<template>
  <div class="medium-center">
    <div class="title-view">
      <p class="title page-title">{{ collection.name }} </p>
      <p class="subtitle">{{ $t('Data collection') }}</p>
    </div>
    <collection-form/>
  </div>
</template>

<script>
import Vuex from 'vuex'
import { store } from '@/store'
import CollectionForm from './Form.vue'

export default {
  name: 'settingsCollectionEdit',
  components: {
    'collection-form': CollectionForm,
  },
  computed: {
    ...Vuex.mapGetters(['collection'])
  },
  methods: {
    ...Vuex.mapActions({
      getCollection: 'getCollection',
    })
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('getCollection', to.params.id).then(next())
  }
}
</script>