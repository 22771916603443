import api from '@/store/api'

export default {
  get (id) {
    return api.get('dataprod/processingLevels/' + id + '/')
  },
  getList () {
    return api.get('dataprod/processingLevels/')
  },
  create (data) {
    return api.post('dataprod/processingLevels/', data)
  },
  update (id, data) {
    return api.patch('dataprod/processingLevels/' + id + '/', data)
  },
}