import { ToastProgrammatic as Toast } from 'buefy'
import QualityControlSheet from '@/store/api/qualityControlSheet'
import i18n from '@/i18n'

// state
const state = {
  qualityControlSheets: {},
  qualityControlSheet: {}
}

// getters
const getters = {
  qualityControlSheets: state => state.qualityControlSheets,
  qualityControlSheet: state => state.qualityControlSheet,
}

// mutations
const mutations = {
  SET_QUALITY_CONTROL_SHEETS: function (state, data) {
    state.qualityControlSheets = data
  },
  SET_QUALITY_CONTROL_SHEET: function (state, data) {
    state.qualityControlSheet = data
  },
  INIT_QUALITY_CONTROL_SHEET: function (state, id) {
    let now = new Date
    state.qualityControlSheet = {
      production_version: '',
      description: '',
      creator: id,
      images: [],
      files: [],
      images_d: [],
      files_d: [],
      date_created: now,
      template: {},
      data: {}
    }
  }
}

// actions
const actions = {

  async createQualityControlSheet ({ commit }, form) {
    try {
      const qc = await QualityControlSheet.create(form)
      commit('SET_QUALITY_CONTROL_SHEET', qc.data)
      // Update list quality control sheets
      const c = await QualityControlSheet.getList()
      await commit('SET_QUALITY_CONTROL_SHEETS', c.data)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
    } catch (e) {
      commit('SET_QUALITY_CONTROL_SHEET', null)
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
    }
  },

  async editQualityControlSheet ({ commit }, form) {
    try {
      await QualityControlSheet.update(form['id'], form)
      const c = await QualityControlSheet.get(form['id'])
      await commit('SET_QUALITY_CONTROL_SHEET', c.data)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
    }
  },

  async getQualityControlSheets ({ commit }, form) {
    try {
      const c = await QualityControlSheet.getList(form['production_version'], form['date_release'])
      await commit('SET_QUALITY_CONTROL_SHEETS', c.data.results)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getQualityControlSheet ({ commit }, id) {
    try {
      const c = await QualityControlSheet.get(id)
      await commit('SET_QUALITY_CONTROL_SHEET', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async initQualityControlSheet ({ commit }, creator) {
    try {
      await commit('INIT_QUALITY_CONTROL_SHEET', creator)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

}

export default {
  state,
  getters,
  mutations,
  actions
}
