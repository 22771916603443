/* eslint-disable */
import { Toast } from 'buefy'
import Scan from '@/store/api/scan'
import router from '@/router'

// state
const state = {
  path: [],
  elements: {
    observatory: null,
    product: null,
    product_level: null,
    year: null,
    month: null,
    day: null
  },
}

// getters
const getters = {
  path: state => state.path,
  elements: state => state.elements
}

// mutations
const mutations = {
  SET_PATH: function (state, data) {
    state.path = data
  },
  SET_ELEMENTS: function (state, data) {
    state.elements = data
  },
  INIT_ELEMENTS: function (state) {
    state.elements.observatory = null
    state.elements.product = null
    state.elements.product_level = null
    state.elements.year = 0
    state.elements.month = null
    state.elements.day = null
  }
}

// actions
const actions = {

  async initElements ({ commit, state }) {
    try {
      await commit('INIT_ELEMENTS')
    } catch (e) {
      Toast.open({
        message: 'Initialisation impossible',
        type: 'is-danger'
      })
    }
  },

  async runScan ({ commit, state }, id) {
    try {
      const p = await Scan.run(id)
      await commit('SET_PATH', p.data)
    } catch (e) {
      Toast.open({
        message: 'Oups ! Il y a eu un problème lors du scan',
        type: 'is-danger'
      })
    }
  },

  async cancelScan ({ commit, state }) {
    try {
      const p = await Scan.cancel()
      await commit('SET_PATH', p.data)
    } catch (e) {
      Toast.open({
        message: 'Oups ! Il y a eu un problème lors du scan',
        type: 'is-danger'
      })
    }
  },


  async getPath ({ commit, state }) {
    try {
      const p = await Scan.get()
      await commit('SET_PATH', p.data)
      this.path.forEach(return_elements)
    } catch (e) {
      Toast.open({
        message: 'Oups ! Il y a eu un problème lors du scan',
        type: 'is-danger'
      })
    }
  }
}

function return_elements(item, index) {
  return item.split('/')
}

export default {
  state,
  getters,
  mutations,
  actions
}
