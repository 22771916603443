<template>
  <div class="scan">
    <div class="container">
      <section class="hero">
        <div class="hero-body">
          <div class="container">
            <h1 class="title">
              {{ $t('Scan module') }}
            </h1>
            <h2 class="subtitle">
              {{ $t('scan_module_description') }}
            </h2>
          </div>
        </div>
      </section>
      <el-button @click="run()">{{ $t('Run scan') }}</el-button>
      <el-button @click="cancel()">{{ $t('Cancel scan') }}</el-button>
      <p>{{ path }}</p>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'

export default {
  name: 'scan',
  data () {
    return {
      pro: true,
      ruleForm: {
        firstName: null,
        lastName: null,
        name: null,
        email: null,
        password: null
      },
    }
  },
  computed: {
    ...Vuex.mapGetters(['path'])
  },
  methods: {
    ...Vuex.mapActions({
      runScan: 'runScan',
      cancelScan: 'cancelScan'
    }),
    run () {
      this.runScan()
    },
    cancel () {
      this.cancelScan()
    }
  }
}
</script>
