<template>
  <e-template formType="edit" :label="template.label" :data="this.code"/>
</template>

<script>
import Vuex from 'vuex'
import { store } from '@/store'
import Template from '@/components/form/Template'

export default {
  name: 'editTemplate',
  data () {
    return {
      code: ""
    }
  },
  computed: {
    ...Vuex.mapGetters(['user', 'template'])
  },
  methods: {
    ...Vuex.mapActions({
      getTemplate: 'getTemplate'
    }),
  },
  components: {
    'e-template': Template,
  },
  beforeMount () {
    this.code = JSON.stringify(this.template.template, null, 2)
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('getTemplate', to.params.id).then(next())
  }
}
</script>