<template>
  <div class="event-form">
    <div class="title-view">
      <p class="title page-title">{{ event.label }} </p>
    </div>
    <div class="e-panel-actions" v-if="formType=='view' && (event.creator === user.info.id || event.recipient === user.info.id || event.contributors.includes(user.info.id))">
      <el-tooltip class="item" effect="dark" :content="$t('Edit event')" placement="top-start">
        <el-button class="el-button--xsmall" type="primary" icon="el-icon-edit" @click="formType='edit'" plain> {{ $t('Edit') }} </el-button>
      </el-tooltip>
      <el-popconfirm :title="$t('Are you sure to delete this?')" 
        :confirm-button-text="$t('OK')"
        :cancel-button-text="$t('No, Thanks')"
        icon="el-icon-info"
        icon-color="red"
        @confirm="deleteAction"
      >
        <el-button class="el-button--xsmall" type="danger" icon="el-icon-delete" slot="reference" v-if="event.creator === user.info.id"> {{ $t('Delete') }} </el-button>
      </el-popconfirm>
      <e-event-button :label="$t('Create a linked event')" :element="event" isSubEvent="true"></e-event-button>
    </div>
    <el-form :model="event" label-position="top" ref="form" :rules="rules" :hide-required-asterisk="true">
      <b-tabs type="is-toggle" position="is-centered dashboard-mg">
        <b-tab-item :label="$t('Description')" icon="clipboard-outline">
          <el-form-item class="center">
            <el-switch
              v-model="complete_form"
              :active-text="$t('Complete form')"
              :inactive-text="$t('Simple form')">
            </el-switch>
          </el-form-item>
          <el-form-item :label="$t('Observing systems assigned')" prop="observingsystems_assigned">
            <el-select v-model="event.observingsystems" multiple @change="loadUsers($event)" placeholder="Select" v-if="formType!='view'">
              <el-option
                v-for="observingsystem in myObservingsystems"
                :key="observingsystem.id"
                :label="observingsystem.label"
                :value="observingsystem.id">
              </el-option>
            </el-select>
            <span v-else> {{ event.observingsystems_label }}</span>
          </el-form-item>
          <el-form-item :label="$t('Event category')" prop="event_category">
            <el-radio-group v-model="event.event_category" v-if="formType!='view'">
              <el-radio-button 
                v-for="value in listOfEventCategory" 
                :key="value[0]"
                :label="value[0]"
                :value="value[0]">
                {{ value[1] }}
              </el-radio-button>
            </el-radio-group>
            <span v-else> {{ eventDetail.event_category }}</span>
          </el-form-item>
          <el-form-item :label="$t('Event criticity')" prop="event_criticity" v-if="complete_form">
            <el-radio-group v-model="event.event_criticity" v-if="formType!='view'">
              <el-radio-button 
                v-for="criticity in listOfEventCriticity.results" 
                :key="criticity.id"
                :label="criticity.id"
                :value="criticity.id">
                {{ criticity.label }}
              </el-radio-button>
            </el-radio-group>
            <span class="tag" :style="{ backgroundColor: eventDetail.event_criticity.color, color: '#1C1C1C' }" v-else> {{ eventDetail.event_criticity.label }}</span>
          </el-form-item>
          <el-form-item :label="$t('Label')" prop="label" v-if="formType!='view'">
            <el-input v-model="event.label" auto-complete="off"></el-input>
          </el-form-item>
          <div class="columns no-mg">
            <div class="column is-one-third">
              <el-form-item :label="$t('Date start (local time)')" prop="date_start">
                <el-date-picker 
                  v-model="event.date_start" 
                  type="datetime"
                  format="dd/MM/yyyy HH:mm"
                  :placeholder="$t('Select a date')"
                  default-time="08:00:00"
                  :disabled="formType=='view'?true:false">
                </el-date-picker><br>
                {{ this.event.date_start ? ' (' + new Date(this.event.date_start).toUTCString() + ') ' : ''}}
              </el-form-item>
            </div>
            <div class="column is-one-third">
              <el-form-item :label="$t('Date end (local time)')" prop="date_end">
                <el-date-picker 
                  v-model="event.date_end" 
                  type="datetime"
                  format="dd/MM/yyyy HH:mm"
                  :placeholder="$t('Select a date')"
                  default-time="08:00:00"
                  :disabled="formType=='view'?true:false">
                </el-date-picker><br>
                {{ this.event.date_end ? ' (' + new Date(this.event.date_end).toUTCString() + ') ' : ''}}
              </el-form-item>
            </div>
          </div>
          <el-form-item :label="$t('Recipient')" prop="recipient" v-if="formType=='view' && event.recipient">
            <a :href="'mailto:' + eventDetail.recipient.email"> {{ eventDetail.recipient.first_name + " " + eventDetail.recipient.last_name }} {{ eventDetail.recipient.organization ? "(" + eventDetail.recipient.organization.label + ")" : "" }}</a>
          </el-form-item>
          <el-form-item :label="$t('Recipient')" prop="recipient" v-else-if="complete_form">
            <el-select name="sltRecipient" v-model="event.recipient" placeholder="Select" value-key="id" ref="sltRecipient" filterable>
              <el-option
                v-for="item in users"
                :key="item.id"
                :label="item.first_name + ' ' + item.last_name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('Contributors')" prop="contributors" v-if="formType=='view' && event.contributors.length != 0">
            <ul id="contributors">
              <li v-for="item in eventDetail.contributors" :key="item.id">
                <a :href="'mailto:' + item.email"> {{ item.first_name + " " + item.last_name }} {{ item.organization ? "(" + item.organization.label + ")" : "" }} </a>
              </li>
            </ul>
          </el-form-item>
          <el-form-item :label="$t('Contributors')" prop="contributors" v-else-if="formType!='view' && complete_form">
            <el-select name="sltContributors" v-model="event.contributors" placeholder="Select" value-key="id" ref="sltExperts" filterable multiple>
              <el-option
                v-for="item in users"
                :key="item.id"
                :label="item.first_name + ' ' + item.last_name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('Triggers')" prop="triggers" v-if="formType=='view' && event.triggers.length != 0">
            <ul id="triggers">
              <li v-for="item in eventDetail.triggers" :key="item.id">
                <router-link :to="{ name: 'event.view', params: { id: item.id } }" class="button rounded button--label">
                  {{ item.label }}
                </router-link>
              </li>
            </ul>
          </el-form-item>
          <el-form-item :label="$t('Triggers')" prop="triggers"  v-else-if="formType!='view' && complete_form">
            <el-select
              v-model="event.triggers"
              multiple
              filterable
              remote
              reserve-keyword
              size="large"
              :placeholder="$t('Enter a keyword')"
              :remote-method="loadTriggers"
              :loading="loading"
              v-if="formType!='view'">
              <el-option
                v-for="item in events"
                :key="item.id"
                :label="item.label + ' (' + formatDate(item.date_created) +')'"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <FormulateForm 
            name="formulate"
            v-model="event.data"
            :schema="template.template"
            @submit="verify('form')"
            v-if="typeof this.$route.params.eventTemplate != 'undefined'"
          />
          <FormulateForm
            name="formulate" 
            :class="formType=='view'?'disabled':''"
            v-model="event.data"
            :schema="event.template"
            @submit="verify('form')"
            v-else
          />
          <el-form-item :label="$t('Description')" prop="description" v-if="formType!='view' || formType=='view' && event.description">
            <e-tiptap type="event" :data="event.description" :isDisabled="formType=='view'?true:false" v-if="formType!='view'"/>
            <div class="justify" v-html="eventDetail.description" v-else></div> 
          </el-form-item>
          <el-form-item :label="$t('Comments')" prop="comments" v-if="formType=='view' && event.comment">
            <div class="justify" v-html=" event.comment"></div> 
          </el-form-item>
          <el-form-item :label="$t('Comments')" prop="comments" v-else-if="complete_form">
            <el-input
              type="textarea"
              :autosize="{ minRows: 4}"
              :placeholder="$t('Enter comments')"
              v-model="event.comment"
              v-if="formType!='view'">
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('History')" prop="history" v-if="formType=='view' && event.history">
            <div class="justify" v-html=" event.history"></div> 
          </el-form-item>
          <el-form-item :label="$t('History')" prop="history" v-else-if="complete_form">
            <el-input
              type="textarea"
              :autosize="{ minRows: 4}"
              :placeholder="$t('Enter history')"
              v-model="event.history"
              v-if="formType!='view'">
            </el-input>
          </el-form-item>
          <el-switch
            v-model="event.is_open"
            :label="$t('Is open')"
            :active-text="$t('Open')"
            :inactive-text="$t('Closed')"
            @change="openCloseAction($event)"
            :disabled="formType=='view'?true:false"
          >
          </el-switch>
          <el-form-item class="lst-btn-end-form" v-if="formType!='view'">
            <el-button @click.stop="save('form')">{{ $t('Save') }}</el-button>
          </el-form-item>
        </b-tab-item>
        <b-tab-item :label="$t('Documents')" icon="paperclip">
          <el-form-item :label="$t('Images')" prop="images" >
            <e-upload entityType="event" name="uploadImage" :data="event.images" fileType="image" :formType="formType" :fileList="event.images_d ? event.images_d : []" :headers="headers" :isDisabled="formType=='view' ? true : false"/>
          </el-form-item>
          <el-form-item :label="$t('Files')" prop="files" >
            <e-upload entityType="event" name="uploadFile" :data="event.files" fileType="file" :formType="formType" :fileList="event.files_d ? event.files_d : []" :headers="headers" :isDisabled="formType=='view' ? true : false"/>
          </el-form-item>
        </b-tab-item>
      </b-tabs>
    </el-form>
  </div>
</template>

<script>
import Vuex from 'vuex'
import i18n from '@/i18n'
import Tiptap from '@/components/Tiptap'
import Upload from '@/components/Upload'
import EventButton from '@/components/event/Button'


export default {
  name: 'settingsEventForm',
  components: {
    'e-tiptap': Tiptap,
    'e-upload': Upload,
    'e-event-button': EventButton
  },
  props: {
    formType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      datenow: new Date(),
      headers: '',
      loading: false,
      complete_form: false,
      contributors: [],
      rules: {
        event_category: [
          { required: true, message: i18n.t('msg_required_field'), trigger: 'change' },
        ],
        event_criticity: [
          { required: true, message: i18n.t('msg_required_field'), trigger: 'change' },
        ],
        label: [
          { required: true, message: i18n.t('msg_required_field'), trigger: 'change' },
        ],
        date_start: [
          { required: true, message: i18n.t('msg_required_field'), trigger: 'change' },
        ],
        recipient: [
          { required: true, message: i18n.t('msg_required_field'), trigger: 'change' },
        ]
      },
      formSchema: []
    }
  },
  computed: {
    ...Vuex.mapGetters(['user', 'event', 'eventDetail', 'events', 'myObservingsystems', 'observingsystem', 'observingsystemEvents', 'users', 'listOfEventCategory', 'listOfEventCriticity', 'templates', 'template'])
  },
  methods: {
    ...Vuex.mapActions({
      initEvent: 'initEvent',
      initEvents: 'initEvents',
      getEvent: 'getEvent',
      getEvents: 'getEvents',
      createEvent: 'createEvent',
      editEvent: 'editEvent',
      deleteEvent: 'deleteEvent',
      searchEvents: 'searchEvents',
      check: 'check',
      getObservingSystemEvents: 'getObservingSystemEvents',
      getUsersByObservingSystems: 'getUsersByObservingSystems',
      getTemplate: 'getTemplate',
      getListOfEventCategory: 'getListOfEventCategory',
      getListOfEventCriticity: 'getListOfEventCriticity',
      getTemplates: 'getTemplates',
      getMyObservingSystems: 'getMyObservingSystems'
    }),
    ajouterZero(nombre) {
      if (nombre < 10) {
        return "0" + nombre
      }
      return nombre;
    },
    formatDate (dateOriginale) {
      var date = new Date(dateOriginale)
      var annee = date.getFullYear();
      var mois = this.ajouterZero(date.getMonth() + 1)
      var jour = this.ajouterZero(date.getDate())
      var heures = this.ajouterZero(date.getHours())
      var minutes = this.ajouterZero(date.getMinutes())
      var dateFormatee = annee + "-" + mois + "-" + jour + " " + heures + ":" + minutes
      return dateFormatee
    },
    loadUsers () {
      let observingsystems = this.event.observingsystems
      // Delete undefined values
      observingsystems = observingsystems.filter(element => {
        return element !== undefined;
      });
      this.getUsersByObservingSystems(observingsystems)
    },
    loadTriggers(query) {
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          /* eslint-disable */
          this.searchEvents(query)
        }, 200)
      } else {
        this.observingsystems = []
      }
    },
    openCloseAction(isOpen) {
      if(isOpen) {
        this.event.closed_by = null
      } else {
        if(!this.event.date_end)
          this.event.date_end = new Date()
        this.event.closed_by = this.user.info.id
      }
    },
    deleteAction () {
      this.deleteEvent(this.event)
    },
    verify (formName) {
      this.event.creator = this.user.info.id
      
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.$route.name == 'settings.event.create'){
            this.createEvent(this.event).then(
              this.$refs.upload.submit()
            )
          } else {
            this.$emit("displayEventForm", false)
            this.editEvent(this.event).then(
              this.$store.dispatch('updateEventInObservingSystemEvents', this.event),
              this.$refs.upload.submit()
            )
          }
        } else {
          this.$buefy.toast.open({
            message: this.$root.$t('check_data_entered'),
            type: 'is-danger'
          })
          return false
        }
      })
    },
    save (formName) {
      if(this.event.template) {
        this.$formulate.submit("formulate")
      } else {
        this.verify(formName)
      }
    },
    init () {
      let params_myobservingsystems = {}
      if(typeof this.$route.params.eventTemplate != 'undefined') {
        params_myobservingsystems["event_template"] = this.$route.params.eventTemplate
      }
      this.getMyObservingSystems(params_myobservingsystems)
      this.check().then(
        () => {
          if(this.formType=='create') {
            let triggers = []
            if(this.$route.params.trigger)
              triggers.push(this.$route.params.trigger)
            // Default recipient set to user connected
            let initObservingsystems = this.$route.params.observingsystem ? [this.$route.params.observingsystem] : []
            let data = {
              'observingsystems' : initObservingsystems,
              'default_recipient': this.$store.getters.user.info.id,
              'triggers': triggers,
              'event_category': 'observation',
              'event_criticity': 1
            }
            this.initEvent(data)
            this.initEvents()
            this.getUsersByObservingSystems(this.$route.params.observingsystem)
            if(typeof this.$route.params.eventTemplate != 'undefined') {
              this.getTemplate(this.$route.params.eventTemplate).then(
                () => {
                  this.event.template = this.template.template
                  this.event.label = this.template.label
                }
              )
            }
          } else {
            this.getUsersByObservingSystems(this.event.observingsystems)
          }
        }
      )
      this.getListOfEventCategory()
      this.getListOfEventCriticity()
      let data = {
        'type': 'event',
      }
      this.getTemplates(data)
      this.token = localStorage.getItem('id_token')
      this.headers = {
        'Authorization': 'Bearer ' + this.token,
      }
    }
  },
  mounted: function () {
    this.init()
  },
  beforeRouteEnter (to, from, next) {
    this.init()
  },
  beforeRouteUpdate (to, from, next) {
    this.init()
  },
}
</script>