import api from '@/store/api'

export default {
  get (id) {
    return api.get('events/records/' + id + '/')
  },
  getDetail (id) {
    return api.get('events/records/' + id + '/get_detail')
  },
  getList (page) {
    return api.get('events/records/?page=' + page)
  },
  getListFromUserConnected (form) {
    var req = 'events/records/my_events/?'
    if(form['page'])
      req = req + '&page=' + form['page']
    return api.get(req)
  },
  getLastFromUserConnected () {
    var req = 'events/records/my_last_events'
    return api.get(req)
  },
  create (data) {
    return api.post('events/records/', data)
  },
  update (id, data) {
    return api.patch('events/records/' + id + '/', data)
  },
  delete (id) {
    return api.delete('events/records/' + id)
  },
  getListIdsIn (ids) {
    var req = 'search/events/?ids='
    let id_list = ''
    for (let id of ids)
      id_list += id + '__'
    req = req + id_list
    return api.get(req)
  },
  search (value) {
    return api.get('search/events/?label__contains=' + value )
  }
}
