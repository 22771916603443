<template>
  <div class="medium-center">
    <el-form :model="product" label-position="top" ref="form" :rules="rules" :hide-required-asterisk="true">
      <el-form-item :label="$t('Collection')" prop="data_collection">
        <el-select name="sltCollection" v-model="product.data_collection" placeholder="Select" value-key="id" ref="sltCollection">
          <el-option
            v-for="item in collections.results"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('Name')" prop="name">
        <el-input v-model="product.name" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item :label="$t('Label')" prop="label">
        <el-input v-model="product.label" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item :label="$t('Product category')" prop="product_category" >
        <el-radio-group v-model="product.category">
          <el-radio-button 
            v-for="item in listOfProductCategory" 
            :key="item[0]" 
            :value="item[0]" 
            :label="item[0]">
            {{ item[1] }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('Processing level')" prop="processing_level">
        <el-radio-group v-model="product.processing_level">
          <el-radio-button 
            v-for="item in processingLevels" 
            :key="item.id" 
            :value="item.id" 
            :label="item.id">
            {{ item.name }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('Data storage strategy')" prop="data_storage_strategy">
        <el-radio-group v-model="product.data_storage_strategy">
          <el-radio-button 
            v-for="item in listOfDataStorageStrategy" 
            :key="item[0]" 
            :value="item[0]" 
            :label="item[0]">
            {{ item[1] }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('Primary data center')" prop="primary_data_center">
        <el-select name="sltNetworks" v-model="product.primary_data_center" placeholder="Select" value-key="id" ref="sltNetworks">
          <el-option
            v-for="item in networks.results"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('Other data centers')" prop="other_data_centers">
        <el-select name="sltOtherDatacenters" v-model="product.other_data_centers" placeholder="Select" value-key="id" ref="sltOtherDatacenters" multiple>
          <el-option
            v-for="item in networks.results"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="is_hidden">
        <el-checkbox v-model="product.is_hidden">{{ $t('Hide the product') }}</el-checkbox>
      </el-form-item>
      <el-form-item class="lst-btn-end-form">
        <el-button @click.stop="save('form')">{{ $t('Create') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Vuex from 'vuex'
import i18n from '@/i18n'

export default {
  name: 'settingsProductCreate',
  data () {
    return {
      rules: {
        name: [
          { required: true, message: i18n.t('msg_required_field'), trigger: 'blur' },
        ],
        data_collection: [
          { required: true, message: i18n.t('msg_required_field'), trigger: 'change' },
        ]
      }
    }
  },
  computed: {
    ...Vuex.mapGetters(['user', 'product', 'collections', 'networks', 'listOfProductCategory', 'listOfDataStorageStrategy', 'processingLevels'])
  },
  methods: {
    ...Vuex.mapActions({
      initProduct: 'initProduct',
      createProduct: 'createProduct',
      getProcessingLevels: 'getProcessingLevels',
      getCollections: 'getCollections',
      getListOfProductCategory: 'getListOfProductCategory',
      getListOfDataStorageStrategy: 'getListOfDataStorageStrategy',
      getNetworks: 'getNetworks',
    }),
    save (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.createProduct(this.product)
        } else {
          this.$buefy.toast.open({
            message: this.$root.$t('check_data_entered'),
            type: 'is-danger'
          })
          return false
        }
      })
    }
  },
  mounted: function () {
    this.initProduct(),
    this.getProcessingLevels(),
    this.getListOfProductCategory(),
    this.getCollections(),
    this.getNetworks(),
    this.getListOfProductCategory(),
    this.getListOfDataStorageStrategy()
  }
}
</script>