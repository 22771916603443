<template>
  <div>
    <div class="avatar-logo-center">
      <el-upload
        class="avatar-logo-uploader img-uploader-big"
        ref="upload"
        :action="url"
        :headers="headers"
        :data="data"
        :before-upload="getFile"
        :on-success="handleAvatarSuccess"
        :http-request="upload"
        :show-file-list="true"
        :auto-upload="formType=='create' ? false : true">
        <img v-if="this.observatory.thumbnail" :src="this.observatory.thumbnail_l" class="img-background">
        <i v-else class="el-icon-picture avatar-uploader-icon img-uploader-big"></i>
      </el-upload>
    </div>
    <br>
    <el-form :model="observatory" label-position="top" ref="form" :rules="rules" :hide-required-asterisk="true">
      <el-switch
        v-model="observatory.is_campaign"
        :active-text="$t('Campaign')"
        :inactive-text="$t('Perennial observatory')">
      </el-switch>
      <el-form-item :label="$t('Name')" prop="name">
        <el-input v-model="observatory.name" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item :label="$t('Label')" prop="label" >
        <el-input v-model="observatory.label" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item :label="$t('Website')" prop="website" >
        <el-input type="url" v-model="observatory.website" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item class="lst-btn-end-form">
        <el-button @click.stop="save('form')">{{ $t('Save') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Vuex from 'vuex'
import conf from '@/conf'
import i18n from '@/i18n'
import router from '@/router'

export default {
  name: 'observatoryForm',
  props: {
    formType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      token: null,
      url: conf.API_URL + 'resources/observatories/',
      headers: {},
      data: {},
      file: null,
      thumbnail: '',
      rules: {
        name: [
          { required: true, message: i18n.t('msg_required_field'), trigger: 'blur' },
        ],
        website: [
          { type: 'url', required: false, message: i18n.t('msg_invalid_url'), trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    ...Vuex.mapGetters(['user', 'countries', 'observatory', 'observatories'])
  },
  methods: {
    ...Vuex.mapActions({
      getCountries: 'getCountries',
      getObservatories: 'getObservatories',
      initObservatory: 'initObservatory',
      createObservatory: 'createObservatory',
      editObservatory: 'editObservatory',
    }),
    getFile (file) {
      const isJPGPNG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isJPGPNG) {
        this.$buefy.toast.open({
          message: this.$root.$t('image_must_be_jpg_png'),
          type: 'is-danger'
        })
      }
      if (!isLt5M) {
        this.$buefy.toast.open({
          message: this.$root.$t('image_too_heavy'),
          type: 'is-danger'
        })
      }
      return isJPGPNG && isLt5M
    },
    handleAvatarSuccess (res, file) {
      this.observatory.thumbnail = URL.createObjectURL(file.raw)
      this.observatory.thumbnail_m = URL.createObjectURL(file.raw)
      this.observatory.thumbnail_l = URL.createObjectURL(file.raw)
    },
    save (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.$route.name == 'settings.observatory.create') {
            // Create observatory and upload image
            this.createObservatory(this.observatory).then(() => {
              this.$refs.upload.submit()
              router.push({
                name: 'settings.observatory.list'
              })
            })
          } else {
            // Delete image element to update observatory. Image is managed by upload function
            delete this.observatory['thumbnail']
            this.editObservatory(this.observatory)
          }
        } else {
          this.$buefy.toast.open({
            message: this.$root.$t('check_data_entered'),
            type: 'is-danger'
          })
          return false
        }
      })
    },
    upload(req) {
      var formData = new FormData()
      this.url = this.url + this.observatory.id + '/'
      formData.append('thumbnail', req.file)
      const requestOptions = {
        method: 'PATCH',
        headers: this.headers,
        body: formData
      }
      return fetch(this.url, requestOptions)
    }
  },

  mounted: function () {
    this.token = localStorage.getItem('id_token')
    this.headers = {
      'Authorization': 'Bearer ' + this.token,
    }
  }
}
</script>