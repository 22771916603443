<template>
  <div class="medium-center">
    <div class="avatar-logo-center">
      <el-upload
        class="avatar-logo-uploader"
        :action="url"
        :headers="headers"
        :data="data"
        :before-upload="getFile"
        :on-success="handleAvatarSuccess"
        :http-request="upload"
        :show-file-list="true">
        <img v-if="user.info.avatar" :src="user.info.thumbnail_m" class="avatar-logo">
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
    </div>
    <el-form :model="user" label-position="top" ref="form" :hide-required-asterisk="true">
      <el-form-item :label="$t('First name')" prop="info.first_name" :rules="[
          { required: true, message: $t('first_name_required'), trigger: 'blur' }
        ]"
      >
        <el-input v-model="user.info.first_name" auto-complete="off" disabled></el-input>
      </el-form-item>
      <el-form-item :label="$t('Last name')" prop="info.last_name" :rules="[
          { required: true, message: $t('last_name_required'), trigger: 'blur' }
        ]"
      >
        <el-input v-model="user.info.last_name" auto-complete="off" disabled></el-input>
      </el-form-item>
      <el-form-item :label="$t('Email')" prop="info.email" :rules="[
          { required: true, message: $t('email_required'), trigger: 'blur' },
          { type: 'email', message: $t('email_not_valid'), trigger: 'blur' }
        ]"
      >
        <el-input type="email" v-model="user.info.email" auto-complete="off" disabled></el-input>
      </el-form-item>
      <el-form-item :label="$t('Organization')" prop="info.organization">
        <el-select v-model="user.info.organization" auto-complete="off" disabled>
          <el-option
            v-for="obs in organizations"
            :key="obs.id"
            :label="obs.label"
            :value="obs.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('Position')" prop="info.position">
        <el-input v-model="user.info.position" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item :label="$t('ORCID')" prop="info.orcid" >
        <el-input v-model="user.info.orcid" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item :label="$t('Address')" prop="address">
        <el-input type="textarea" v-model="user.info.address" auto-complete="off"></el-input> 
      </el-form-item>
      <el-form-item :label="$t('Postcode')" prop="info.postcode">
        <el-input v-model="user.info.postcode" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item :label="$t('City')" prop="info.city">
        <el-input v-model="user.info.city" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item :label="$t('Country')" prop="info.country">
        <el-input v-model="user.info.country" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item :label="$t('Voice')" prop="info.voice">
        <el-input v-model="user.info.voice" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item :label="$t('Fax')" prop="info.fax">
        <el-input v-model="user.info.fax" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item :label="$t('Website url')" prop="info.website">
        <el-input v-model="user.info.website" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item :label="$t('Website name')" prop="info.websiteName">
        <el-input v-model="user.info.websiteName" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item :label="$t('Events email notifications')" prop="allow_events_email_notifications" >
        <el-switch
          v-model="user.info.allow_events_email_notifications"
          :label="$t('Enable events email notifications')"
          :active-text="$t('Enable events email notifications')"
          :inactive-text="$t('Disable events email notifications')"
          ref="allow_events_email_notifications">
        </el-switch>
      </el-form-item>
      <el-form-item :label="$t('Default language')" prop="default_language" >
        <el-radio-group v-model="user.info.default_language">
          <el-radio-button 
            v-for="item in listOfLanguages" 
            :key="item[0]" 
            :value="item[0]" 
            :label="item[0]">
            {{ item[1] }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item class="lst-btn-end-form">
        <el-button @click.stop="saveProfile('form')">{{ $t('Update') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Vuex from 'vuex'
import conf from '@/conf'

export default {
  name: 'settingsProfile',
  data () {
    return {
      token: null,
      url: conf.API_URL + 'user/',
      headers: {},
      data: {},
      file: null,
      image: ''
    }
  },
  computed: {
    ...Vuex.mapGetters(['user', 'countries', 'observatories', 'organizations', 'listOfGroups', 'listOfLanguages'])
  },
  methods: {
    ...Vuex.mapActions({
      updateProfile: 'updateProfile',
      getObservatories: 'getObservatories',
      getOrganizations: 'getOrganizations',
      getCountries: 'getCountries',
      getListOfGroups: 'getListOfGroups',
      getListOfLanguages: 'getListOfLanguages'
    }),
    getFile (file) {
      const isJPGPNG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isJPGPNG) {
        this.$buefy.toast.open({
          message: this.$root.$t('image_must_be_jpg_png'),
          type: 'is-danger'
        })
      }
      if (!isLt5M) {
        this.$buefy.toast.open({
          message: this.$root.$t('image_too_heavy'),
          type: 'is-danger'
        })
      }
      return isJPGPNG && isLt5M
    },
    handleAvatarSuccess (res, file) {
      this.user.info.avatar = URL.createObjectURL(file.raw)
      this.user.info.thumbnail_m = URL.createObjectURL(file.raw)
      this.user.info.thumb_avatar = URL.createObjectURL(file.raw)
    },
    saveProfile (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // Delete avatar element to update user. Avatar is managed by upload function
          delete this.user.info['avatar']
          this.updateProfile(this.user.info)
          this.$i18n.locale = this.user.info.defaultLanguage
        } else {
          this.$buefy.toast.open({
            message: this.$root.$t('check_data_entered'),
            type: 'is-danger'
          })
          return false
        }
      })
    },
    upload(req) {
      var formData = new FormData()
      formData.append('avatar', req.file)
      const requestOptions = {
        method: 'PATCH',
        headers: this.headers,
        body: formData
      }
      return fetch(this.url, requestOptions)
    }
  },

  mounted: function () {
    this.token = localStorage.getItem('id_token')
    this.getListOfLanguages()
    this.getObservatories()
    this.getOrganizations([])
    this.getCountries()
    this.headers = {
      'Authorization': 'Bearer ' + this.token,
    }
  }
}
</script>
