<template>
  <div class="e-upload" :class="this.formType == 'view' ? 'hide-add-button' : null">
    <div v-if="formType=='view' && data.length == 0">
      {{ $t('Sorry there is no data') }}
    </div>
    <el-upload
      :drag="draggable"
      :action="url"
      :headers="headers"
      :data="data"
      :before-upload="checkFile"
      :http-request="upload"
      :multiple="true"
      :list-type="listType"
      :ref="name"
      :auto-upload="true"
      :disabled="isDisabled"
      :on-preview="this.fileType=='image' ? handlePictureCardPreview : handleDownload"
      :before-remove="this.formType != 'create' ? handleRemove : null"
      :file-list="fileList"
      v-else>
        <div v-if="fileType=='file'">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">{{ $t('Drop the files here or ') }} <em>{{ $t('click to send') }}</em></div>
        </div>
        <div v-if="fileType=='image'">
          <i slot="default" class="el-icon-plus"></i>
        </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
      <div class="modal-download-image">
        <el-button class="download-btn" @click="handleDownload(imageToUpload)" icon="el-icon-download">{{ $t('Download') }} </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Vuex from 'vuex'
import i18n from '@/i18n'
import conf from '@/conf'

export default {
  props: {
    entityType: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    fileType: {
      type: String,
      required: true
    },
    formType: {
      type: String,
      required: true
    },
    fileList: {
      type: Array,
      required: true
    },
    headers: {
      type: Object,
      required: true
    },
    isDisabled: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      url: '',
      draggable: false,
      autoUpload: true,
      dialogImageUrl: '',
      imageToUpload: '',
      dialogVisible: false,
      listType: 'text'
    }
  },
  computed: {
    ...Vuex.mapGetters(['user', 'operationSheet', 'qualityControlSheet', 'event'])
  },
  methods: {
    ...Vuex.mapActions({
      editOperationSheet: 'editOperationSheet',
      editQualityControlSheet: 'editQualityControlSheet',
      editEvent: 'editEvent',
    }),
    init: function () {
      this.autoUpload = (this.formType == 'create' ? false : true)
      if (this.fileType == 'image') {
        this.listType = 'picture-card'
        this.draggable = false
        this.ref = 'uploadImage'
      } else {
        this.listType = 'text'
        this.draggable = true
        this.ref = 'uploadFile'
      }
    },
    checkFile (file) {
      const isJPGPNG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isLt5M) {
        this.$buefy.toast.open({
          message: i18n.t('image_too_heavy'),
          type: 'is-danger'
        })
      }
      if(this.fileType == 'image'){
        if (!isJPGPNG) {
          this.$buefy.toast.open({
            message: i18n.t('image_must_be_jpg_png'),
            type: 'is-danger'
          })
        }
        return isJPGPNG && isLt5M
      } else {
        if (isJPGPNG) {
          this.$buefy.toast.open({
            message: i18n.t('You must use image upload for images'),
            type: 'is-danger'
          })
        }
        return !isJPGPNG && isLt5M
      }
    },
    handleRemove(file) {
      return new Promise(() => {
        this.$confirm(i18n.t('This will delete the file. Continue ?'), 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: i18n.t('Cancel'),
          type: 'warning'
        }).then(() => {
          var elements = null
          if(this.entityType=='event') {
            if (this.fileType=='image') {
              elements = this.event.images
            } else if (this.fileType=='file') {
              elements = this.event.files
            }
            const index = elements.indexOf(file.id)
            if (index > -1) {
              elements.splice(index, 1)
            }
            if (this.formType != "create") {
              this.editEvent(this.event)
            }
          }
          if(this.entityType=='operationSheet') {
            if (this.fileType=='image') {
              elements = this.operationSheet.images
            } else if (this.fileType=='file') {
              elements = this.operationSheet.files
            }
            const index = elements.indexOf(file.id)
            if (index > -1) {
              elements.splice(index, 1)
            }
            if (this.formType != "create") {
              this.editOperationSheet(this.operationSheet)
            }
          } 
          if(this.entityType=='qualityControlSheet') {
            if (this.fileType=='image') {
              elements = this.qualityControlSheet.images
            } else if (this.fileType=='file') {
              elements = this.qualityControlSheet.files
            }
            const index = elements.indexOf(file.id)
            if (index > -1) {
              elements.splice(index, 1)
            }
            if (this.formType != "create") {
              this.editQualityControlSheet(this.qualityControlSheet)
            }
          }  
        }).catch(() => {
          this.$buefy.toast.open({
            type: 'is-info',
            message: i18n.t('Deletion canceled')
          })       
        })
      })
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.imageToUpload = file
      this.dialogVisible = true
    },
    handleDownload(file) {
      this.url = (this.fileType =='image' ? conf.API_URL + 'resources/images/' + file.id + '/download' : conf.API_URL + 'resources/files/' + file.id + '/download')
      const requestOptions = {
        method: 'GET',
        headers: this.headers,
        responseType: 'blob',
      }
      return fetch(this.url, requestOptions).then(response => response.blob())
        .then(blob => {
          window.URL.createObjectURL(blob)
          var a = document.createElement('a')
          a.href = this.url
          document.body.appendChild(a)
          a.click()   
          a.remove()
        })
    },
    upload(req) {
      var formData = new FormData()
      var filename = req.file.name.substr(0, req.file.name.indexOf('.'))
      formData.append('upload', req.file)
      formData.append('label', filename)
      formData.append('creator', this.user.info.id)
      formData.append('type_of_document', this.fileType)
      this.url = (this.fileType =='image' ? conf.API_URL + 'resources/images/' : conf.API_URL + 'resources/files/')
      const requestOptions = {
        method: 'POST',
        headers: this.headers,
        body: formData
      }
      return fetch(this.url, requestOptions).then(
        (response) => response.json()
      ).then(
        (result) => {
          if(this.entityType=='event') {
            this.fileType =='image' ? this.event.images.push(result.id) : this.event.files.push(result.id)
            this.fileType =='image' ? this.event.images_d.push(result) : this.event.files_d.push(result)
            if(this.formType!='create') {
              this.editEvent(this.event)
            }
          }
          if(this.entityType=='operationSheet') {
            this.fileType =='image' ? this.operationSheet.images.push(result.id) : this.operationSheet.files.push(result.id)
            if(this.formType!='create') {
              this.editOperationSheet(this.operationSheet)
            }
          }
          if(this.entityType=='qualityControlSheet') {
            this.fileType =='image' ? this.qualityControlSheet.images.push(result.id) : this.qualityControlSheet.files.push(result.id)
            if(this.formType!='create') {
              this.editQualityControlSheet(this.qualityControlSheet)
            }
          }
        }
      )
    }
  },
  mounted: function () {
    this.init()
  },
}
</script>