<template>
  <div>
    <l-map
      ref="map"
      :zoom="zoom"
      :center="center"
      style="height: 500px; width: 100%"
    >
      <l-tile-layer
        :url="url"
        :attribution="attribution"
      />
      <l-geo-json
        :geojson="this.geojson"
        :options-style="styleFunction"
        ref="editableLayer"
      />
    </l-map>
  </div>
</template>

<script>
import L from "leaflet"
import { LMap, LTileLayer,  LGeoJson } from "vue2-leaflet"

export default {
  name: "Map",
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
  },
  props: {
    geojson: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      loading: false,
      show: true,
      enableTooltip: true,
      zoom: 6,
      center: [48, -1.219482],
      fillColor: "#e4ce7f",
      url: 'https://s.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    };
  },
  mounted() {
    // Définit automatiquement le centre et le zoom en fonction du geojson en entrée
    const map = this.$refs.map.mapObject
    var geojson = L.geoJSON(this.geojson)
    map.fitBounds(geojson.getBounds())
  },
  computed: {
    styleFunction() {
      const fillColor = this.fillColor; // important! need touch fillColor in computed for re-calculate when change fillColor
      return () => {
        return {
          weight: 2,
          color: "red",
          opacity: 1,
          fillColor: fillColor,
          fillOpacity: 0.2
        };
      };
    },
  },
};
</script>
