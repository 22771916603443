import { ToastProgrammatic as Toast } from 'buefy'
import ObservingSystem from '@/store/api/observingsystem'
import Organization from '@/store/api/organization'
import Observatory from '@/store/api/observatory'
import Site from '@/store/api/site'
import Instrument from '@/store/api/instrument'
import Software from '@/store/api/software'
import Platform from '@/store/api/platform'
import Network from '@/store/api/network'
import Collection from '@/store/api/collection'
import Product from '@/store/api/product'
import i18n from '@/i18n'

// state
const state = {
  myObservingsystems: {},
  parentsObservingsystems: {},
  associatedObservingsystems: {},
  observingsystems: {},
  observingsystem: {},
  observingsystemDetail: {},
  observingsystemEvents: [],
  observingsystemHistory: {},
  observingsystemVersion: {},
  status: {},
  clone: {},
  isLoadingObservingsystem: true,
  isNoMoreEvents: false,
}

// getters
const getters = {
  myObservingsystems: state => state.myObservingsystems,
  parentsObservingsystems: state => state.parentsObservingsystems,
  associatedObservingsystems: state => state.associatedObservingsystems,
  observingsystems: state => state.observingsystems,
  observingsystem: state => state.observingsystem,
  observingsystemDetail: state => state.observingsystemDetail,
  observingsystemEvents: state => state.observingsystemEvents,
  observingsystemEventsPage: state => state.observingsystemEventsPage,
  observingsystemHistory: state => state.observingsystemHistory,
  observingsystemVersion: state => state.observingsystemVersion,
  status: state => state.status,
  clone: state => state.clone,
  isLoadingObservingsystem: state => state.isLoadingObservingsystem,
  isNoMoreEvents: state => state.isNoMoreEvents
}

// mutations
const mutations = {
  INIT_OBSERVINGSYSTEMS: function (state) {
    state.observingsystems = {}
  },
  SET_MY_OBSERVINGSYSTEMS: function (state, data) {
    state.myObservingsystems = data
  },
  SET_PARENTS_OBSERVINGSYSTEMS: function (state, data) {
    state.parentsObservingsystems = data
  },
  SET_ASSOCIATED_OBSERVINGSYSTEMS: function (state, data) {
    state.associatedObservingsystems = data
  },
  SET_OBSERVINGSYSTEMS: function (state, data) {
    state.observingsystems = data
  },
  INIT_OBSERVINGSYSTEM: function (state, type) {
    state.observingsystem = {
      'name' : null,
      'label' : null,
      'thumbnail' : null,
      'type_of_system' : type,
      'current_status' : null,
      'pi' : null,
      'comment' : null,
      'keywords' : null
    }
  },
  SET_OBSERVINGSYSTEM: function (state, data) {
    state.observingsystem = data
  },
  SET_OBSERVINGSYSTEM_DETAIL: function (state, data) {
    state.observingsystemDetail = data
  },
  INIT_OBSERVINGSYSTEM_EVENTS: function (state) {
    state.observingsystemEventsPage = 1
    state.observingsystemEvents = []
  },
  SET_OBSERVINGSYSTEM_EVENTS: function (state, data) {
    state.observingsystemEvents = state.observingsystemEvents.concat(data)
  },
  UPDATE_EVENT_OBSERVINGSYSTEM_EVENTS: function (state, data) {
    var events = []
    events.push(data)
    const result = state.observingsystemEvents.map(x => {
      const item = events.find(({ id }) => id === x.id);
      return item ? item : x;
    }) 
    state.observingsystemEvents = result
  },
  SET_OBSERVINGSYSTEM_EVENTS_PAGE: function (state, data) {
    state.observingsystemEventsPage = data
  },
  SET_OBSERVINGSYSTEM_HISTORY: function (state, data) {
    state.observingsystemHistory = data
  },
  SET_OBSERVINGSYSTEM_VERSION: function (state, data) {
    state.observingsystemVersion = data
  },
  SET_STATUS: function (state, data) {
    state.status = data
  },
  INIT_STATUS: function (state) {
    state.status = {}
  },
  INIT_CLONE: function (state) {
    state.clone = {}
  },
  SET_CLONE: function (state, data) {
    state.clone = data
  },
  SET_LOADING_OBSERVINGSYSTEM: function (state, data) {
    state.isLoadingObservingsystem = data
  },
  SET_NO_MORE_EVENTS: function (state, data) {
    state.isNoMoreEvents = data
  },
}

// actions: method for CRUD actions
const actions = {
  async createObservingSystem ({ commit }, form) {
    try {
      let obs = null
      if(form['type_of_system'] == 'organization') {
        obs = await Organization.create(form)
      } else if (form['type_of_system'] == 'observatory') {
        obs = await Observatory.create(form)
      } else if (form['type_of_system'] == 'site') {
        obs = await Site.create(form)
      } else if (form['type_of_system'] == 'instrument') {
        obs = await Instrument.create(form)
      } else if (form['type_of_system'] == 'software') {
        obs = await Software.create(form)
      } else if (form['type_of_system'] == 'platform') {
        obs = await Platform.create(form)
      } else if (form['type_of_system'] == 'network') {
        obs = await Network.create(form)
      } else if (form['type_of_system'] == 'data_collection') {
        obs = await Collection.create(form)
      } else if (form['type_of_system'] == 'data_product') {
        obs = await Product.create(form)
      } else {
        obs = await ObservingSystem.create(form)
      }
      commit('SET_OBSERVINGSYSTEM', obs.data)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
    } catch (e) {
      commit('SET_OBSERVINGSYSTEM', null)
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
      return false
    }
  },

  async editObservingSystem ({ dispatch }, form) {
    try {
      let c = null
      if(form['type_of_system'] == 'organization') {
        c = await Organization.update(form['id'], form)
      } else if (form['type_of_system'] == 'observatory') {
        c = await Observatory.update(form['id'], form)
      } else if (form['type_of_system'] == 'site') {
        c = await Site.update(form['id'], form)
      } else if (form['type_of_system'] == 'instrument') {
        c = await Instrument.update(form['id'], form)
      } else if (form['type_of_system'] == 'software') {
        c = await Software.update(form['id'], form)
      } else if (form['type_of_system'] == 'platform') {
        c = await Platform.update(form['id'], form)
      } else if (form['type_of_system'] == 'network') {
        c = await Network.update(form['id'], form)
      } else if (form['type_of_system'] == 'data_collection') {
        c = await Collection.update(form['id'], form)
      } else if (form['type_of_system'] == 'data_product') {
        c = await Product.update(form['id'], form)
      } else {
        c = await ObservingSystem.update(form['id'], form)
      }

      //await commit('SET_OBSERVINGSYSTEM', c.data)
      dispatch('getObservingSystem', c.data.id)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
    }
  },

  async getMyObservingSystems ({ commit }, form) {
    try {
      const c = await ObservingSystem.getListFromUserConnected(form)
      await commit('SET_MY_OBSERVINGSYSTEMS', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('err'),
        type: 'is-danger'
      })
    }
  },

  async getObservingSystems ({ commit }, form) {
    try {
      let c = null
      if (form['observingsystemType']=="organization") {
        c = await Organization.getList(form)
      } else if (form['observingsystemType']=="observatory") {
        c = await Observatory.getList(form)
      } else if (form['observingsystemType']=="site") {
        c = await Site.getList(form)
      } else if (form['observingsystemType']=="instrument") {
        c = await Instrument.getList(form)
      } else if (form['observingsystemType']=="software") {
        c = await Software.getList(form)
      } else if (form['observingsystemType']=="platform") {
        c = await Platform.getList(form)
      } else if (form['observingsystemType']=="network") {
        c = await Network.getList(form)
      } else if (form['observingsystemType']=="data_collection") {
        c = await Collection.getList(form)
      } else if (form['observingsystemType']=="data_product") {
        c = await Product.getList(form)
      } else {
        c = await ObservingSystem.getList(form)
      }
      await commit('SET_OBSERVINGSYSTEMS', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async searchObservingSystems ({ commit }, text) {
    try {
      const c = await ObservingSystem.search(text, 0, 100)
      await commit('SET_OBSERVINGSYSTEMS', c.data.results)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async searchParentsObservingSystems ({ commit }, text) {
    try {
      const c = await ObservingSystem.search(text, 0, 100)
      await commit('SET_PARENTS_OBSERVINGSYSTEMS', c.data.results)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async searchAssociatedObservingSystems ({ commit }, text) {
    try {
      const c = await ObservingSystem.search(text, 0, 100)
      await commit('SET_ASSOCIATED_OBSERVINGSYSTEMS', c.data.results)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getObservingSystem ({ commit }, id) {
    try {
      // Load observing system by type or set default
      let c = await ObservingSystem.get(id)
      if (c.data.type_of_system=="organization") {
        c = await Organization.get(id)
      } else if (c.data.type_of_system=="observatory") {
        c = await Observatory.get(id)
      } else if (c.data.type_of_system=="site") {
        c = await Site.get(id)
      } else if (c.data.type_of_system=="instrument") {
        c = await Instrument.get(id)
      } else if (c.data.type_of_system=="software") {
        c = await Software.get(id)
      } else if (c.data.type_of_system=="platform") {
        c = await Platform.get(id)
      } else if (c.data.type_of_system=="network") {
        c = await Network.get(id)
      } else if (c.data.type_of_system=="data_collection") {
        c = await Collection.get(id)
      } else if (c.data.type_of_system=="data_product") {
        c = await Product.get(id)
      }
      await commit('SET_OBSERVINGSYSTEM', c.data)

      // Load observing system detail by type or set default
      let d = await ObservingSystem.getDetail(id)
      if (d.data.type_of_system=="organization") {
        d = await Organization.getDetail(id)
      } else if (d.data.type_of_system=="observatory") {
        d = await Observatory.getDetail(id)
      } else if (d.data.type_of_system=="site") {
        d = await Site.getDetail(id)
      } else if (d.data.type_of_system=="instrument") {
        d = await Instrument.getDetail(id)
      } else if (d.data.type_of_system=="software") {
        d = await Software.getDetail(id)
      } else if (d.data.type_of_system=="platform") {
        d = await Platform.getDetail(id)
      } else if (d.data.type_of_system=="network") {
        d = await Network.getDetail(id)
      } else if (d.data.type_of_system=="data_collection") {
        d = await Collection.getDetail(id)
      } else if (d.data.type_of_system=="data_product") {
        d = await Product.getDetail(id)
      }
      await commit('SET_OBSERVINGSYSTEM_DETAIL', d.data)

      // Load parents observing systems
      if(c.data.parents_discoveries){
        const d = await ObservingSystem.getListIdsIn(c.data.parents_discoveries)
        await commit('SET_PARENTS_OBSERVINGSYSTEMS', d.data.results)
      }

      // Load associated discoveries
      if(c.data.associated_discoveries){
        const d = await ObservingSystem.getListIdsIn(c.data.associated_discoveries)
        await commit('SET_ASSOCIATED_OBSERVINGSYSTEMS', d.data.results)
      }

      await commit('SET_LOADING_OBSERVINGSYSTEM', false)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getObservingSystemEvents ({ commit }, form) {
    try {
      const c = await ObservingSystem.getEvents(form)
      await commit('SET_OBSERVINGSYSTEM_EVENTS', c.data.results)
      await commit('SET_OBSERVINGSYSTEM_EVENTS_PAGE', form['page'])
      if(c.data.next) {
        await commit('SET_NO_MORE_EVENTS', false)
      } else {
        await commit('SET_NO_MORE_EVENTS', true)
      }
    } catch (e) {
      await commit('SET_NO_MORE_EVENTS', true)
    }
  },

  async updateEventInObservingSystemEvents ({ commit }, form) {
    try {
      await commit('UPDATE_EVENT_OBSERVINGSYSTEM_EVENTS', form)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getObservingSystemHistory ({ commit }, id) {
    try {
      const c = await ObservingSystem.getHistory(id)
      await commit('SET_OBSERVINGSYSTEM_HISTORY', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getObservingSystemVersion ({ commit }, id) {
    try {
      const c = await ObservingSystem.getVersion(id)
      await commit('SET_OBSERVINGSYSTEM', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getStatus ({ commit }, form) {
    try {
      const c = await ObservingSystem.getListStatus(form['type_of_system'])
      await commit('SET_STATUS', c.data.results)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async initStatus ({ commit }) {
    try {
      await commit('INIT_STATUS')
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async initLoadingObservingSystem ({ commit }) {
    await commit('SET_LOADING_OBSERVINGSYSTEM', true)
  },


  async initObservingSystem ({ commit }, type) {
    try {
      await commit('INIT_OBSERVINGSYSTEM', type)
      await commit('SET_LOADING_OBSERVINGSYSTEM', false)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async initObservingSystems ({ commit }) {
    try {
      await commit('INIT_OBSERVINGSYSTEMS')
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async initObservingSystemsEvents ({ commit }) {
    try {
      await commit('INIT_OBSERVINGSYSTEM_EVENTS')
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async initClone ({ commit }) {
    try {
      await commit('INIT_CLONE')
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async setClone ({ commit }, id) {
    try {
      const c = await ObservingSystem.get(id)
      await commit('SET_CLONE', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async exportEvents (form) {
    try {
      await ObservingSystem.exportEvents(form['id'], form['show_sites_events'], form['date_start'], form['date_end'], form['fields'])
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
