<template>
  <div class="instrument full-width">
    <e-observingsystem-form isVersion="false" observingSystemType="instrument" formType="create"/>
  </div>
</template>

<script>
import Vuex from 'vuex'
import ObservingSystemForm from '@/views/observingsystem/Form.vue'

export default {
  name: 'createObservingSystem',
  components: {
    'e-observingsystem-form': ObservingSystemForm,
  },
  methods: {
    ...Vuex.mapActions({
      initObservingSystem: 'initObservingSystem'
    })
  },
  mounted: function () {
    this.initObservingSystem()
  }
}
</script>