<template>
  <e-loading v-if="isLoadingSite"></e-loading>
  <div class="full-width" v-else>
    <e-site-form formType="edit"/>
  </div>
</template>

<script>
import Vuex from 'vuex'
import { store } from '@/store'
import SiteForm from '@/views/site/Form.vue'
import Loading from '@/components/Loading'

export default {
  name: 'editObservingSystem',
  components: {
    'e-site-form': SiteForm,
    'e-loading': Loading
  },
  computed: {
    ...Vuex.mapGetters(['user', 'isLoadingSite'])
  },
  methods: {
    ...Vuex.mapActions({
      getSite: 'getSite',
    }),
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('initLoadingSite')
    store.dispatch('getSite', to.params.id).then(next())
  }
}
</script>