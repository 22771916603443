<template>
  <div class="full-width">
    <h1 class="title settings-title">
      {{ $t('Organizations') }} 
    </h1>
    <div class="columns medium-center">
      <div class="column">
        <section>
          <b-table
            :data="organizations.results"
            backend-pagination
            :loading="loading"
            :paginated="isPaginated"
            :per-page="perPage"
            @page-change="onPageChange"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :default-sort-direction="defaultSortDirection"
            :total="organizations.count"
            default-sort="name"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page">
            <b-table-column field="id" label="ID" width="40" sortable numeric v-slot="props">
              {{ props.row.id }}
            </b-table-column>

            <b-table-column field="name" :label="$t('Name')" sortable v-slot="props">
              {{ props.row.name }}
            </b-table-column>

            <b-table-column field="label" :label="$t('Label')" v-slot="props">
              {{ props.row.label }}
            </b-table-column>

            <b-table-column field="actions" :label="$t('Action')" v-slot="props">
              <router-link :to="{ name: 'settings.organization.edit', params: { id: props.row.id } }" class="button rounded is-primary">
                <i class="el-icon-edit"></i>
              </router-link>
            </b-table-column>
          </b-table>
          <router-link :to="{name: 'settings.organization.create'}" v-if="$can('read', 'Info')">
            <el-button icon="el-icon-plus" plain>{{ $t('Add') }}</el-button>
          </router-link>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'


export default {
  name: 'listOrganizations',
  data () {
    return {
      loading: false,
      isPaginated: true,
      isPaginationSimple: false,
      defaultSortDirection: 'asc',
      currentPage: 1,
      page: 1,
      perPage: 10,
      total: 0,
      token: null,
      data: null,
      name: '',
      value: '',
      formSchema: '',
      id: '',
    }
  },
  computed: {
    ...Vuex.mapGetters(['user', 'organizations'])
  },
  methods: {
    ...Vuex.mapActions({
      getOrganizations: 'getOrganizations'
    }),
    loadAsyncData() {
      this.loading = true
      const data =  {
        'page': this.page,
      }
      this.getOrganizations(data).then(
        () => {
          this.loading = false
          this.total = this.observatories.count
          this.data = this.observatories.results
        }
      )
    },
    onPageChange(page) {
      this.page = page
      this.loadAsyncData()
    },
  },

  mounted: function () {
    this.loadAsyncData()
  }
}
</script>
