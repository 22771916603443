<template>
  <div class="page-view medium-center">
    <h1 class="title settings-title">
      {{ instrument.label }} 
    </h1>
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link :to="{ name: 'home' }">
            {{ $t('Home') }}
          </router-link>
        </li>
        <li v-if="instrumentDetail.current_observatory">
          <router-link :to="{ name: 'observatory.visuData', params: { obs: instrumentDetail.current_observatory.name } }">
            {{ instrumentDetail.current_observatory.name }}
          </router-link>
        </li>
        <li v-if="instrumentDetail.current_site">
          <router-link :to="{ name: 'observatory.visuData', params: { obs: instrumentDetail.current_site.name }  }">
            {{ instrumentDetail.current_site.name }}
          </router-link>
        </li>
        <li class="is-active"><a href="#" aria-current="page">{{ instrument.name }}</a></li>
      </ul>
    </nav>
    <div class="instrument full-width">
      <b-tabs type="is-toggle" position="is-centered dashboard-mg">
        <b-tab-item :label="$t('Informations')" icon="clipboard-outline">
          <e-observingsystem-form observingSystemType="instrument" formType="view"/>
        </b-tab-item>
        <b-tab-item :label="$t('Events')" icon="bell-ring-outline">
          <e-observingsystem-events-list observingSystemType="instrument"/>
        </b-tab-item>
        <b-tab-item :label="$t('History')" icon="history">
          <e-observingsystem-history-list :observingSystemID="instrument.id" observingSystemType="instrument"/>
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'
import { store } from '@/store'
import ObservingSystemForm from '@/views/observingsystem/Form.vue'
import ObservingSystemEventsList from '@/components/observingsystem/EventsList.vue'
import ObservingSystemHistoryList from '@/components/observingsystem/HistoryList.vue'

export default {
  name: 'viewObservingSystem',
  components: {
    'e-observingsystem-form': ObservingSystemForm,
    'e-observingsystem-events-list': ObservingSystemEventsList,
    'e-observingsystem-history-list': ObservingSystemHistoryList,
  },
  computed: {
    ...Vuex.mapGetters(['user', 'template', 'instrument', 'instrumentDetail'])
  },
  methods: {
    ...Vuex.mapActions({
      getObservingSystem: 'getObservingSystem',
      getObservingSystemEvents: 'getObservingSystemEvents',
      getObservingSystemHistory: 'getObservingSystemHistory',
      getInstrument: 'getInstrument',
      getInstrumentDetail: 'getInstrumentDetail'
    }),
  },
  beforeRouteEnter (to, from, next) {
    const form = {
      'id': to.params.id,
      'page': 1
    }
    store.dispatch('getObservingSystem', to.params.id).then(next())
    store.dispatch('getInstrument', to.params.id).then(next())
    store.dispatch('getInstrumentDetail', to.params.id).then(next())
    store.dispatch('getObservingSystemEvents', form).then(next())
    store.dispatch('getObservingSystemHistory', to.params.id).then(next())
  }
}
</script>