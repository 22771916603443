<template>
  <div class="medium-center">
    <collection-form/>
  </div>
</template>

<script>
import Vuex from 'vuex'
import CollectionForm from './Form.vue'

export default {
  name: 'settingsCollectionCreate',
  components: {
    'collection-form': CollectionForm,
  },
  methods: {
    ...Vuex.mapActions({
      initCollection: 'initCollection'
    })
  },
  mounted: function () {
    this.initCollection()
  }
}
</script>