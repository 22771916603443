import api from '@/store/api'

export default {
  get (id) {
    return api.get('resources/instruments/' + id + '/')
  },
  getDetail (id) {
    return api.get('resources/instruments/' + id + '/get_detail')
  },
  getList (form) {
    var req = 'resources/instruments/?'
    if(form['page'])
      req = req + '&page=' + form['page']
    if(form['obs'])
      req = req + '&current_observatory__name=' + form['obs']
    if(form['observingsystemType'])
      req = req + '&type_of_system=' + form['observingsystemType']
    return api.get(req)
  },
  getListDetail (form) {
    var req = 'resources/instrumentsDetails/?'
    if(form['page'])
      req = req + '&page=' + form['page']
    if(form['obs'])
      req = req + '&current_observatory__name=' + form['obs']
    if(form['observingsystemType'])
      req = req + '&type_of_system=' + form['observingsystemType']
    return api.get(req)
  },
  getListFromUserConnected (form) {
    var req = 'resources/instruments/my_instruments/?'
    if(form['page'])
      req = req + '&page=' + form['page']
    return api.get(req)
  },
  export (form) {
    var req = 'resources/instruments/export?'
    if(form['observatory'])
      req = req + '&observatory=' + form['observatory']
    return api.get(req)
  },
  create (data) {
    return api.post('resources/instruments/', data)
  },
  update (id, data) {
    return api.patch('resources/instruments/' + id + '/', data)
  },
}
