<template>
  <e-loading v-if="isLoadingObservingsystem"></e-loading>
  <div class="page-view medium-center" v-else>
    <div class="title-view">
      <p class="title page-title">{{ observingsystem.label }} </p>
      <p class="subtitle cap">{{ observingsystem.type_of_system }}</p>
    </div>
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link :to="{ name: 'home' }">
            {{ $t('Home') }}
          </router-link>
        </li>
        <li v-if="observingsystemDetail.current_observatory">
          <router-link :to="{ name: 'observatory.visuData', params: { obs: observingsystemDetail.current_observatory.name } }">
            {{ observingsystemDetail.current_observatory.label }}
          </router-link>
        </li>
        <li v-if="observingsystemDetail.current_site">
          <router-link :to="{ name: 'observingsystem.view', params: { observingsystemType: 'site', id: observingsystemDetail.current_site.id } }">
            {{ observingsystemDetail.current_site.label }}
          </router-link>
        </li>
        <li v-if="observingsystemDetail.data_source">
          <router-link :to="{ name: 'observingsystem.view', params: { observingsystemType: observingsystemDetail.data_source.type_of_system, id: observingsystemDetail.data_source.id } }">
            {{ observingsystemDetail.data_source.label }}
          </router-link>
        </li>
        <li class="is-active"><a href="#" aria-current="page">{{ observingsystem.label }}</a></li>
      </ul>
    </nav>
    <div class="instrument full-width">
      <b-tabs type="is-toggle" position="is-centered dashboard-mg">
        <b-tab-item :label="$t('Informations')" icon="clipboard-outline">
          <e-observingsystem-form formType="view"/>
        </b-tab-item>
        <b-tab-item :label="$t('Events')" icon="bell-ring-outline" v-if="this.observingsystemEvents.length">
          <e-observingsystem-events-list/>
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'
import { store } from '@/store'
import ObservingSystemForm from '@/views/observingsystem/Form.vue'
import ObservingSystemEventsList from '@/components/observingsystem/EventsList.vue'
import Loading from '@/components/Loading'

export default {
  name: 'viewObservingSystem',
  components: {
    'e-observingsystem-form': ObservingSystemForm,
    'e-observingsystem-events-list': ObservingSystemEventsList,
    'e-loading': Loading
  },
  data () {
    return {
      //loading: true
    }
  },
  computed: {
    ...Vuex.mapGetters(['user', 'template', 'observingsystem', 'observingsystemDetail', 'isLoadingObservingsystem', 'observingsystemEvents'])
  },
  methods: {
    ...Vuex.mapActions({
      getObservingSystem: 'getObservingSystem',
      getObservingSystemEvents: 'getObservingSystemEvents',
      getObservingSystemHistory: 'getObservingSystemHistory',
    }),
  },
  beforeRouteEnter (to, from, next) {
    const form = {
      'id': to.params.id,
      'page': 1
    }
    store.dispatch('initLoadingObservingSystem')
    store.dispatch('initObservingSystemsEvents').then(
      store.dispatch('getObservingSystemEvents', form)
    )
    store.dispatch('getObservingSystem', to.params.id).then(next())
    store.dispatch('getObservingSystemHistory', to.params.id).then(next())
  },
  beforeRouteUpdate (to, from, next) {
    const form = {
      'id': to.params.id,
      'page': 1
    }
    store.dispatch('initLoadingObservingSystem')
    store.dispatch('initObservingSystemsEvents').then(
      store.dispatch('getObservingSystemEvents', form)
    )
    store.dispatch('getObservingSystem', to.params.id).then(next())
    store.dispatch('getObservingSystemHistory', to.params.id).then(next())
  }
}
</script>