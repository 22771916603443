<template>
  <div class="e-loading">
    <div class="loader-cylon"></div> 
    <!--<span class="loading">Loading</span>-->
    <!--<div class="loader"></div>-->
  </div>
</template>

<script>

export default {
  name: 'e-loading'
}
</script>
