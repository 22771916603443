<template>
  <div class="dropdown is-active">
    <b-dropdown aria-role="list" position="is-bottom-right" class="fix-position">
      <template #trigger>
        <el-button type="text">
          <i class="el-icon-more"></i>
        </el-button>
      </template>
      <b-dropdown-item aria-role="listitem">
        <router-link :to="{ name: 'settings.operationSheet.create', params: { production_version: element.id } }" v-if="element.is_operationsheet">
          {{ $t('Create operation sheet') }}
        </router-link>
      </b-dropdown-item>
      <b-dropdown-item aria-role="listitem">
        <router-link :to="{ name: 'settings.qualityControlSheet.create', params: { production_version: element.id } }" v-if="element.is_qualitycontrolsheet">
          {{ $t('Create quality control sheet') }}
        </router-link>
      </b-dropdown-item>
      <b-dropdown-item aria-role="listitem" @click="openDialogExport('operationsheet')" v-if="element.is_operationsheet">
        <span>{{ $t('Export data operations sheets') }}</span>
      </b-dropdown-item>
      <b-dropdown-item aria-role="listitem" @click="openDialogExport('qualitycontrolsheet')" v-if="element.is_qualitycontrolsheet">
        <span>{{ $t('Export data quality control sheets') }}</span>
      </b-dropdown-item>
    </b-dropdown>

    <!-- Dialog export operation or quality control sheet -->
    <el-dialog :title="this.typeSheet === 'operationsheet' ? $t('Export operations sheets') : $t('Export quality control sheets')" :visible.sync="dialogExportVisible">
      <el-form :model="form" label-position="top">
        <el-form-item :label="$t('Filter by start date')">
          <el-date-picker
            v-model="form.dateStartRange"
            type="monthrange"
            align="right"
            unlink-panels
            :range-separator="$t('to')"
            :start-placeholder="$t('Start month')"
            :end-placeholder="$t('End month')"
            :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('Filter by end date')">
          <el-date-picker
            v-model="form.dateEndRange"
            type="monthrange"
            align="right"
            unlink-panels
            :range-separator="$t('to')"
            :start-placeholder="$t('Start month')"
            :end-placeholder="$t('End month')"
            :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('Select fields to display')">
          <el-transfer
            v-model="form.fieldsToExport"
            :titles="[$t('Fields available'), $t('Fields to export')]"
            :data="listOfFieldToExport">
          </el-transfer>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">{{ $t('Cancel') }}</el-button>
        <el-button type="primary" @click.stop="exportData()">{{ $t('Export') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import conf from '@/conf'
import Vuex from 'vuex'
import i18n from '@/i18n'

export default {
  name: 'SelectAction',
  props: {
    element: {
      type: String,
      required: true
    },
    isSubEvent: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      typeSheet: '',
      dialogExportVisible: false,
      dialogExportOperationSheetsVisible: false,
      dialogExportQualityControlSheetsVisible: false,
      sltClone: '',
      showTemplates: false,
      listOfFieldToExport: [],
      form: {
        showSitesEvents: false,
        dateStartRange: [],
        dateEndRange: [],
        fieldsToExport: []
      },
      pickerOptions: {
        shortcuts: [{
          text: i18n.t('This month'),
          onClick(picker) {
            picker.$emit('pick', [new Date(), new Date()]);
          }
        }, {
          text: i18n.t('This year'),
          onClick(picker) {
            const end = new Date();
            const start = new Date(new Date().getFullYear(), 0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: i18n.t('Last 6 months'),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setMonth(start.getMonth() - 6);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    }
  },
  computed: {
    ...Vuex.mapGetters(['productionVersion'])
  },
  methods: {
    ...Vuex.mapActions({
      getProductionVersionDetail: 'getProductionVersionDetail',
    }),

    init: function () {
    },

    openDialogExport: function (type) {
      // init form
      this.listOfFieldToExport = []
      this.form = {
        dateStartRange: '',
        dateEndRange: '',
        fieldsToExport: []
      }
      this.typeSheet = type
      // generate form
      this.getProductionVersionDetail(this.element.id).then(() => {
        this.generateFields(this.productionVersion)
      })
    },

    exportData: function () {
      let data = {
        'id': this.element.id,
        'type_sheet': this.typeSheet,
        'date_release_start_bs': typeof this.form.dateStartRange[0] != 'undefined' ? this.form.dateStartRange[0].toISOString() : '',
        'date_release_start_be': typeof this.form.dateStartRange[1] != 'undefined' ? this.form.dateStartRange[1].toISOString() : '',
        'date_release_end_bs': typeof this.form.dateEndRange[0] != 'undefined' ? this.form.dateEndRange[0].toISOString() : '',
        'date_release_end_be': typeof this.form.dateEndRange[1] != 'undefined' ? this.form.dateEndRange[1].toISOString() : '',
        'fields': JSON.stringify(this.listOfFieldToExport.filter(this.comparer(this.form.fieldsToExport)))
      }

      var url = conf.API_URL + 'dataprod/productionVersions/' + data['id'] + '/export_sheets/?'
      if(data['type_sheet'])
        url = url + '&type_sheet=' + data['type_sheet']
      if(data['date_release_start_bs'])
        url = url + '&date_release_start_bs=' + data['date_release_start_bs']
      if(data['date_release_start_be'])
        url = url + '&date_release_start_be=' + data['date_release_start_be']
      if(data['date_release_end_bs'])
        url = url + '&date_release_end_bs=' + data['date_release_end_bs']
      if(data['date_release_end_be'])
        url = url + '&date_release_end_be=' + data['date_release_end_be']
      if(data['fields'])
        url = url + '&fields=' + data['fields']
      
      axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]))
        var fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', this.typeSheet === 'operationsheet' ? 'operation_sheets.csv' : 'qualitycontrol_sheets.csv')
        document.body.appendChild(fileLink)
        fileLink.click()
        this.dialogExportVisible = false
      })
    },
    comparer: function (otherArray){
      return function(current){
        return otherArray.some(function(other){
          return other == current.key
        });
      }
    },
    generateFields: function (pv) {
      const data = [
        {
          key: 'description',
          label: 'Description'
        },
        {
          key: 'date_release_start',
          label: 'Date release start'
        },
        {
          key: 'date_release_end',
          label: 'Date release end'
        },
        {
          key: 'creator',
          label: 'Creator'
        },
      ]
      // Get list of fields of template
      if(this.typeSheet === 'operationsheet') {
        for (let i = 0; i < pv.operationsheet_template.template.length; i++) {
          if(pv.operationsheet_template.template[i].name)
            data.push(
              {
                key: 'data.' + pv.operationsheet_template.template[i].name,
                label: pv.operationsheet_template.template[i].name
              }
            )
        }
        this.listOfFieldToExport = data
      } else if (this.typeSheet === 'qualitycontrolsheet') {
        for (let i = 0; i < pv.qualitycontrolsheet_template.template.length; i++) {
          if(pv.qualitycontrolsheet_template.template[i].name)
            data.push(
              {
                key: 'data.' + pv.qualitycontrolsheet_template.template[i].name,
                label: pv.qualitycontrolsheet_template.template[i].name
              }
            )
        }
        this.listOfFieldToExport = data
      }
      this.dialogExportVisible = true
    }
  },
  mounted: function () {
    this.init()
  },
}
</script>