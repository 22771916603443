import api from '@/store/api'

export default {
  getListOfGroups () {
    return api.get('resources/groups/')
  },
  getCounters () {
    return api.get('utility/counters/')
  },
  getListOfObservingSystemType () {
    return api.get('utility/listOfObservingSystemType/')
  },
  getListOfWordCategory () {
    return api.get('utility/listOfWordCategory/')
  },
  getListOfWordDataType () {
    return api.get('utility/listOfWordDataType/')
  },
  getListOfWordDataWidget () {
    return api.get('utility/listOfWordDataWidget/')
  },
  getListOfDataStorageStrategy () {
    return api.get('utility/listOfDataStorageStrategy/')
  },
  getListOfProductCategory () {
    return api.get('utility/listOfProductCategory/')
  },
  getListOfProductVersionRecordMode () {
    return api.get('utility/listOfProductVersionRecordMode/')
  },
  getListOfProductionVersionPolicy () {
    return api.get('utility/listOfProductionVersionPolicy/')
  },
  getListOfProductionVersionQCMode () {
    return api.get('utility/listOfProductionVersionQCMode/')
  },
  getListOfQualityControlStatus () {
    return api.get('utility/listOfQualityControlSheetStatus/')
  },
  getListOfEventCategory () {
    return api.get('utility/listOfEventCategory/')
  },
  getListOfEventCriticity () {
    return api.get('utility/listOfEventCriticity/')
  },
  getListOfLanguages () {
    return api.get('utility/listOfLanguages/')
  },
  getListOfLanguagesIso () {
    return api.get('utility/listOfLanguagesIso/')
  },
  getListOfIsoTopicCategory () {
    return api.get('utility/listOfIsoTopicCategory/')
  },
  getListOfGeonetworkRights () {
    return api.get('utility/listOfGeonetworkRights/')
  },
  getListOfGeonetworkTypeOfSystem () {
    return api.get('utility/listOfGeonetworkTypeOfSystem/')
  }
}
