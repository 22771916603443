<template>
  <div class="header">
    <nav class="navbar is-fixed-top" role="navigation" aria-label="main navigation" :class="{ mobile: this.isMobile, connected: user.authenticated }">
      <div class="navbar-brand">
        <router-link :to="{ name: 'home' }" class="navbar-item">
          eObs
        </router-link>

        <a role="button" v-if="!user.authenticated" class="navbar-burger burger" aria-label="menu" @click="toggleMenu" :class="navMenuClass">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarMenu" class="navbar-menu" :class="navMenuClass">
        <div class="navbar-start">
          <div class="navbar-item has-dropdown is-hoverable" v-if="observatories.length > 0">
            <a class="navbar-link">
              {{ $t('Observatories') }}
            </a>
            <div class="navbar-dropdown">
              <router-link 
                v-model="observatories"
                v-for="o in observatories"
                v-bind:item="o"
                v-bind:key="o.name"
                :to="{ name: 'observatory.visuData', params: { obs: o.name } }" class="navbar-item">
                {{ o.name.toUpperCase() }}
              </router-link>
            </div>
          </div>
          <div class="navbar-item has-dropdown is-hoverable" v-if="campaigns.length > 0">
            <a class="navbar-link">
              {{ $t('Campaigns') }}
            </a>
            <div class="navbar-dropdown">
              <router-link 
                v-model="campaigns"
                v-for="o in campaigns"
                v-bind:item="o"
                v-bind:key="o.name"
                :to="{ name: 'observatory.visuData', params: { obs: o.name } }" class="navbar-item">
                {{ o.name.toUpperCase() }}
              </router-link>
            </div>
          </div>
        </div>

        <div class="navbar-end">
          
          <div class="navbar-item">
            <e-select-langage/>
          </div>

          <div class="navbar-item">
            <router-link :to="{ name: 'docs' }" class="button" target='_blank'>
              <span class="icon-menu-navbar">
                <i class="el-icon-collection"></i>
              </span>
              Docs
            </router-link>
          </div>
          
          <div v-if="!user.authenticated" class="navbar-item">
            <div class="buttons">
              <router-link :to="{ name: 'register' }" class="button is-hidden">
                {{ $t('Register') }}
              </router-link>
              <router-link :to="{ name: 'signIn' }" class="button is-primary">
                <strong>{{ $t('Sign in') }}</strong>
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div v-if="user.authenticated" >
        <div class="navbar-avatar">
          <b-dropdown v-model="navigation" position="is-bottom-left">
              <a class="navbar-item" slot="trigger">
                <e-avatar/>
              </a>
              <span class="navbar-item navbar-userinfo">
                <div class="is-4">{{ user.info.full_name }}</div>
                <span class="navbar-username">@{{ user.info.username }}</span>
              </span>
              <hr class="dropdown-divider">
              <b-dropdown-item has-link>
                <router-link class="navbar-item" :to="{ name: 'dashboard' }">
                  <i class="el-icon-menu icon-menu-navbar"></i>
                  {{ $t('My dashboard') }}
                </router-link>
              </b-dropdown-item>
              <b-dropdown-item has-link>
                <router-link class="navbar-item" :to="{ name: 'profile' }">
                  <i class="el-icon-user icon-menu-navbar"></i>
                  {{ $t('Profile') }}
                </router-link>
              </b-dropdown-item>
              <b-dropdown-item has-link v-if="$can('access', 'adm')">
                <router-link class="navbar-item" :to="{ name: 'settings.organization.list' }">
                  <i class="el-icon-setting icon-menu-navbar"></i>
                  {{ $t('Admin area') }}
                </router-link>
              </b-dropdown-item>
              <hr class="dropdown-divider">
              <b-dropdown-item value="signout" has-link>
                <a class="navbar-item" value="logout" v-on:click="signout">
                    <b-icon icon="exit_to_app" class="icon-margin"></b-icon>
                    {{ $t('Disconnect') }}
                </a>
              </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      
    </nav>
  </div>
</template>

<script>
import Vuex from 'vuex'
import conf from '@/conf'
import SelectLangage from '@/components/SelectLangage.vue'
import Avatar from '@/components/Avatar.vue'
var MobileDetect = require('mobile-detect')
var md = new MobileDetect(navigator.userAgent)

export default {
  name: 'navbar',
  data () {
    return {
      isMobile: false,
      navigation: 'home',
      isNavMenuActive: false,
      gitlab : conf.GITLAB_URL,
      apiLink : conf.API_URL + 'doc',
      issueLink : conf.GITLAB_URL + 'eobs-back/issues',
    }
  },
  components: {
    'e-select-langage': SelectLangage,
    'e-avatar': Avatar,
  },
  computed: {
    ...Vuex.mapGetters(['user', 'observatories', 'campaigns']),
    navMenuClass () {
      return {
        'is-active': this.isNavMenuActive
      }
    },
    connectedClass () {
      return {
        'connected': this.user.authenticated
      }
    }
  },
  methods: {
    ...Vuex.mapActions({
      logout: 'logout',
      check: 'check',
      getObservatories: 'getObservatories',
      getCampaigns: 'getCampaigns'
    }),
    toggleMenu () {
      this.isNavMenuActive = !this.isNavMenuActive
    },
    signout (e) {
      e.preventDefault()
      const redirect = this.logout(this)
      if (redirect) {
        this.$router.push({
          name: 'home'
        })
      }
    }
  },
  mounted: function () {
    this.getObservatories()
    this.getCampaigns()
    this.$nextTick(function () {
      this.check(this)
    })
    if (md.mobile()) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }
  }
}
</script>
