<template>
  <div class="full-width">
    <b-tabs type="is-toggle" position="is-centered dashboard-mg">
        <b-tab-item :label="$t('My dashboard')" icon="clipboard-outline">
          <dashboard/>
        </b-tab-item>
        <b-tab-item :label="$t('My data collections')" icon="chart-bar">
          <dataprod/>
        </b-tab-item>
        <b-tab-item :label="$t('My quicklooks')" icon="eye-outline">
          <quicklook/>
        </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import Vuex from 'vuex'
import Dataprod from '@/components/Dataprod'
import Quicklook from '@/components/Quicklook'
import Dashboard from '@/components/Dashboard'

export default {
  name: 'Dashboard',
  components: {
    'dashboard': Dashboard,
    'dataprod': Dataprod,
    'quicklook': Quicklook
  },
  data () {
    return {
      id: '',
      name: '',
      title: '',
    }
  },
  methods: {
    ...Vuex.mapActions({
      check: 'check',
    }),
  },
  mounted: function () {
    this.$nextTick(function () {
      this.check(this)
    })
  }
}
</script>