import { ToastProgrammatic as Toast } from 'buefy'
import ProcessingLevel from '@/store/api/processingLevel'
import router from '@/router'
import i18n from '@/i18n'

// state
const state = {
  processingLevels: {},
  processingLevel: {}
}

// getters
const getters = {
  processingLevels: state => state.processingLevels,
  processingLevel: state => state.processingLevel,
}

// mutations
const mutations = {
  SET_PROCESSING_LEVELS: function (state, data) {
    state.processingLevels = data
  },
  SET_PROCESSING_LEVEL: function (state, data) {
    state.processingLevel = data
  },
  INIT_PROCESSING_LEVEL: function (state) {
    state.processingLevel = {
      'name' : null,
      'main_processing_level' : null,
      'specific_processing_level' : null
    }
  }
}

// actions: method for CRUD actions
const actions = {

  async createProcessingLevel ({ commit }, form) {
    try {
      const ins = await ProcessingLevel.create(form)
      commit('SET_PROCESSING_LEVEL', ins)
      // Reload processingLevel list
      const c = await ProcessingLevel.getList()
      await commit('SET_PROCESSING_LEVELS', c.data)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
      router.push({
        name: 'settings.dataprod.processingLevel.list'
      })
    } catch (e) {
      commit('SET_PROCESSING_LEVEL', null)
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
      return false
    }
  },

  async editProcessingLevel ({ commit }, form) {
    try {
      const ins = await ProcessingLevel.update(form['id'], form)
      commit('SET_PROCESSING_LEVEL', ins.data)
      // Reload processingLevel list
      const c = await ProcessingLevel.getList()
      await commit('SET_PROCESSING_LEVELS', c.data)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
      router.push({
        name: 'settings.dataprod.processingLevel.list'
      })
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
    }
  },

  async getProcessingLevels ({ commit }) {
    try {
      const c = await ProcessingLevel.getList()
      await commit('SET_PROCESSING_LEVELS', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getProcessingLevel ({ commit }, id) {
    try {
      const c = await ProcessingLevel.get(id)
      await commit('SET_PROCESSING_LEVEL', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async initProcessingLevel ({ commit }) {
    try {
      await commit('INIT_PROCESSING_LEVEL')
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

}

export default {
  state,
  getters,
  mutations,
  actions
}
