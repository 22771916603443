<template>
  <div class="instrumentClone medium-center">
    <el-form :model="clone" label-position="top" ref="form" :rules="rules" :hide-required-asterisk="true">
      <el-form-item :label="$t('Name')" prop="name">
        <el-input v-model="clone.name" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item :label="$t('Label')" prop="label">
        <el-input v-model="clone.label" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('Clone number')"
        prop="clone_number"
      >
        <el-input type="clone_number" v-model.number="clone_number" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item :label="$t('Serial number')" prop="label">
        <el-input v-model="clone.serial_number" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item class="lst-btn-end-form">
        <el-button @click.stop="save('form')">
          <span>{{ $t('Create') }}</span>
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Vuex from 'vuex'
import i18n from '@/i18n'

export default {
  name: 'formInstrumentClone',
  data () {
    return {
      data: {},
      observingsystemType: '',
      clone_number: '',
      rules: {
        name: [
          { required: true, message: i18n.t('msg_required_field'), trigger: 'blur' },
        ],
        clone_number: [
          { type: 'number', message: i18n.t('Clone number must be an integer') },
        ]
      },
    }
  },
  watch: {
    clone_number: function () {
      this.clone.name = this.clone.name.split('__')[0]
      this.clone.name = this.clone.label + '__' + this.clone_number
      this.clone.label = this.clone.label.split(' #')[0]
      this.clone.label = this.clone.label + ' #' + this.clone_number
      this.clone.clone_number = this.clone_number
    }
  },
  computed: {
    ...Vuex.mapGetters(['clone']),
  },
  methods: {
    ...Vuex.mapActions({
      createInstrument: 'createInstrument',
    }),
    save (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // Set main instrument and init id, date_created, date_modified to create it
          this.clone.primary_instrument = this.clone.id
          delete this.clone.id
          delete this.clone.date_created
          delete this.clone.date_modified
          this.clone.is_main_version = true
          this.clone.commit_message = "Init"
          this.createInstrument(this.clone) 
        } else {
          this.$buefy.toast.open({
            message: this.$root.$t('check_data_entered'),
            type: 'is-danger'
          })
          return false
        }
      })
    }
  },
}
</script>