/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'


import { ToastProgrammatic as Toast } from 'buefy'
import User from '@/store/api/user'
import user from '@/store/modules/user'
import scan from '@/store/modules/scan'
import event from '@/store/modules/event'
import observingsystem from '@/store/modules/observingsystem'
import countries from '@/store/modules/countries'
import utility from '@/store/modules/utility'
import operationSheet from '@/store/modules/operationSheet'
import qualityControlSheet from '@/store/modules/qualityControlSheet'
import organization from '@/store/modules/organization'
import observatory from '@/store/modules/observatory'
import site from '@/store/modules/site'
import instrument from '@/store/modules/instrument'
import network from '@/store/modules/network'
import software from '@/store/modules/software'
import platform from '@/store/modules/platform'
import vocabulary from '@/store/modules/vocabulary'
import collection from '@/store/modules/collection'
import datasource from '@/store/modules/datasource'
import dataFile from '@/store/modules/dataFile'
import processingLevel from '@/store/modules/processingLevel'
import product from '@/store/modules/product'
import productionVersion from '@/store/modules/productionVersion'
import visudata from '@/store/modules/visudata'
import api from '@/store/api'
import router from '@/router'
import i18n from '@/i18n'
import { abilityPlugin, ability as appAbility } from '@/store/ability'

Vue.use(Vuex)

export const ability = appAbility

const state = {
  error: null,
  token: null,
}

const mutations = {
  SET_ERROR: function (state, data) {
    state.error = data
  },
  SET_TOKEN: function (state, data) {
    state.token = data
    localStorage.setItem('id_token', data)
    api.defaults.headers.common['Authorization'] = 'Bearer ' + data
  },
  DESTROY_SESSION: function (state) {
    localStorage.removeItem('id_token')
    localStorage.removeItem('authenticated')
    store.commit('SET_USER_AUTHENTICATED', false)
    store.commit('SET_TOKEN', null)
    store.commit('SET_USER_INFO', [])
  }
}

const getters = {
  token: state => state.token
}

const actions = {
  async check (store, state) {
    const token = localStorage.getItem('id_token')
    if ((token !== undefined) && (token !== null)) {
      try {
        await store.commit('SET_TOKEN', token)
        const userInfo = await User.getUserInfo()
        await store.commit('SET_USER_AUTHENTICATED', true)
        await store.commit('SET_USER_INFO', userInfo.data)
        // Set locale language
        if (userInfo.data.default_language)
          i18n.locale = userInfo.data.default_language
      } catch (e) {
        store.dispatch('logout');
      }
    } else {
      store.dispatch('logout');
    }
  },

  async register ({ dispatch, commit, state }, data) {
    try {
      const user = await User.createUser(data['firstName'], data['lastName'], data['username'], data['email'], data['password'])
      let userId = user.data.id
      // connect user to get the token
      const connect = await User.connectUser(data['username'], data['password'])
      if (connect.data.access_token != null) {
        try {
          await commit('SET_TOKEN', connect.data.access_token)
          const userInfo = await User.getUserInfo()
          await store.commit('SET_USER_AUTHENTICATED', true)
          await store.commit('SET_USER_INFO', userInfo.data)
          router.push({
            name: 'profile'
          })
        } catch (e) {
          Toast.open({
            message: i18n.t('msg_problem_backup'),
            type: 'is-danger'
          })
          commit('SET_ERROR', true)
        }
      }
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_creating_account'),
        type: 'is-danger'
      })
    }
  },

  async login ({ dispatch, commit, state }, data) {
    try {
      const connect = await User.connectUser(data['username'], data['password'])
      if (connect.data.access_token != null) {
        try {
          await commit('SET_TOKEN', connect.data.access_token)
          const userInfo = await User.getUserInfo()
          await store.commit('SET_USER_AUTHENTICATED', true)
          await store.commit('SET_USER_INFO', userInfo.data)
          // Set locale language
          if (userInfo.data.default_language)
            i18n.locale = userInfo.data.default_language
          router.push({
            name: 'dashboard'
          })
        } catch (e) {
          Toast.open({
            message: i18n.t('msg_problem_data_recovery'),
            type: 'is-danger'
          })
        }
      } else {
        Toast.open({
          message: i18n.t('msg_problem_account_credentials'),
          type: 'is-danger'
        })
        store.commit('SET_ERROR', true)
      }
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_account_credentials'),
        type: 'is-danger'
      })
    }
  },

  async logout (store) {
    router.push({
      name: 'signIn'
    })
    Toast.open({
      message: i18n.t('Your session has expired. Please try to reconnect.'),
      type: 'is-info',
    })
    await store.commit('DESTROY_SESSION')
  }
}

export const store = new Vuex.Store({
  plugins: [
    abilityPlugin
  ],
  state: state,
  mutations: mutations,
  getters: getters,
  actions: actions,
  modules: {
    user,
    scan,
    event,
    observingsystem,
    countries,
    utility,
    operationSheet,
    qualityControlSheet,
    organization,
    observatory,
    site,
    instrument,
    network,
    software,
    platform,
    vocabulary,
    collection,
    datasource,
    dataFile,
    processingLevel,
    product,
    productionVersion,
    visudata
  }
})

store.hotUpdate({
  plugins: [
    abilityPlugin
  ],
  mutations: mutations,
  modules: {
    user,
    scan,
    event,
    observingsystem,
    countries,
    utility,
    operationSheet,
    qualityControlSheet,
    organization,
    observatory,
    site,
    instrument,
    network,
    software,
    platform,
    vocabulary,
    collection,
    datasource,
    dataFile,
    processingLevel,
    product,
    productionVersion,
    visudata
  }
})

//export default store
