import api from '@/store/api'

export default {
  get (id) {
    return api.get('vocabulary/words/' + id + '/')
  },
  getList () {
    return api.get('vocabulary/words/')
  },
  create (data) {
    return api.post('vocabulary/words/', data)
  },
  update (id, data) {
    return api.patch('vocabulary/words/' + id + '/', data)
  },
  search (data) {
    return api.get('vocabulary/words/?search=' + data)
  },
  getTemplate (id) {
    return api.get('vocabulary/templates/' + id + '/')
  },
  getListTemplates (form) {
    var req = 'vocabulary/templates/?'
    if(form['page'])
      req = req + '&page=' + form['page']
    if(form['type'])
      req = req + 'type=' + form['type']
    if(form['subtype'])
      req = req + '&subtype=' + form['subtype']
    return api.get(req)
  },
  createTemplate (template) {
    return api.post('vocabulary/templates/', template)
  },
  updateTemplate (id, data) {
    return api.patch('vocabulary/templates/' + id + '/', data)
  },
}
