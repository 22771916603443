import { ToastProgrammatic as Toast } from 'buefy'
import Event from '@/store/api/event'
import router from '@/router'
import i18n from '@/i18n'

// state
const state = {
  events: {},
  event: {},
  eventDetail: {},
  isLoadingEvent: true,
}

// getters
const getters = {
  events: state => state.events,
  event: state => state.event,
  eventDetail: state => state.eventDetail,
  isLoadingEvent: state => state.isLoadingEvent
}

// mutations
const mutations = {
  SET_EVENTS: function (state, data) {
    state.events = data
  },
  SET_EVENT: function (state, data) {
    state.event = data
  },
  SET_EVENT_DETAIL: function (state, data) {
    state.eventDetail = data
  },
  INIT_EVENT: function (state, data) {
    state.event = {
      label: '',
      observingsystems: data.observingsystems,
      event_category: data.event_category,
      event_criticity: data.event_criticity,
      recipient: data.default_recipient,
      images: [],
      files: [],
      images_d: [],
      files_d: [],
      triggers: [],
      is_open: false,
    }
  },
  INIT_EVENTS: function (state) {
    state.events = {}
  },
  SET_LOADING_EVENT: function (state, data) {
    state.isLoadingEvent = data
  },
}

// actions: method for CRUD actions
const actions = {

  async createEvent ({ commit }, form) {
    try {
      const ev = await Event.create(form)
      commit('SET_EVENT', ev)
      // Reload detail
      const d = await Event.getDetail(ev.data.id)
      await commit('SET_EVENT_DETAIL', d.data)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
      router.push({
        name: 'dashboard'
      })
    } catch (e) {
      commit('SET_EVENT', null)
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
      return false
    }
  },

  async editEvent ({ commit }, form) {
    try {
      const ev = await Event.update(form['id'], form)
      commit('SET_EVENT', ev.data)
      // Reload detail
      const d = await Event.getDetail(ev.data.id)
      await commit('SET_EVENT_DETAIL', d.data)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
    }
  },

  async deleteEvent ({ commit }, form) {
    try {
      const ev = await Event.delete(form['id'])
      commit('SET_EVENT', ev.data)
      Toast.open({
        message: i18n.t('msg_ok_deleted'),
        type: 'is-success'
      })
      router.push({
        name: 'dashboard'
      })
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
    }
  },
 
  async getEvents ({ commit }, page) {
    try {
      const e = await Event.getList(page)
      await commit('SET_EVENTS', e.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getMyEvents ({ commit }, form) {
    try {
      const e = await Event.getListFromUserConnected(form)
      await commit('SET_EVENTS', e.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getMyLastEvents ({ commit }) {
    try {
      const e = await Event.getLastFromUserConnected()
      await commit('SET_EVENTS', e.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getEvent ({ commit }, id) {
    try {
      const c = await Event.get(id)
      await commit('SET_EVENT', c.data)
      const d = await Event.getDetail(id)
      await commit('SET_EVENT_DETAIL', d.data)
      // Load observing system to get event templates list
      //const o = await ObservingSystem.getDetail(c.data.observingsystem)
      //await commit('SET_OBSERVINGSYSTEM', o.data)
      // Load triggers
      if(c.data.triggers){
        const d = await Event.getListIdsIn(c.data.triggers)
        await commit('SET_EVENTS', d.data.results)
      }
      await commit('SET_LOADING_EVENT', false)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async searchEvents ({ commit }, text) {
    try {
      const c = await Event.search(text)
      await commit('SET_EVENTS', c.data.results)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async initEvent ({ commit }, data) {
    try {
      await commit('INIT_EVENT', data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async initEvents ({ commit }) {
    try {
      await commit('INIT_EVENTS')
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async initLoadingEvent ({ commit }) {
    await commit('SET_LOADING_EVENT', true)
  },

}

export default {
  state,
  getters,
  mutations,
  actions
}
