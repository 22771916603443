<template>
  <div class="status" v-if="display">
    <span class="tag" :style="{ 'background-color': backgroundColor, 'color': color }">
        {{ message }}
    </span>
  </div>
</template>

<script>

export default {
  props: {
    display: {
      type: Boolean,
      default: false,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    type: {
      type: String,
      require: true
    },
    backgroundColor: {
      type: String,
      require: true
    },
    color: {
      type: String,
      require: true
    }
  },
}
</script>