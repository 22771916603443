<template>
  <div id="app">
    <e-navbar></e-navbar>
     <!-- If user connected, add menu -->
    <transition name="page-transition" mode="out-in" v-if="user.authenticated">
      <div class="settings contain" >
        <e-menu/>
        <transition name="page-transition" mode="out-in">
          <router-view class="contain" :key="$route.path" />
        </transition>
      </div>
    </transition>
    <transition name="page-transition" mode="out-in" v-else>
      <router-view class="contain" :key="$route.path" />
    </transition>
  </div>
</template>

<style lang="scss">
  @import 'assets/scss/main.scss';
</style>


<script>
import Vuex from 'vuex'
import Navbar from './components/Navbar'
import Menu from '@/components/Menu.vue'

export default {
  name: 'App',
  components: {
    'e-navbar': Navbar,
    'e-menu' : Menu,
  },
  computed: {
    ...Vuex.mapGetters(['user'])
  }
}
</script>
