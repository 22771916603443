import api from '@/store/api'
import conf from '@/conf'

export default {
  setToken (token) {
    api.request({
      headers: {
        Authorization: 'Bearer ' + token,
      }
    })
  },
  getList (observingsystem) {
    var req = 'resources/users/'
    if(observingsystem) {
      req = 'observingsystem/discoveries/'
      req = req + observingsystem + '/user_list/'
    }
    return api.get(req)
  },
  getListByObservingSystems (observingsystems) {
    var req = 'observingsystem/discoveries/get_users/'
    if(observingsystems) {
      req = req + '?observingsystems=' + observingsystems
    }
    return api.get(req)
  },
  get (id) {
    return api.get('resources/users/' + id + '/')
  },
  connectUser (username, password) {
    const data = new FormData()

    data.append('grant_type', 'password')
    data.append('username', username)
    data.append('password', password)
    data.append('client_id', conf.CLIENT_ID)
    data.append('client_secret', conf.CLIENT_SECRET)

    return api.post(conf.API_URL + 'o/token/', data)
  },
  getUserInfo () {
    return api.get('user/')
  },
  createUser (firstName, lastName, username, email, password) {
    return api.post('resources/users/',
      {
        username: username,
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password
      })
  },
  updateUserInfo (userInfo) {
    return api.patch('user/', userInfo)
  }
}
