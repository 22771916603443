<template>
  <div class="medium-center">
    <observatory-form formType="edit" />
  </div>
</template>

<script>
import Vuex from 'vuex'
import { store } from '@/store'
import ObservatoryForm from './Form.vue'

export default {
  name: 'settingsObservatoryCreate',
  components: {
    'observatory-form': ObservatoryForm,
  },
  methods: {
    ...Vuex.mapActions({
      editObservatory: 'editObservatory',
    })
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('getObservatory', to.params.id).then(next())
  }
}
</script>