<template>
  <div class="operationSheet big-width">
    <div class="columns">
      <div class="column">
        <h1 class="title">
          {{ operationSheet.name }} 
        </h1>
        <ncform :form-schema="operationSheet.template" form-name="editOperationSheetForm" v-model="operationSheet.data" @submit="submit()"></ncform>
        <br>
        <el-button @click="submit()">Submit</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'
import { store } from '@/store'

export default {
  name: 'editOperationSheet',
  data () {
    return {
      token: null,
      data: {},
      name: '',
      value: '',
      formSchema: '',
      id: '',
    }
  },
  computed: {
    ...Vuex.mapGetters(['user', 'operationSheet'])
  },
  methods: {
    ...Vuex.mapActions({
      getOperationSheets: 'getOperationSheets',
      editOperationSheet: 'editOperationSheet'
    }),
    onCmCodeChange(newCode) {
      let res = eval("(" + newCode + ")")
      this.formSchema = res
    },
    createTemplate () {
      const data = {
        'name': this.name,
        'template': this.formSchema
      }
      this.createOperationSheetTemplate(data)
    },
    submit () {
      this.$ncformValidate('editOperationSheetForm').then(data => {
        if (data.result) {
          const data = {
            'id': this.operationSheet.id,
            'name': this.operationSheet.name,
            'template': this.operationSheet.template,
            'data': this.operationSheet.data
          }
          this.editOperationSheet(data)
        }
      })
    }
  },

  beforeRouteEnter (to, from, next) {
    store.dispatch('getOperationSheet', to.params.id).then(next())
  },

  mounted: function () {
    this.token = localStorage.getItem('id_token')
    this.headers = {
      'Authorization': 'Bearer ' + this.token,
    }
  }
}
</script>
