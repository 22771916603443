<template>
  <div>
    <e-list :title="$t('Instruments')" :data="[]" type="instrument" route="observingsystem.view" :displayCurrentStatus="true" :showDetail="true" :isDashboard="isDashboard"></e-list>
  </div>
</template>

<script>
import List from '@/components/List'

export default {
  name: 'ListInstrument',
  components: {
    'e-list': List
  },
  props: {
    observatory: {
      type: String,
      required: false
    },
    isDashboard: {
      type: Boolean,
      required: false,
      default: false
    }
  },
}
</script>