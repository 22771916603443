import { ToastProgrammatic as Toast } from 'buefy'
import Collection from '@/store/api/collection'
import Instrument from '@/store/api/instrument'
import router from '@/router'
import i18n from '@/i18n'

// state
const state = {
  myCollections: {},
  collections: {},
  collection: {},
  collectionDetail: {}
}

// getters
const getters = {
  myCollections: state => state.myCollections,
  collections: state => state.collections,
  collection: state => state.collection,
  collectionDetail: state => state.collectionDetail,
}

// mutations
const mutations = {
  SET_MY_COLLECTIONS: function (state, data) {
    state.myCollections = data
  },
  SET_COLLECTIONS: function (state, data) {
    state.collections = data
  },
  SET_COLLECTION: function (state, data) {
    state.collection = data
  },
  SET_COLLECTION_DETAIL: function (state, data) {
    state.collectionDetail = data
  },
  INIT_COLLECTION: function (state) {
    state.collection = {
      'name' : null,
      'label' : null
    }
  }
}

// actions: method for CRUD actions
const actions = {

  async createCollection ({ commit }, form) {
    try {
      const ins = await Collection.create(form)
      commit('SET_COLLECTION', ins)
      // Reload collection list
      const c = await Collection.getList()
      await commit('SET_COLLECTIONS', c.data)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
      router.push({
        name: 'settings.dataprod.collection.list'
      })
    } catch (e) {
      commit('SET_COLLECTION', null)
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
      return false
    }
  },

  async editCollection ({ commit }, form) {
    try {
      const ins = await Collection.update(form['id'], form)
      commit('SET_COLLECTION', ins.data)
      // Reload collection list
      const c = await Collection.getList()
      await commit('SET_COLLECTIONS', c.data)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
    }
  },

  async getMyCollections ({ commit }) {
    try {
      const c = await Collection.getListFromUserConnected()
      await commit('SET_MY_COLLECTIONS', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getCollections ({ commit }, form) {
    try {
      const c = await Collection.getList(form)
      await commit('SET_COLLECTIONS', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getCollection ({ commit }, id) {
    try {
      const c = await Collection.get(id)
      await commit('SET_COLLECTION', c.data)
      // Get instrument detail of data source to get clones list
      const instru = await Instrument.get(c.data.data_source)
      await commit('SET_INSTRUMENT', instru.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getCollectionDetail ({ commit }, id) {
    try {
      const c = await Collection.getDetail(id)
      await commit('SET_DATASOURCE_DETAIL', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async initCollection ({ commit }) {
    try {
      await commit('INIT_COLLECTION')
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

}

export default {
  state,
  getters,
  mutations,
  actions
}
