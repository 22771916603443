<template>
  <div>
    <e-list :title="$t('Sites')" :data="[]" type="site" route="observingsystem.view" :displayCurrentStatus="false" :showDetail="false" :isDashboard="isDashboard" :loading="loading"></e-list>
  </div>
</template>

<script>
import List from '@/components/List'

export default {
  name: 'ListSite',
  components: {
    'e-list': List
  },
  props: {
    observatory: {
      type: String,
      required: false
    },
    isDashboard: {
      type: Boolean,
      required: false,
      default: false
    }
  },
}
</script>