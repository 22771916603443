<template>
  <div class="dropdown is-active">
    <b-dropdown class="action-more" aria-role="list" position="is-bottom-left">
      <template #trigger>
        <el-button type="text">
          <i class="el-icon-more"></i>
        </el-button>
      </template>
      <b-dropdown-item aria-role="listitem" :has-link="element.events_templates.length || element.clones !== undefined && element.clones.length ? false : true">
        <span @click="dialogCreateEventVisible = true" v-if="element.events_templates.length || element.clones !== undefined && element.clones.length">{{ $t('Create event') }}</span>
        <router-link :to="{ name: 'settings.event.create', params: { observingsystem: element.id, trigger: isSubEvent?event.id:'' } }" :class="size" v-else>
          {{ $t('Create event') }}
        </router-link>
      </b-dropdown-item>
      <b-dropdown-item aria-role="listitem" @click="openDialogExportEvent()">
        <span>{{ $t('Export events') }}</span>
      </b-dropdown-item>
    </b-dropdown>
    
    <!-- Dialog create event -->
    <el-dialog :title="$t('Create event')" :visible.sync="dialogCreateEventVisible" v-if="element.events_templates.length || element.clones !== undefined && element.clones.length">
      <div class="observingsystem__choose_event">
        <div class="observingsystem__event_select_clone_used" v-if="element.clones !== undefined && element.clones.length">
          <h3>{{ $t('Choose the clone used') }}</h3>
          <div class="observingsystem__choose_event__list">
            <el-select v-model="sltClone" placeholder="Select">
              <el-option
                v-for="item in element.clones"
                :key="item.id"
                :label="item.label"
                :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="observingsystem__choose_event_template" v-show="showTemplates">
          <h3>{{ $t('Choose the model of event') }}</h3>
          <div class="observingsystem__choose_event__list">
            <span v-for="event_template in element._events_templates" :key="event_template.id">
              <router-link :to="{ name: 'settings.event.create', params: { observingsystem: sltClone, eventTemplate: event_template.id, trigger: isSubEvent?event.id:'' } }" class="button rounded" v-if="element.clones !== undefined && element.clones.length">
                {{ event_template.label }}
              </router-link>
              <router-link :to="{ name: 'settings.event.create', params: { observingsystem: element.id, eventTemplate: event_template.id, trigger: isSubEvent?event.id:'' } }" class="button rounded" v-else>
                {{ event_template.label }}
              </router-link>
            </span>
            <router-link :to="{ name: 'settings.event.create', params: { observingsystem: sltClone, trigger: isSubEvent?event.id:'' } }" class="button rounded" v-if="element.clones !== undefined && element.clones.length">
              {{ $t('Basic model') }}
            </router-link>
            <router-link :to="{ name: 'settings.event.create', params: { observingsystem: element.id, trigger: isSubEvent?event.id:'' } }" class="button rounded" v-else>
              {{ $t('Basic model') }}
            </router-link>
          </div>
        </div>
      </div>
    </el-dialog>
    
    <!-- Dialog export event -->
    <el-dialog :title="$t('Export events')" :visible.sync="dialogExportEventsVisible" :before-close="handleClose">
      <el-form :model="form" label-position="top">
        <el-form-item :label="$t('Sites events')">
          <el-switch
            v-model="form.showSitesEvents"
            :active-text="$t('Show sites events')"
            :inactive-text="$t('Do not show sites events')">
          </el-switch>
        </el-form-item>
        <el-form-item :label="$t('Filter by start date')">
          <el-date-picker
            v-model="form.dateStartRange"
            type="monthrange"
            align="right"
            unlink-panels
            :range-separator="$t('to')"
            :start-placeholder="$t('Start month')"
            :end-placeholder="$t('End month')"
            :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('Filter by end date')">
          <el-date-picker
            v-model="form.dateEndRange"
            type="monthrange"
            align="right"
            unlink-panels
            :range-separator="$t('to')"
            :start-placeholder="$t('Start month')"
            :end-placeholder="$t('End month')"
            :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('Select fields to display')">
          <el-transfer
            v-model="form.fieldsToExport"
            :titles="[$t('Fields available'), $t('Fields to export')]"
            :data="listOfFieldToExport">
          </el-transfer>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogExportEventsVisible = false">{{ $t('Cancel') }}</el-button>
        <el-button type="primary" @click.stop="exportEvent()">{{ $t('Export') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import conf from '@/conf'
import Vuex from 'vuex'
import i18n from '@/i18n'

export default {
  name: 'SelectAction',
  props: {
    element: {
      type: String,
      required: false
    },
    isSubEvent: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      dialogCreateEventVisible: false,
      dialogExportEventsVisible: false,
      sltClone: '',
      showTemplates: false,
      listOfFieldToExport: [],
      form: {
        showSitesEvents: false,
        dateStartRange: '',
        dateEndRange: '',
        fieldsToExport: []
      },
      pickerOptions: {
        shortcuts: [{
          text: i18n.t('This month'),
          onClick(picker) {
            picker.$emit('pick', [new Date(), new Date()]);
          }
        }, {
          text: i18n.t('This year'),
          onClick(picker) {
            const end = new Date();
            const start = new Date(new Date().getFullYear(), 0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: i18n.t('Last 6 months'),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setMonth(start.getMonth() - 6);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    }
  },
  computed: {
    ...Vuex.mapGetters(['event'])
  },
  methods: {
    ...Vuex.mapActions({
      exportEvents: 'exportEvents',
    }),

    init: function () {
      if(this.element.events_templates.length !== undefined)
        this.showTemplates = true
    },

    openDialogExportEvent: function () {
      this.generateFields(this.element)
      this.dialogExportEventsVisible = true
    },

    handleClose(done) {
      this.listOfFieldToExport = []
      this.form = {
        showSitesEvents: false,
        dateStartRange: '',
        dateEndRange: '',
        fieldsToExport: []
      }
      done()
    },

    exportEvent: function () {
      let data = {
        'id': this.element.id,
        'show_sites_events': this.form.showSitesEvents,
        'date_start_bs': typeof this.form.dateStartRange[0] != 'undefined' ? this.form.dateStartRange[0].toISOString() : '',
        'date_start_be': typeof this.form.dateStartRange[1] != 'undefined' ? this.form.dateStartRange[1].toISOString() : '',
        'date_end_bs': typeof this.form.dateEndRange[0] != 'undefined' ?  this.form.dateEndRange[0].toISOString() : '',
        'date_end_be': typeof this.form.dateEndRange[1] != 'undefined' ?  this.form.dateEndRange[1].toISOString() : '',
        'fields': JSON.stringify(this.listOfFieldToExport.filter(this.comparer(this.form.fieldsToExport)))
      }

      var url = conf.API_URL + 'observingsystem/discoveries/' + data['id'] + '/export_events/?'
      if(data['show_sites_events'])
        url = url + '&show_sites_events=' + data['show_sites_events']
      if(data['date_start_bs'])
        url = url + '&date_start_bs=' + data['date_start_bs']
      if(data['date_start_be'])
        url = url + '&date_start_be=' + data['date_start_be']
      if(data['date_end_bs'])
        url = url + '&date_end_bs=' + data['date_end_bs']
      if(data['date_end_be'])
        url = url + '&date_end_be=' + data['date_end_be']
      if(data['fields'])
        url = url + '&fields=' + data['fields']
      axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]))
        var fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', 'export_events.csv')
        document.body.appendChild(fileLink)
        fileLink.click()
        this.dialogExportEventsVisible = false
      })
    },
    comparer: function (otherArray){
      return function(current){
        return otherArray.some(function(other){
          return other == current.key
        });
      }
    },
    generateFields: function (obs) {
      const data = [
        {
          key: 'observingsystems_label',
          label: 'Observing systems'
        },
        {
          key: 'event_category',
          label: 'Event category'
        },
        {
          key: 'event_criticity',
          label: 'Event criticity'
        },
        {
          key: 'label',
          label: 'Label'
        },
        {
          key: 'description',
          label: 'Description'
        },
        {
          key: 'creator_fullname',
          label: 'Creator'
        },
        {
          key: 'contributors_fullname',
          label: 'Contributors'
        },
        {
          key: 'recipient_fullname',
          label: 'Recipient'
        },
        {
          key: 'date_start',
          label: 'Start date'
        },
        {
          key: 'date_end',
          label: 'End date'
        },
        {
          key: 'is_open',
          label: 'Open'
        },
        {
          key: 'comment',
          label: 'Comment'
        },
        {
          key: 'history',
          label: 'History'
        },
      ]
      // Get list of fields of template
      for (let i = 0; i < obs._events_templates.length; i++) {
        for (let n = 0; n < obs._events_templates[i].template.length; n++) {
          if('children' in obs._events_templates[i].template[n]) {
            for (let x = 0; x < obs._events_templates[i].template[n].children.length; x++) {
              if(obs._events_templates[i].template[n].children[x].name)
                data.push(
                  {
                    key: 'data.' + obs._events_templates[i].template[n].children[x].name,
                    label: obs._events_templates[i].template[n].children[x].name
                  }
                )
            } 
          } else {
            if(obs._events_templates[i].template[n].name)
              data.push(
                {
                  key: 'data.' + obs._events_templates[i].template[n].name,
                  label: obs._events_templates[i].template[n].name
                }
              )
          }
        }
        
      }
      this.listOfFieldToExport = data
      //return data
    }
  },
  watch: {
    sltClone: function () {
      this.showTemplates = true
    },
  },
  mounted: function () {
    this.init()
  },
}
</script>