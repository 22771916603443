import { ToastProgrammatic as Toast } from 'buefy'
import Site from '@/store/api/site'
import i18n from '@/i18n'
import router from '@/router'

// state
const state = {
  mySites: {},
  sites: {},
  site: {}
}

// getters
const getters = {
  mySites: state => state.mySites,
  sites: state => state.sites,
  site: state => state.site,
}

// mutations
const mutations = {
  SET_MY_SITES: function (state, data) {
    state.mySites = data
  },
  SET_SITES: function (state, data) {
    state.sites = data
  },
  SET_SITE: function (state, data) {
    state.site = data
  },
  INIT_SITE: function (state) {
    state.site = {
      'observatory_link': null,
      'parent_site': null,
      'name' : null,
      'label' : null,
      'address' : null,
      'postcode' : null,
      'city' : null,
      'country' : null,
      'geom' : null
    }
  }
}

// actions to update user profile
const actions = {

  async createSite ({ commit }, form) {
    try {
      const site = await Site.create(form)
      commit('SET_SITE', site)
      return site
    } catch (e) {
      commit('SET_SITE', null)
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
      return false
    }
  },

  async editSite ({ commit }, form) {
    try {
      const site = await Site.update(form['id'], form)
      commit('SET_SITE', site.data)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
      router.push({
        name: 'settings.site.list'
      })
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
    }
  },

  async getSite ({ commit }, id) {
    try {
      const c = await Site.get(id)
      await commit('SET_SITE', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getSiteDetail ({ commit }, id) {
    try {
      const c = await Site.getDetail(id)
      await commit('SET_SITE', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getSites ({ commit }, form) {
    try {
      const c = await Site.getList(form)
      await commit('SET_SITES', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getMySites ({ commit }, form) {
    try {
      const c = await Site.getListFromUserConnected(form)
      await commit('SET_MY_SITES', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async initSite ({ commit }) {
    try {
      await commit('INIT_SITE')
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

}

export default {
  state,
  getters,
  mutations,
  actions
}
