import Vue from 'vue'
import '@mdi/font/css/materialdesignicons.css'
import Element from 'element-ui'
import './assets/scss/element-variables.scss'
import { ElementTiptapPlugin } from 'element-tiptap'
import 'element-tiptap/lib/index.css'
import Buefy from 'buefy'
import './assets/scss/bulma/bulma-dark.scss'
import App from './App.vue'
import router from './router'
import { store, ability } from '@/store'
import { abilitiesPlugin } from '@casl/vue'
import { Can } from '@casl/vue'
import './registerServiceWorker'
import i18n from './i18n'
import VueCodemirror from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/paraiso-dark.css'
import VueFormulate from '@braid/vue-formulate'
import { LMap, LTileLayer, LMarker, LCircle, LPolyline, LPolygon } from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import { Icon } from 'leaflet'
import 'leaflet-draw/dist/leaflet.draw.css'
import 'leaflet-draw'

// Leaflet
Vue.component('l-map', LMap)
Vue.component('l-tile-layer', LTileLayer)
Vue.component('l-marker', LMarker)
Vue.component('l-circle', LCircle)
Vue.component('l-polyline', LPolyline)
Vue.component('l-polygon', LPolygon)
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})


// Manage authorization
Vue.use(abilitiesPlugin, ability)
Vue.component('Can', Can)

// Design config
Vue.use(Element)
Vue.use(Buefy)
Vue.use(VueCodemirror, { 
  options: { theme: 'monokai' },
  events: ['scroll']
})
Vue.use(VueFormulate)

// Import and use Vue Tiptap lib.
Vue.use(ElementTiptapPlugin)

Vue.config.productionTip = false

/* eslint-disable */
const vm = new Vue({
  el: '#app',
  i18n,
  router,
  store,
  render: h => h(App)
})