<template>
  <div class="instrument full-width">
    <e-observingsystem-form isVersion="false" observingSystemType="instrument" formType="edit"/>
  </div>
</template>

<script>
import Vuex from 'vuex'
import { store } from '@/store'
import ObservingSystemForm from '@/views/observingsystem/Form.vue'

export default {
  name: 'editObservingSystem',
  components: {
    'e-observingsystem-form': ObservingSystemForm,
  },
  computed: {
    ...Vuex.mapGetters(['user', 'template'])
  },
  methods: {
    ...Vuex.mapActions({
      getObservingSystem: 'getObservingSystem'
    }),
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('getObservingSystem', to.params.id).then(next())
  }
}
</script>