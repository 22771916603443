<template>
  <div class="medium-center">
    <el-form :model="word" label-position="top" ref="form" :rules="rules" :hide-required-asterisk="true">
      <el-form-item :label="$t('Category')" prop="category" >
        <el-select name="sltCategory" v-model="word.category" placeholder="Select" value-key="id" ref="sltCategory">
          <el-option
            v-for="value in listOfWordCategory"
            :key="value[0]"
            :label="value[1]"
            :value="value[0]">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('Name')" prop="name">
        <el-input v-model="word.name" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item :label="$t('Label')" prop="label">
        <el-input v-model="word.label" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item :label="$t('Data type')" prop="data_type" >
        <el-select name="sltDataType" v-model="word.data_type" placeholder="Select" value-key="id" ref="sltDataType">
          <el-option
            v-for="value in listOfWordDataType"
            :key="value[0]"
            :label="value[1]"
            :value="value[0]">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('Data widget')" prop="data_widget" >
        <el-select name="sltDataWidget" v-model="word.data_widget" placeholder="Select" value-key="id" ref="sltDataWidget">
          <el-option
            v-for="value in listOfWordDataWidget"
            :key="value[0]"
            :label="value[1]"
            :value="value[0]">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('References')" prop="references" >
        <el-tag
          :key="tag"
          v-for="tag in word.references"
          closable
          :disable-transitions="false"
          @close="handleClose(tag)">
          {{tag}}
        </el-tag>
        <el-input
          class="input-new-tag"
          v-if="inputVisible"
          v-model="inputValue"
          ref="saveTagInput"
          size="mini"
          @keyup.enter.native="handleInputConfirm"
          @blur="handleInputConfirm"
        >
        </el-input>
        <el-button v-else class="button-new-tag" size="small" @click="showInput">+ {{ $t('New reference') }}</el-button>
      </el-form-item>
      <el-checkbox v-model="word.limited_values" :label="$t('Limited values')" border></el-checkbox>
      <el-checkbox v-model="word.deprecated" :label="$t('Deprecated')" border></el-checkbox>
      <el-form-item :label="$t('Comment')" prop="comment">
        <el-input type="textarea" v-model="word.comment" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item class="lst-btn-end-form">
        <el-button @click.stop="save('form')">{{ $t('Update') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Vuex from 'vuex'
import conf from '@/conf'
import { store } from '@/store'
import i18n from '@/i18n'

export default {
  name: 'vocabularyCreate',
  data () {
    return {
      token: null,
      url: conf.API_URL + 'vocabulary/words/',
      headers: {},
      data: {},
      inputVisible: false,
      inputValue: '',
      file: null,
      image: '',
      rules: {
        name: [
          { required: true, message: i18n.t('msg_required_field'), trigger: 'blur' },
        ]
      }
    }
  },
  computed: {
    ...Vuex.mapGetters(['user', 'words', 'word', 'listOfWordCategory', 'listOfWordDataType', 'listOfWordDataWidget'])
  },
  methods: {
    ...Vuex.mapActions({
      editWord: 'editWord',
      getWord: 'getWord',
      getListOfWordCategory: 'getListOfWordCategory',
      getListOfWordDataType: 'getListOfWordDataType',
      getListOfWordDataWidget: 'getListOfWordDataWidget'
    }),
    save (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.editWord(this.word)
        } else {
          this.$buefy.toast.open({
            message: this.$root.$t('check_data_entered'),
            type: 'is-danger'
          })
          return false
        }
      })
    },
    handleClose(tag) {
      this.word.references.splice(this.word.references.indexOf(tag), 1)
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(
        this.$refs.saveTagInput.$refs.input.focus()
      )
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.word.references.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
  },

  beforeRouteEnter (to, from, next) {
    store.dispatch('getWord', to.params.id).then(next())
  },

  mounted: function () {
    this.id = this.$route.params.id
    this.token = localStorage.getItem('id_token')
    this.headers = {
      'Authorization': 'Bearer ' + this.token,
    },
    this.getListOfWordCategory(),
    this.getListOfWordDataType(),
    this.getListOfWordDataWidget()
  }
}
</script>