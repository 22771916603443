<template>
  <div class="medium-center">
    <productionversion-form/>
  </div>
</template>

<script>
import Vuex from 'vuex'
import { store } from '@/store'
import ProductionVersionForm from './Form.vue'

export default {
  name: 'settingsProductionVersionEdit',
  components: {
    'productionversion-form': ProductionVersionForm,
  },
  methods: {
    ...Vuex.mapActions({
      getProductionVersion: 'getProductionVersion'
    })
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('getProductionVersion', to.params.id).then(next())
  }
}
</script>