<template>
  <div class="full-center">
    <div class="page-content">
      <div class="title-view">
        <p class="title page-title">{{ observatory.name.toUpperCase() }}</p>
        <p class="subtitle">{{ observatory.label }} </p>
      </div>
      <b-tabs type="is-toggle" position="is-centered">
        <b-tab-item :label="$t('Data collections')" icon="chart-bar">
          <dataprod/>
        </b-tab-item>
        <b-tab-item :label="$t('Quicklook')" icon="eye-outline">
          <quicklook/>
        </b-tab-item>
        <b-tab-item :label="$t('Informations')" icon="information-variant">
          <div class="columns">
            <div class="column">
              <div class="tile is-parent is-vertical">
                <div class="card card--mg-btm">
                  <div class="card-image">
                    <figure class="image is-4by3">
                      <img :src="observatory.thumbnail_l" alt="Placeholder image">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="title is-4">
                          <router-link :to="{ name: 'observingsystem.view', params: { observingsystemType: 'observatory', id: observatory.id } }" >
                            {{ observatory.name.toUpperCase() }}
                          </router-link>
                        <p class="subtitle is-6" v-if="observatory.website"><el-link :href="observatory.website" target="_blank" type="info">{{ observatory.website }}</el-link></p>
                      </div>
                    </div>

                    <div class="content">
                      <h4>
                        {{ observatory.label }}
                      </h4>
                      {{ observatory.description }}
                    </div>
                  </div>
                </div>
                <e-list-site :isDashboard="false"></e-list-site>
              </div>
            </div>
            <div class="column">
              <div class="tile is-parent is-vertical">
                <e-list-instrument :isDashboard="false"></e-list-instrument>
                <e-list-software :isDashboard="false"></e-list-software>
                <e-list-platform :isDashboard="false"></e-list-platform>
              </div>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'
import Dataprod from '../../components/Dataprod'
import Quicklook from '../../components/Quicklook'
import InstrumentList from '@/components/instrument/List'
import SoftwareList from '@/components/software/List'
import PlatformList from '@/components/platform/List'
import SiteList from '@/components/site/List'

export default {
  name: 'VisuData',
  components: {
    'dataprod': Dataprod,
    'quicklook': Quicklook,
    'e-list-instrument': InstrumentList,
    'e-list-software': SoftwareList,
    'e-list-platform': PlatformList,
    'e-list-site': SiteList
  },
  data () {
    return {
      obs: this.$route.params.obs
    }
  },
  computed: {
    ...Vuex.mapGetters(['user', 'observatory'])
  },
  methods: {
    ...Vuex.mapActions({
      getObservatoryByName: 'getObservatoryByName',
    }),
    init: function () {
      this.getObservatoryByName(this.obs)
    }
  },

  mounted: function () {
    this.init()
  },
}
</script>