import api from '@/store/api'

export default {
  get (id) {
    return api.get('dataprod/dataFiles/' + id + '/')
  },
  getList (productionVersion, dateRelease) {
    var req = 'dataprod/dataFiles/?'
    if(productionVersion)
      req = req + '&production_version=' + productionVersion
    if(dateRelease)
      req = req + '&date_release=' + dateRelease
    return api.get(req)
  },
  create (data) {
    return api.post('dataprod/dataFiles/', data)
  },
  update (id, data) {
    return api.patch('dataprod/dataFiles/' + id + '/', data)
  },
  updateCloneByDateRange (data_collection, clone, date_start, date_end) {
    return api.post('dataprod/dataFiles/set_clone_by_daterange/?data_collection=' + data_collection + '&date_start=' + date_start+ '&date_end=' + date_end + '&clone=' + clone)
  },
}