import { ToastProgrammatic as Toast } from 'buefy'
import Observatory from '@/store/api/observatory'
import i18n from '@/i18n'

// state
const state = {
  campaigns: {},
  observatories: {},
  observatoriesAndCampaigns: {},
  observatory: {},
  observatoryDetail: {}
}

// getters
const getters = {
  campaigns: state => state.campaigns,
  observatories: state => state.observatories,
  observatoriesAndCampaigns: state => state.observatoriesAndCampaigns,
  observatory: state => state.observatory,
  observatoryDetail: state => state.observatoryDetail,
}

// mutations
const mutations = {
  SET_CAMPAIGNS: function (state, data) {
    state.campaigns = data
  },
  SET_OBSERVATORIES: function (state, data) {
    state.observatories = data
  },
  SET_OBSERVATORIES_AND_CAMPAIGNS: function (state, data) {
    state.observatoriesAndCampaigns = data
  },
  SET_OBSERVATORY: function (state, data) {
    state.observatory = data
  },
  SET_OBSERVATORY_DETAIL: function (state, data) {
    state.observatoryDetail = data
  },
  INIT_OBSERVATORY: function (state) {
    state.observatory = {}
  }
}

// actions: method for CRUD actions
const actions = {

  async createObservatory ({ commit }, form) {
    try {
      const obs = await Observatory.create(form)
      commit('SET_OBSERVATORY', obs.data)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
      return obs.data
    } catch (e) {
      commit('SET_OBSERVATORY', null)
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
      return false
    }
  },

  async editObservatory ({ commit }, form) {
    try {
      const obs = await Observatory.update(form['id'], form)
      commit('SET_OBSERVATORY', obs.data)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
    }
  },

  async getCampaigns ({ commit }) {
    try {
      const data =  {
        'observingsystemType': 'campaign'
      }
      const c = await Observatory.getList(data)
      await commit('SET_CAMPAIGNS', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getObservatories ({ commit }) {
    try {
      const data =  {
        'observingsystemType': 'observatory'
      }
      const c = await Observatory.getList(data)
      await commit('SET_OBSERVATORIES', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getObservatoriesAndAll ({ commit }) {
    try {
      const data =  {
        'observingsystemType': 'observatory'
      }
      const c = await Observatory.getList(data)
      var select_all = {
        id: "all",
        label: i18n.t('All'),
      }
      var lst_obs = [].concat(select_all, c.data)
      await commit('SET_OBSERVATORIES', lst_obs)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getObservatoriesAndCampaigns ({ commit }, data) {
    try {
      const c = await Observatory.getList(data)
      await commit('SET_OBSERVATORIES_AND_CAMPAIGNS', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getObservatory ({ commit }, id) {
    try {
      const c = await Observatory.get(id)
      await commit('SET_OBSERVATORY', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getObservatoryDetail ({ commit }, id) {
    try {
      const c = await Observatory.getDetail(id)
      await commit('SET_OBSERVATORY_DETAIL', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getObservatoryByName ({ commit }, obs) {
    try {
      const c = await Observatory.getByName(obs)
      await commit('SET_OBSERVATORY', c.data.results[0])
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async initObservatory ({ commit }) {
    try {
      await commit('INIT_OBSERVATORY')
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

}

export default {
  state,
  getters,
  mutations,
  actions
}
