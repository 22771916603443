import api from '@/store/api'

export default {
  get (id) {
    return api.get('dataprod/collections/' + id + '/')
  },
  getDetail (id) {
    return api.get('dataprod/collections/' + id + '/get_detail')
  },
  getList (form) {
    var req = 'dataprod/collections/?'
    if(form['page'])
      req = req + '&page=' + form['page']
    if(form['obs'])
      req = req + '&site__data_collections__observatory__name=' + form['obs']
    if(form['observingsystemType'])
      req = req + '&type_of_system=' + form['observingsystemType']
    if(form['datasource'])
      req = req + '&data_source=' + form['datasource']
    return api.get(req)
  },
  getListFromUserConnected () {
    return api.get('dataprod/collections/my_collections')
  },
  create (data) {
    return api.post('dataprod/collections/', data)
  },
  update (id, data) {
    return api.patch('dataprod/collections/' + id + '/', data)
  },
}