<template>
  <div class="qualityControlSheetForm">
      <section v-if="formType!='view'">
        <h1 class="title settings-title">
          {{ title }}
        </h1>
      </section>
      <div class="e-panel-actions" v-if="formType=='view'">
          <el-tooltip class="item" effect="dark" :content="$t('Edit operation sheet')" placement="top-start" v-if="operationSheet.users_can_update.includes(user.info.id)">
            <el-button class="el-button--xsmall" type="primary" icon="el-icon-edit" @click="setEditMode(true)" plain> {{ $t('Edit') }}</el-button>
          </el-tooltip>
      </div>
      <section class="content-form medium-center">
        <el-form :model="qualityControlSheet" label-position="top" ref="form" :rules="rules" :hide-required-asterisk="true" :disabled="!editMode">
          <b-tabs type="is-toggle" position="is-centered dashboard-mg">
            <b-tab-item :label="$t('Description')" icon="clipboard-outline">
              <el-form-item :label="$t('Date release')" prop="date_created" v-if="type!='show'">
                <el-date-picker
                  v-model="dateRange"
                  type="datetimerange"
                  :range-separator="$t('to')"
                  :start-placeholder="$t('Start date')"
                  :end-placeholder="$t('End date')"
                  :default-time="['08:00:00', '08:00:00']">
                </el-date-picker>
              </el-form-item>
              <el-form-item :label="$t('Status')" prop="status" >
                <el-radio-group v-model="qualityControlSheet.status">
                  <el-radio-button 
                    v-for="value in listOfQualityControlStatus" 
                    :key="value[0]"
                    :label="value[0]"
                    :value="value[0]">
                    {{ value[1] }}
                  </el-radio-button>
                </el-radio-group>
              </el-form-item>
              <FormulateForm 
                name="formulate"
                :class="!editMode?'disabled':''"
                v-model="qualityControlSheet.data"
                :schema="qualityControlSheet.template"
                @submit="verify('form')"
              />
              <el-form-item :label="$t('Description')" prop="description">
                <e-tiptap type="qualityControlSheet" :data="qualityControlSheet.description" :isDisabled="formType=='view'?true:false"/>
              </el-form-item>
              <el-form-item class="lst-btn-end-form" v-if="editMode">
                <el-button @click.stop="save('form')">
                  <span v-if="formType=='create'">{{ $t('Create') }}</span>
                  <span v-else>{{ $t('Save') }}</span>
                </el-button>
              </el-form-item>
            </b-tab-item> 
            <b-tab-item :label="$t('Documents')" icon="paperclip">
              <el-form-item :label="$t('Images')" prop="images" >
                <e-upload entityType="qualityControlSheet" name="uploadImage" :data="qualityControlSheet.images" fileType="image" :formType="formType" :fileList="qualityControlSheet.images_d" :headers="headers" :isDisabled="formType=='view'?true:false"/>
              </el-form-item>
              <el-form-item :label="$t('Files')" prop="files" >
                <e-upload entityType="qualityControlSheet" name="uploadFile" :data="qualityControlSheet.files" fileType="file" :formType="formType" :fileList="qualityControlSheet.files_d" :headers="headers" :isDisabled="formType=='view'?true:false"/>
              </el-form-item>
            </b-tab-item>
          </b-tabs>
        </el-form>
      </section>
  </div>
</template>

<script>
import Vuex from 'vuex'
import Tiptap from '@/components/Tiptap'
import Upload from '@/components/Upload'


export default {
  name: 'qualityControlSheetForm',
  components: {
    'e-tiptap': Tiptap,
    'e-upload': Upload
  },
  props: {
    id: {
      type: Number,
      required: false
    },
    title: {
      type: String,
      required: true
    },
    formType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      editMode: false,
      headers: '',
      dateRange: '',
      formSchema: [],
      formValues: {},
      clearable: false,
      productionVersionId: '',
      dateRelease: ''
    }
  },
  watch: {
    id (val, oldVal) {
      if (val !== oldVal) this.init()
    }
  },
  computed: {
    ...Vuex.mapGetters(['user', 'qualityControlSheet', 'qualityControlSheetTemplate', 'productionVersion', 'productionVersions', 'template', 'listOfQualityControlStatus'])
  },
  methods: {
    ...Vuex.mapActions({
      getProductionVersions: 'getProductionVersions',
      getProductionVersion: 'getProductionVersion',
      getQualityControlSheet: 'getQualityControlSheet',
      getTemplate: 'getTemplate',
      getListOfQualityControlStatus: 'getListOfQualityControlStatus',
      createQualityControlSheet: 'createQualityControlSheet',
      editQualityControlSheet: 'editQualityControlSheet',
    }),
    setEditMode(edit) {
      this.editMode = edit
      this.formType = 'edit'
    },
    init() {
      this.getListOfQualityControlStatus()
      if (this.formType=="view") {
        this.getQualityControlSheet(this.id).then(
          () => {
            this.dateRange = [this.qualityControlSheet.date_release_start, this.qualityControlSheet.date_release_end]
          }
        )
      } else {
        this.editMode = true
        this.productionVersionId = this.$route.params.production_version
        this.qualityControlSheet.production_version = this.productionVersionId
        this.getProductionVersion(this.productionVersionId).then(
          () => {
            if(this.productionVersion.qualitycontrolsheet_template){
              this.getTemplate(this.productionVersion.qualitycontrolsheet_template).then(
                () => {
                  this.qualityControlSheet.template = this.template.template
                }
              )
            }
          }
        )
      }
    },
    save () {
      this.$formulate.submit("formulate")
    },
    verify (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.qualityControlSheet.date_release_start = this.dateRange[0].toISOString()
          this.qualityControlSheet.date_release_end = this.dateRange[1].toISOString()
          if (this.formType == 'create') {
            this.qualityControlSheet.production_version = this.$route.params.production_version
            this.createQualityControlSheet(this.qualityControlSheet).then(() => {
              this.editMode = false
            })
          } else {
            this.editQualityControlSheet(this.qualityControlSheet).then(() => {
              this.editMode = false
              this.$emit("displayQualityControlSheetForm", false)
            })
          }
         
        } else {
          this.$buefy.toast.open({
            message: this.$root.$t('check_data_entered'),
            type: 'is-danger'
          })
          return false
        }
      })
    }
  },
  beforeMount: function () {
    this.init()
    this.token = localStorage.getItem('id_token')
    this.headers = {
      'Authorization': 'Bearer ' + this.token,
    }
  },
}
</script>
