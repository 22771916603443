import { Ability } from '@casl/ability'
import { AbilityBuilder } from '@casl/ability'

export const ability = new Ability()

export const abilityPlugin = (store) => {
  ability.update([{ actions: 'access', subject: 'public' }])

  return store.subscribe((mutation, state) => {
    switch (mutation.type) {
      case 'SET_USER_INFO':
        ability.update(defineAbilitiesFor(state.user.user))
        break
      case 'DESTROY_SESSION':
        ability.update([{ actions: 'access', subject: 'public' }])
        break
    }
  })
}

/***** Roles id
  * 1: PI
  * 2: Co-investigator
  * 3: Expert
  * 4: Instrument manager
  * 5: Operator
  * 6: Data manager
  * 7: Collaborator
  * 
  **** Interfaces
  * adm : administration interface
  * visu : visualization of metadata and/or graphic files
  * visu-private : visualization of metadata and/or graphic private files
  * visu-restricted : visualization of metadata and/or graphic restricted files
  * event : allow create event on observing systems
  * set-metdisc : discovery metadata entry interface
  * set-metop : operation metadata entry interface
  * set-metqual : quality control & validation  metadata entry interface
  * set-metevt : monitoring metadata entry interface
  * monit-prod : production monitoring
  * monit-instru : instrument monitoring
  * monit-obs : observatory monitoring
  * get-data : data downloading and extraction
  * find-voc : searching and proposing vocabulary interface
  * specific : specific interfaces
  * event : access and manage events of observing systems
  * export : access general export
  * 
  */

export default function defineAbilitiesFor (user) {
  const { can, cannot, rules } =  AbilityBuilder.extract()
  if (user.info.is_superuser) {
    can('manage', 'all')
    can('access', 'all')
    can('manage', 'event')
    can('access', 'event')
  } else if (user.info.groups.includes(1) || user.info.groups.includes(2) || user.info.groups.includes(3)) {
    can('manage', 'all')
    can('access', 'all')
    can('manage', 'event')
    can('access', 'event')
    can('manage', 'export')
    can('access', 'export')
    cannot('manage', 'adm')
    cannot('manage', 'specific')
    cannot('access', 'adm')
    cannot('access', 'specific')
  } else if (user.info.groups.includes(4)) {
    can('manage', 'all')
    can('access', 'all')
    cannot('manage', 'export')
    cannot('access', 'export')
    cannot('manage', 'adm')
    cannot('manage', 'specific')
    cannot('manage', 'set-metqual')
    cannot('access', 'adm')
    cannot('access', 'specific')
    cannot('access', 'set-metqual')
  } else if (user.info.groups.includes(5)) {
    can('access', 'visu')
    can('access', 'visu-private')
    can('access', 'visu-restricted')
    can('manage', 'event')
    can('access', 'event')
    can('access', 'set-metop')
    can('access', 'set-metevt')
    can('access', 'get-data')
    cannot('manage', 'export')
    cannot('access', 'export')
  } else if (user.info.groups.includes(6)) {
    can('access', 'visu')
    can('access', 'visu-private')
    can('access', 'visu-restricted')
    can('manage', 'event')
    can('access', 'event')
    can('manage', 'set-metevt')
    can('access', 'set-metevt')
    can('access', 'get-data')
    can('access', 'find-voc')
    cannot('manage', 'export')
    cannot('access', 'export')
  } else if (user.info.groups.includes(7)) {
    can('access', 'visu')
    can('access', 'visu-restricted')
    can('manage', 'event')
    can('access', 'event')
    can('access', 'set-metevt')
    can('access', 'get-data')
    cannot('manage', 'export')
    cannot('access', 'export')
  } else {
    can('access', 'public')
    cannot('manage', 'export')
    cannot('access', 'export')
  }
  return rules
}