import { ToastProgrammatic as Toast } from 'buefy'
import Datasource from '@/store/api/datasource'
import router from '@/router'
import i18n from '@/i18n'

// state
const state = {
  datasources: {},
  datasource: {}
}

// getters
const getters = {
  datasources: state => state.datasources,
  datasource: state => state.datasource,
}

// mutations
const mutations = {
  SET_DATASOURCES: function (state, data) {
    state.datasources = data
  },
  SET_DATASOURCE: function (state, data) {
    state.datasource = data
  },
  INIT_DATASOURCE: function (state) {
    state.datasource = {
      'name' : null,
      'label' : null,
      'current_status' : null,
      'pi' : null,
      'comment' : null
    }
  }
}

// actions: method for CRUD actions
const actions = {

  async createDatasource ({ commit }, form) {
    try {
      const instru = await Datasource.create(form)
      commit('SET_DATASOURCE', instru)
      // Reload datasources list
      const c = await Datasource.getList()
      await commit('SET_DATASOURCES', c.data)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
      router.push({
        name: 'settings.datasource.list'
      })
    } catch (e) {
      commit('SET_DATASOURCE', null)
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
      return false
    }
  },

  async editDatasource ({ commit }, form) {
    try {
      const instru = await Datasource.update(form['id'], form)
      commit('SET_DATASOURCE', instru.data)
      // Reload datasources list
      const c = await Datasource.getList()
      await commit('SET_DATASOURCES', c.data)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
    }
  },

  async getDatasources ({ commit }) {
    try {
      const c = await Datasource.getList()
      await commit('SET_DATASOURCES', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getDatasource ({ commit }, id) {
    try {
      const c = await Datasource.get(id)
      await commit('SET_DATASOURCE', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async initDatasource ({ commit }) {
    try {
      await commit('INIT_DATASOURCE')
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

}

export default {
  state,
  getters,
  mutations,
  actions
}
