<template>
  <div class="medium-center">
    <organization-form formType="create" />
  </div>
</template>

<script>
import Vuex from 'vuex'
import OrganizationForm from './Form.vue'

export default {
  name: 'settingsOrganizationCreate',
  components: {
    'organization-form': OrganizationForm,
  },
  methods: {
    ...Vuex.mapActions({
      initOrganization: 'initOrganization'
    })
  },
  mounted: function () {
    this.initOrganization()
  }
}
</script>