<template>
  <div class="vocabulary full-width">
    <h1 class="title settings-title">
      {{ $t('Vocabulary') }} 
    </h1>
    <div class="columns medium-center">
      <div class="column">
        <section>
          <el-input
            class="mg-btm-sm"
            :placeholder="$t('Search')"
            prefix-icon="el-icon-search"
            v-model="search">
          </el-input>
          <b-table
            :data="words.results"
            :paginated="isPaginated"
            :per-page="perPage"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :default-sort-direction="defaultSortDirection"
            default-sort="id"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page">
            <b-table-column field="name" :label="$t('Name')" sortable v-slot="props">
                {{ props.row.name }}
            </b-table-column>

            <b-table-column field="label" :label="$t('Label')" sortable v-slot="props">
                {{ props.row.label }}
            </b-table-column>

            <b-table-column field="category" :label="$t('Category')" sortable v-slot="props">
                {{ props.row.category }}
            </b-table-column>

            <b-table-column field="actions" :label="$t('Actions')" v-slot="props">
                <router-link :to="{ name: 'settings.vocabulary.edit', params: { id: props.row.id } }" class="button rounded is-primary">
                  <i class="el-icon-edit"></i>
                </router-link>
            </b-table-column>
          </b-table>
          <router-link :to="{name: 'settings.vocabulary.create'}">
            <el-button icon="el-icon-plus" plain>{{ $t('Add') }}</el-button>
          </router-link>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'
import { store } from '@/store'

export default {
  name: 'listWords',
  data () {
    return {
      isPaginated: true,
      isPaginationSimple: false,
      defaultSortDirection: 'asc',
      currentPage: 1,
      perPage: 10,
      token: null,
      data: null,
      name: '',
      value: '',
      formSchema: '',
      id: '',
      search: ''
    }
  },
  computed: {
    ...Vuex.mapGetters(['user', 'words'])
  },
  watch: {
    search: function (val) {
      if (val) {
        store.dispatch('searchWords', val)
      } else {
        store.dispatch('getWords')
      }
    }
  },
  methods: {
    ...Vuex.mapActions({
      getWords: 'getWords',
      searchWords: 'searchWords'
    }),
  },
  mounted: function () {
    this.$nextTick(function () {
      this.getWords()
    })
  }
}
</script>
