<template>
  <div class="medium-center">
    <productionversion-form/>
  </div>
</template>

<script>
import Vuex from 'vuex'
import ProductionVersionForm from './Form.vue'

export default {
  name: 'settingsProductionVersionCreate',
  components: {
    'productionversion-form': ProductionVersionForm,
  },
  methods: {
    ...Vuex.mapActions({
      initProductionVersion: 'initProductionVersion'
    })
  },
  mounted: function () {
    this.initProductionVersion()
  }
}
</script>