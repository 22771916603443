<template>
  <div class="dataprod">
    <section class="section-datacalendar">
      <div class="tile is-ancestor">
        <div class="tile is-parent is-vertical">
          <div class="tile is-vertical box">
            <p class="title">{{ $t('Data collections') }}</p>
            <div class="table_filter">
              <el-select name="sltYear" v-model="sltVisudataYears" :placeholder="$t('Year')" value-key="id" ref="sltYear">
                <el-option
                  v-for="year in visudataYears"
                  :key="year"
                  :label="year"
                  :value="year">
                </el-option>
              </el-select>
              <el-select name="sltProcessingLevel" v-model="sltProcessingLevels" :placeholder="$t('Processing level')" value-key="id" ref="sltProcessingLevel">
                <el-option
                  v-for="item in processingLevels"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </div>
            <b-table
              :data="visudata"
              :paginated="isPaginated"
              :per-page="perPage"
              :current-page.sync="currentPage"
              :pagination-simple="isPaginationSimple"
              :default-sort-direction="defaultSortDirection"
              :hoverable="isHoverable"
              default-sort="id"
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page"
              :loading="loading">
              <b-table-column 
                field="id" 
                :label="$t('Collections')"
                sortable
                v-slot="props"
              >
                <router-link :to="{ name: 'observingsystem.view', params: { observingsystemType: 'data_collection', id: props.row.id } }" class="button rounded">
                  {{ props.row.name }}
                </router-link>
              </b-table-column>

              <b-table-column 
                v-for="(n, i) in 12"
                :key="i"
                :label="months_name[i]"
                :field="'months[' + i + '].result[0].data_production_version__product__category'"
                width="130"
                centered
                v-slot="props"
              >
                <div class="dataprod__center" v-if="props.row.months[i].result[0]">
                  <img class="img-button" :src="src_processed" v-if="props.row.months[i].result[0].production_version__product__category == 'processed'" :height="size_img" :width="size_img" @click="loadVisudataTimeline(props.row.id, i)">
                  <img class="img-button" :src="src_raw" v-else-if="props.row.months[i].result[0].production_version__product__category == 'raw'" :height="size_img" :width="size_img" @click="loadVisudataTimeline(props.row.id, i)">
                </div>
                <div class="dataprod__center" v-else>
                  _
                </div>
              </b-table-column>
            </b-table>
          </div>
        </div>
      </div>       
    </section>

    <section class="section-timeline" v-if="visudataTimeline[0]">
      <div class="tile is-ancestor">
        <div class="tile is-vertical box">
          <p class="title">{{ $t('Timeline') }}</p>
          <e-loading v-if="loadingTimeline"></e-loading>
          <el-timeline v-else>
            <el-timeline-item 
              v-for="data in visudataTimeline" 
              :key="data.timeline_date"
              :timestamp="data.timeline_date" 
              hide-timestamp="true"
              placement="top"
            >
              <time :datetime="data.timeline_date" class="icon">
                <strong>{{ months_name[data.timeline_month-1] }}</strong>
                <span>{{ data.timeline_day }}</span>
              </time>
              <el-collapse class="timeline-collapse" accordion="true" v-model="activeNames" @change="loadVisudataDatafiles">
                <el-collapse-item v-for="res in data.results" :key="res.production_version + ',' + res.date_release" :name="res.production_version + ',' + res.date_release" :title="$t('Source : ') + res.production_version__product__data_collection__data_source__name + ' | ' + $t('Product : ') + res.production_version__product__name + ' | ' + $t('Processing level : ') + res.production_version__product__processing_level__name">
                  <el-button v-for="ope in operationSheets" :key="ope.id" @click="loadOperationSheetForm(ope.id)" round><i class="el-icon-s-order"></i> {{ $t('Operation sheet') }}</el-button>
                  <el-button v-for="qc in qualityControlSheets" :key="qc.id" @click="loadQualityControlSheetForm(qc.id)" round><i class="el-icon-s-claim"></i> {{ $t('Quality control sheet') }}</el-button>
                  <e-loading v-if="loadingData"></e-loading>
                  <div v-else>
                    <br/>
                    <div class="flex-images">
                      <div v-for="datafile in dataFiles" :key="datafile.id">
                        <div class="flex-images__img_half" v-if="datafile.filename.split('.')[1] == 'png' || datafile.filename.split('.')[1] == 'jpeg' || datafile.filename.split('.')[1] == 'jpg'">
                          <el-image :src="file_url + datafile.id">
                            <div slot="placeholder" class="image-slot">
                              {{ $t('Loading') }}<span class="dot">...</span>
                            </div>
                          </el-image>
                        </div>
                      </div>
                    </div>
                    <div v-if="typeof(productionVersion.users_can_access_private_files) !== 'undefined' && productionVersion.users_can_access_private_files.includes(user.info.id)">
                      <div v-for="datafile in dataFiles" :key="datafile.id">
                        <el-link :href="file_url + datafile.id" v-if="datafile.access_level_initial=='private'">{{ datafile.filename }}</el-link>
                      </div>
                    </div>
                    <div v-else>
                      <el-alert
                        :title="$t('Sorry, you don\'t have access of private files')"
                        type="warning"
                        :closable="false"
                        show-icon>
                      </el-alert>
                    </div>
                    <div v-if="typeof(productionVersion.users_can_access_restricted_files) !== 'undefined' && productionVersion.users_can_access_restricted_files.includes(user.info.id)">
                      <div v-for="datafile in dataFiles" :key="datafile.id">
                        <el-link :href="file_url + datafile.id" v-if="datafile.access_level_initial=='restricted'">{{ datafile.filename }}</el-link>
                      </div>
                    </div>
                    <div v-else>
                      <el-alert
                        :title="$t('Sorry, you don\'t have access of restricted files')"
                        type="warning"
                        :closable="false"
                        show-icon>
                      </el-alert>
                    </div>
                    <div>
                      <div v-for="datafile in dataFiles" :key="datafile.id">
                        <el-link :href="file_url + datafile.id" v-if="datafile.access_level_initial=='public'">{{ datafile.filename }}</el-link>
                      </div>
                    </div><br/>
                    <div v-if="dataFiles.length == 10">
                      <el-alert
                        :title="$t('There is more data. To retrieve them, click on the download zip archive button.')"
                        type="info"
                        :closable="false"
                        show-icon>
                      </el-alert>
                    </div>
                    <div class="timeline-download-zip" v-if="dataFiles.length && typeof(productionVersion.users_can_access_private_files) !== 'undefined' && productionVersion.users_can_access_private_files.includes(user.info.id)">
                      <el-link :href="zip_url + 'production_version=' + res.production_version + '&date_release=' + res.date_release.split('T')[0]" icon="el-icon-download">{{ $t('Download zip archive') }}</el-link>
                    </div>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
      <el-dialog
        :title="$t('Operation sheet')"
        :visible.sync="showOperationSheetForm"
        width="80%"
        center v-if="operationSheetID">
        <e-operationsheet-form :id="operationSheetID" title="" formType="view" @displayOperationSheetForm="showOperationSheetForm = $event"></e-operationsheet-form>
      </el-dialog>
      <el-dialog
        :title="$t('Quality control sheet')"
        :visible.sync="showQualityControlSheetForm"
        width="80%"
        center v-if="qualityControlSheetID">
        <e-qualitycontrolsheet-form :id="qualityControlSheetID" title="" formType="view" @displayQualityControlSheetForm="showQualityControlSheetForm = $event"></e-qualitycontrolsheet-form>
      </el-dialog>
    </section>
  </div>
</template>

<script>
import Vuex from 'vuex'
import conf from '@/conf'
import { store } from '@/store'
import i18n from '@/i18n'
import OperationSheetForm from '@/components/form/OperationSheet'
import QualityControlSheetForm from '@/components/form/QualityControlSheet'
import Loading from '@/components/Loading'

export default {
  name: 'Dataprod',
  components: {
    'e-operationsheet-form': OperationSheetForm,
    'e-qualitycontrolsheet-form': QualityControlSheetForm,
    'e-loading': Loading
  },
  data () {
    return {
      showOperationSheetForm: false,
      showQualityControlSheetForm: false,
      operationSheetID: null,
      qualityControlSheetID: null,
      obs: this.$route.params.obs,
      src_processed: require('../assets/img/processed.png'),
      src_raw: require('../assets/img/raw.png'),
      width_month: 100,
      size_img: 35,
      token: null,
      url: conf.API_URL + 'resources/observatories/',
      file_url: conf.API_URL + conf.FILE_URL,
      zip_url: conf.API_URL + conf.ZIP_URL,
      headers: {},
      data: {},
      sltVisudataYears: '',
      sltProcessingLevels: '',
      isPaginated: true,
      isHoverable: true,
      isPaginationSimple: false,
      loadingTimeline: false,
      loadingData: false,
      defaultSortDirection: 'asc',
      currentPage: 1,
      perPage: 10,
      year: null,
      loading:false,
      activeNames: [],
      rules: {
        name: [
          { required: true, message: i18n.t('msg_required_field'), trigger: 'blur' },
        ],
        website: [
          { type: 'url', required: false, message: i18n.t('msg_invalid_url'), trigger: 'change' }
        ]
      },
      months_name: [
        i18n.t('Jan'),
        i18n.t('Feb'),
        i18n.t('Mar'),
        i18n.t('Apr'),
        i18n.t('May'),
        i18n.t('Jun'),
        i18n.t('Jul'),
        i18n.t('Aug'),
        i18n.t('Sep'),
        i18n.t('Oct'),
        i18n.t('Nov'),
        i18n.t('Dec')
      ]
    }
  },
  computed: {
    ...Vuex.mapGetters(['user', 'productionVersion', 'observatory', 'visudata', 'visudataTimeline', 'visudataYears', 'processingLevels', 'dataFiles', 'operationSheets', 'qualityControlSheets'])
  },
  methods: {
    ...Vuex.mapActions({
      initVisudata: 'initVisudata',
      initVisudataTimeline: 'initVisudataTimeline',
      getObservatoryByName: 'getObservatoryByName',
      getProductionVersion: 'getProductionVersion',
      getVisudata: 'getVisudata',
      getVisudataTimeline: 'getVisudataTimeline',
      getVisudataYears: 'getVisudataYears',
      getProcessingLevels: 'getProcessingLevels',
      getDataFiles: 'getDataFiles',
      getOperationSheets: 'getOperationSheets',
      getQualityControlSheets: 'getQualityControlSheets'
    }),
    init: function () {
      this.getProcessingLevels()
      if (this.$route.name == 'observatory.visuData') {
        this.getObservatoryByName(this.obs)
        /* eslint-disable */
        var data = {
          obs_n : this.obs
        }
        this.getVisudataYears(data).then(response => {
          this.sltVisudataYears = this.visudataYears[0]
        })
        this.initVisudataTimeline()
      } else {
        var data = {
          obs_n : null
        }
        /* eslint-disable */
        this.getVisudataYears(data).then(response => {
          this.sltVisudataYears = this.visudataYears[0]
        })
        this.initVisudata(data)
        this.initVisudataTimeline()
      } 
    },
    loadVisudata: function () {
      var visudata = {
        obs : this.obs,
        year : this.sltVisudataYears,
        processing_level : this.sltProcessingLevels,
      }
      this.getVisudata(visudata)
    },
    loadVisudataTimeline: function (datacollection_n, month) {
      this.loadingTimeline = true
      var visudata = {
        datacollection_n : datacollection_n,
        year : this.sltVisudataYears,
        month : month+1,
        processing_level: this.sltProcessingLevels
      }
      this.getVisudataTimeline(visudata).then(
        () => {
          this.loadingTimeline = false
        }
      )
    },
    loadVisudataDatafiles: function (id) {
      this.loadingData = true
      let data = {
        'production_version': id.split(",")[0],
        'date_release': id.split(",")[1].split("T")[0]
      }
      if(data) {
        this.getProductionVersion(data.production_version)
        this.getOperationSheets(data)
        this.getQualityControlSheets(data)
        this.getDataFiles(data).then(
          () => {
            this.loadingData = false
          }
        )
      }
    },
    loadOperationSheetForm: function (id) {
      this.showOperationSheetForm = true
      this.operationSheetID = id
    },
    loadQualityControlSheetForm: function (id) {
      this.showQualityControlSheetForm = true
      this.qualityControlSheetID = id
    },
  },

  watch: {
    sltVisudataYears: function () {
      this.loadVisudata()
    },
    sltProcessingLevels: function () {
      this.loadVisudata()
    }
  },

  beforeRouteEnter (to, from, next) {
    if (this.$route.name == 'observatory.visuData') {
      store.dispatch('getObservatoryByName', to.params.obs)
      store.dispatch('initVisudata', to.params.obs).then(
        store.dispatch('initVisudataTimeline').then(
          next()
        )
      )
    }
  },

  beforeRouteUpdate (to, from, next) {
    if (this.$route.name == 'observatory.visuData') {
      store.dispatch('getObservatoryByName', to.params.obs)
      store.dispatch('initVisudata', to.params.obs).then(
        store.dispatch('initVisudataTimeline').then(
          next()
        )
      )
    }
  },

  mounted: function () {
    this.init()
  },
}
</script>