<template>
  <div class="tile is-child box" v-if="typeof data != 'undefined' && data != null && data.length > 0">
    <p class="title">
      {{ title }}
      <b-dropdown class="action-more" aria-role="list" position="is-bottom-left" v-if="type == 'event'">
        <template #trigger>
          <el-button type="text">
            <i class="el-icon-more"></i>
          </el-button>
        </template>
        <b-dropdown-item aria-role="listitem" :has-link="true">
          <router-link :to="{ name: 'settings.event.create' }">
            {{ $t('Create event') }}
          </router-link>
        </b-dropdown-item>
      </b-dropdown>
    </p>
    <e-loading v-if="mainLoading" class="dashboard-block"></e-loading>
    <b-table
      class="table"
      :data="data"
      backend-pagination
      :loading="loading"
      :paginated="isPaginated"
      :per-page="perPage"
      @page-change="onPageChange"
      :current-page.sync="currentPage"
      :pagination-simple="isPaginationSimple"
      :default-sort-direction="defaultSortDirection"
      :total="total"
      default-sort="name"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      :detailed="showDetail"
      detail-key="id"
      :show-detail-icon="showDetailIcon"
      @details-open="(row) => loadCollections(row.id)"
      :opened-detailed="defaultOpenedDetails"
      :class="displayCurrentStatus ? 'has-status' : ''"
      v-else
      >

      
      <b-table-column field="label" :label="$t('Label')" :sortable="isDashboard ? 'sortable' : null" class="align" v-if="type != 'event'" v-slot="props">
        <router-link :to="{ name: route, params: { observingsystemType: type, id: props.row.id } }" class="button rounded button--label">
          {{ props.row.label }}
        </router-link>
      </b-table-column>

      <b-table-column field="current_status_label" :label="$t('Status')" :sortable="isDashboard ? 'sortable' : null" class="align" v-if="displayCurrentStatus" v-slot="props">
        <status :display="displayCurrentStatus" :message="$t(props.row.current_status_label)" :backgroundColor="props.row.current_status_color" :color="'#1C1C1C'" v-if="props.row.current_status"></status>
      </b-table-column>

      <b-table-column field="current_observatory_name" :label="$t('Obs.')" :sortable="isDashboard ? 'sortable' : null" class="align" v-if="type != 'event' && type != 'site'" v-slot="props">
        {{ props.row.current_observatory_name }}
      </b-table-column>

      <b-table-column field="observingsystems_label" :sortable="isDashboard ? 'sortable' : null" :label="$t('Linked to')" v-if="type == 'event'" v-slot="props">
        {{ props.row.observingsystems_label }}
      </b-table-column>

      <b-table-column field="event_criticity_label" v-if="type == 'event'" class="list-label" v-slot="props">
        <div class="dashboard-event-list__middle">
          <router-link :to="{ name: route, params: { id: props.row.id } }" class="button rounded button--label">
            {{ props.row.label }}
          </router-link>
          <span class="event-status__item" v-if="props.row.event_criticity">
            <el-tooltip class="item" effect="dark" :content="$t('Event criticity') + ': ' + props.row.event_criticity_label" placement="top-start">
              <i class="mdi mdi-alert-decagram event-status__icon" :style="{ 'color': props.row.event_criticity_color }"></i>
            </el-tooltip>
          </span>
        </div>
      </b-table-column>

      <b-table-column field="is_open" v-if="type == 'event'" sortable :label="$t('State')" class="align" v-slot="props">
        <span class="event-state__item">
          <b-tag type="is-light" v-if="props.row.is_open"> {{ $t('Open') }} </b-tag>
          <b-tag type="is-dark" v-else> {{ $t('Closed') }} </b-tag>
        </span>
      </b-table-column>

      <b-table-column field="date_start" :sortable="isDashboard ? 'sortable' : null" label="Date" centered v-if="type == 'event'" v-slot="props">
        <span class="tag" :class="new Date(props.row.date_end)>new Date()?'is-success':'is-danger'" v-if="props.row.date_end && props.row.is_open">
          {{ new Date(props.row.date_start).toLocaleDateString() }}
        </span>
        <span class="tag" v-else>
          {{ new Date(props.row.date_start).toLocaleDateString() }}
        </span>
      </b-table-column>

      <b-table-column field="recipient_fullname" :sortable="isDashboard ? 'sortable' : null" :label="$t('Recipient')" v-if="type == 'event'" v-slot="props">
        {{ props.row.recipient_fullname }}
      </b-table-column>

      <b-table-column field="city" :sortable="isDashboard ? 'sortable' : null" :label="$t('City')" v-if="type == 'site'" v-slot="props">
        {{ props.row.city }}
      </b-table-column>

      <b-table-column field="country" :sortable="isDashboard ? 'sortable' : null" :label="$t('Country')" v-if="type == 'site'" v-slot="props">
        {{ props.row.country_name }}
      </b-table-column>

      <b-table-column field="pi_name" :sortable="isDashboard ? 'sortable' : null" :label="$t('PI')" v-if="type == 'instrument' || type == 'platform' || type == 'software'" v-slot="props">
        {{ props.row.pi_name }}
      </b-table-column>
      <b-table-column field="role" :label="$t('My role')" v-if="(type == 'instrument' || type == 'platform' || type == 'software') && isDashboard" v-slot="props">
        <span v-if="props.row.pi == user.info.id"> {{ $t('PI') }} </span>
        <div v-for="coi in props.row.co_investigators" :key="coi">
          <span v-if="coi.id == user.info.id"> {{ $t('Co-Investigator') }} </span>
        </div>
        <div v-if="props.row.experts">
          <span v-if="props.row.experts.includes(user.info.id)"> {{ $t('Expert') }} </span>
        </div>
        <div v-if="props.row.collaborators">
          <span v-if="props.row.collaborators.includes(user.info.id)"> {{ $t('Collaborator') }} </span>
        </div>
        <div v-if="props.row.instrument_manager">
          <span v-if="props.row.instrument_manager == user.info.id"> {{ $t('Instrument manager') }} </span>
        </div>
        <div v-if="props.row.local_instrument_manager">
          <span v-if="props.row.local_instrument_manager == user.info.id"> {{ $t('Local instrument manager') }} </span>
        </div>
        <div v-if="props.row.data_manager">
          <span v-if="props.row.data_manager == user.info.id"> {{ $t('Data manager') }} </span>
        </div>
        <div v-if="props.row.local_data_manager">
          <span v-if="props.row.local_data_manager == user.info.id"> {{ $t('Local data manager') }} </span>
        </div>
        <div v-if="props.row.operators">
          <span v-if="props.row.operators.includes(user.info.id)"> {{ $t('Operator') }} </span>
        </div>
      </b-table-column>
      
      <b-table-column field="event" v-if="type != 'event' && isDashboard" v-slot="props">
        <e-select-action :element="props.row" isSubEvent="false"></e-select-action>
      </b-table-column>

      <template slot="detail" slot-scope="props">
        <div v-if="type=='site'">
          <tr v-for="item in props.row.children_sites" :key="item.id">
              <td>
                <router-link :to="{ name: 'observingsystem.view', params: { observingsystemType: 'site', id: item.id } }">
                  {{ item.label }}
                </router-link>
              </td>
              <td class="has-text-centered">
                <e-select-action :element="item" isSubEvent="false"></e-select-action>
              </td>
          </tr>
        </div>
        <el-table
          :data="collections"
          style="width: 100%"
          v-if="type=='instrument' || type=='platform' || type=='software'">
          <el-table-column type="expand">
            <template slot-scope="collections">
              <div v-for="product in collections.row.products" :key="product.id">
                <div class="listProductionVersion" v-for="production_version in product.production_versions" :key="production_version.id">
                  <span class="listProductionVersion__title">{{ production_version.name }} </span>
                  <span class="listProductionVersion__action" v-if="production_version.is_operationsheet || production_version.is_qualitycontrolsheet">
                    <e-production-version-select-action :element="production_version" isSubEvent="false"></e-production-version-select-action>
                  </span>
                </div> 
              </div>  
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('Data collections')"
          >
            <template slot-scope="collection">
              <router-link :to="{ name: 'observingsystem.view', params: { observingsystemType: 'data_collection', id: collection.row.id } }" class="button rounded">
                {{ collection.row.name }}
              </router-link>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('Actions')"
            v-if="typeof props.row.clones != 'undefined' && props.row.clones.length"
          >
            <template slot-scope="collection">
              <el-button 
                type="text" 
                @click="loadChooseClone(collection.row.id)" 
                v-if="collection.row.users_can_manage_clones.includes(user.info.id)"
              > 
                {{ $t('Clone in use') }} 
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </b-table>
    <el-dialog
      :title="$t('Choose clone in use')"
      :visible.sync="dialogChooseCloneVisible"
      width="30%"
      :before-close="handleCloseSaveClone"
    >
      <div>
        <b-tabs position="is-centered" type="is-toggle">
            <b-tab-item :label="$t('Current')" icon="square-edit-outline">
              <div class="el-dialog-tab-content">
                <h4>{{ $t('Indicate the clone in use') }}</h4>
                <el-form ref="formUpdateClone">
                  <el-form-item>
                    <el-select v-model="collection.instrument_clone" placeholder="Select">
                      <el-option
                        v-for="clone in instrument.clones"
                        :key="clone.id"
                        :label="clone.label"
                        :value="clone.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <div class="btn-list-actions">
                    <el-button @click="dialogChooseCloneVisible = false"> {{ $t('Cancel') }} </el-button>
                    <el-button type="primary" @click="saveClone()" plain> {{ $t('Save') }} </el-button>
                  </div>
                </el-form>
              </div>
            </b-tab-item>
            <b-tab-item :label="$t('By date')" icon="calendar-repeat">
              <div class="el-dialog-tab-content">
                <h4>{{ $t('Indicate the clone used for a given range') }}</h4>
                <el-form ref="formUpdateCloneRange" :model="cloneForm">
                  <el-form-item>
                    <el-select v-model="cloneForm.clone_used" placeholder="Select">
                      <el-option
                        v-for="clone in instrument.clones"
                        :key="clone.id"
                        :label="clone.label"
                        :value="clone.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-date-picker
                      v-model="cloneForm.date_range"
                      type="datetimerange"
                      :range-separator="$t('to')"
                      :start-placeholder="$t('Start date')"
                      :end-placeholder="$t('End date')"
                      :default-time="['08:00:00', '08:00:00']">
                    </el-date-picker>
                  </el-form-item>
                  <div class="btn-list-actions">
                    <el-button @click="dialogChooseCloneVisible = false"> {{ $t('Cancel') }} </el-button>
                    <el-button type="primary" @click="saveCloneByRange()" plain> {{ $t('Save') }} </el-button>
                  </div>
                </el-form>
              </div>
            </b-tab-item>
        </b-tabs>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Vuex from 'vuex'
import Status from '@/components/Status'
import SelectAction from '@/components/observingsystem/SelectAction'
import ProductionVersionSelectAction from '@/components/collection/SelectAction'
import Loading from '@/components/Loading'

export default {
  components: {
    'status': Status,
    'e-select-action': SelectAction,
    'e-production-version-select-action': ProductionVersionSelectAction,
    'e-loading': Loading
  },
  props: {
    title: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    route: {
      type: String,
      required: false
    },
    displayCurrentStatus: {
      type: Boolean,
      required: false,
      default: false
    },
    showDetail: {
      type: Boolean,
      required: false,
      default: false
    },
    isDashboard: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data () {
    return {
      obs: this.$route.params.obs,
      mainLoading: true,
      loading: false,
      isPaginated: true,
      isHoverable: true,
      isPaginationSimple: false,
      defaultSortDirection: 'asc',
      currentPage: 1,
      page: 1,
      total: 0,
      perPage: 10,
      defaultOpenedDetails: [1],
      showDetailIcon: false,
      dialogChooseCloneVisible: false,
      cloneForm: {
        data_collection: '',
        clone_used: '',
        date_range: ''
      }
    }
  },
  computed: {
    ...Vuex.mapGetters(['user', 'instrument', 'instruments', 'myInstruments', 'softwares', 'mySoftwares', 'platforms', 'myPlatforms', 'sites', 'mySites', 'collection', 'collections', 'events'])
  },
  methods: {
    ...Vuex.mapActions({
      getInstrumentsDetails: 'getInstrumentsDetails',
      getMyInstruments: 'getMyInstruments',
      getMyLastEvents: 'getMyLastEvents',
      getSoftwares: 'getSoftwares',
      getMySoftwares: 'getMySoftwares',
      getPlatforms: 'getPlatforms',
      getMyPlatforms: 'getMyPlatforms',
      getSites: 'getSites',
      getMySites: 'getMySites',
      getInstrument: 'getInstrument',
      getCollection: 'getCollection',
      getCollections: 'getCollections',
      editCollection: 'editCollection',
      updateCloneByDateRange: 'updateCloneByDateRange'
    }),
    loadCollections(id) {
      this.defaultOpenedDetails = [id]
      this.loading = true
      const form = {
        'datasource': id
      }
      this.getCollections(form).then(
        () => {
          this.loading = false
        }
      )
    },
    init: function () {
      this.showDetailIcon = (this.showDetail ? true : false)
      this.loadAsyncData()
    },
    loadAsyncData() {
      this.loading = true
      const form = {
        'obs': this.obs,
        'page': this.page
      }
      if(this.type=='instrument') {
        if(this.isDashboard) {
          this.getMyInstruments(form).then(
            () => {
              this.total = Object.keys(this.myInstruments).length
              this.data = this.myInstruments
              this.loading = false
              this.mainLoading = false
            }
          )
        } else {
          this.getInstrumentsDetails(form).then(
            () => {
              this.total = this.instruments.count
              this.data = this.instruments.results
              this.loading = false
              this.mainLoading = false
            }
          )
        }
      } else if(this.type=='software') {
        if(this.isDashboard) {
          this.getMySoftwares(form).then(
            () => {
              this.total = Object.keys(this.mySoftwares).length
              this.data = this.mySoftwares
              this.loading = false
              this.mainLoading = false
            }
          )
        } else {
          this.getSoftwares(form).then(
            () => {
              this.total = this.softwares.count
              this.data = this.softwares.results
              this.loading = false
              this.mainLoading = false
            }
          )
        }
      } else if(this.type=='platform') {
        if(this.isDashboard) {
          this.getMyPlatforms(form).then(
            () => {
              this.total = Object.keys(this.myPlatforms).length
              this.data = this.myPlatforms
              this.loading = false
              this.mainLoading = false
            }
          )
        } else {
          this.getPlatforms(form).then(
            () => {
              this.total = this.platforms.count
              this.data = this.platforms.results
              this.loading = false
              this.mainLoading = false
            }
          )
        }
      } else if(this.type=='event') {
        this.getMyLastEvents(form).then(
          () => {
            this.total = Object.keys(this.events).length
            this.data = this.events
            this.$emit("displaySitesList", true)
            this.loading = false
            this.mainLoading = false
          }
        )
      } else if(this.type=='site') {
        if(this.isDashboard) {
          this.getMySites(form).then(
            () => {
              this.total = Object.keys(this.mySites).length
              this.data = this.mySites
              this.loading = false
              this.mainLoading = false
            }
          )
        } else {
          this.getSites(form).then(
            () => {
              this.total = this.sites.count
              this.data = this.sites.results
              this.loading = false
              this.mainLoading = false
            }
          )
        }
      } 
    },
    onPageChange(page) {
      if(!this.isDashboard) {
        this.page = page
        this.loadAsyncData()
      }
    },
    loadChooseClone: function (id) {
      this.getCollection(id).then(
        () => {
          this.getInstrument(this.collection.data_source).then(
            () => {
              this.cloneForm.data_collection = id
              this.dialogChooseCloneVisible = true
            }
          )
        }
      )
    },
    saveClone() {
      this.editCollection(this.collection).then(
        () => {
          this.dialogChooseCloneVisible = false
        }
      )
    },
    saveCloneByRange() {
      this.updateCloneByDateRange(this.cloneForm).then(
        () => {
          this.dialogChooseCloneVisible = false
        }
      )
    }
  },

  mounted: function () {
    this.init()
  }
}
</script>