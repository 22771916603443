<template>
  <div class="medium-center">
    <el-form :model="processingLevel" label-position="top" ref="form" :rules="rules" :hide-required-asterisk="true">
      <el-form-item :label="$t('Name')" prop="name">
        <el-input v-model="processingLevel.name" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item :label="$t('Main processing level')" prop="main_processing_level">
        <el-input-number v-model="processingLevel.main_processing_level" :min="0"></el-input-number>
      </el-form-item>
      <el-form-item :label="$t('Description')">
        <el-input type="textarea" v-model="processingLevel.description"></el-input>
      </el-form-item>
      <el-form-item class="lst-btn-end-form">
        <el-button @click.stop="save('form')">{{ $t('Update') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Vuex from 'vuex'
import { store } from '@/store'
import i18n from '@/i18n'

export default {
  name: 'settingsProcessingLevelEdit',
  data () {
    return {
      rules: {
        name: [
          { required: true, message: i18n.t('msg_required_field'), trigger: 'blur' },
        ]
      }
    }
  },
  computed: {
    ...Vuex.mapGetters(['user', 'processingLevel'])
  },
  methods: {
    ...Vuex.mapActions({
      initProcessingLevel: 'initProcessingLevel',
      editProcessingLevel: 'editProcessingLevel',
    }),
    save (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.editProcessingLevel(this.processingLevel)
        } else {
          this.$buefy.toast.open({
            message: this.$root.$t('check_data_entered'),
            type: 'is-danger'
          })
          return false
        }
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('getProcessingLevel', to.params.id).then(next())
  },
}
</script>