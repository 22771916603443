import { ToastProgrammatic as Toast } from 'buefy'
import Instrument from '@/store/api/instrument'
import router from '@/router'
import i18n from '@/i18n'

// state
const state = {
  myInstruments: {},
  instruments: {},
  instrument: {},
  instrumentDetail: {}
}

// getters
const getters = {
  myInstruments: state => state.myInstruments,
  instruments: state => state.instruments,
  instrument: state => state.instrument,
  instrumentDetail: state => state.instrumentDetail,
}

// mutations
const mutations = {
  SET_MY_INSTRUMENTS: function (state, data) {
    state.myInstruments = data
  },
  SET_INSTRUMENTS: function (state, data) {
    state.instruments = data
  },
  SET_INSTRUMENT: function (state, data) {
    state.instrument = data
  },
  SET_INSTRUMENT_DETAIL: function (state, data) {
    state.instrumentDetail = data
  },
  INIT_INSTRUMENT: function (state) {
    state.instrument = {
      'name' : null,
      'label' : null,
      'current_status' : null,
      'pi' : null,
      'comment' : null
    }
  }
}

// actions: method for CRUD actions
const actions = {

  async createInstrument ({ commit }, form) {
    try {
      const instru = await Instrument.create(form)
      commit('SET_INSTRUMENT', instru)
      // Reload instruments list
      const c = await Instrument.getList()
      await commit('SET_INSTRUMENTS', c.data)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
      router.push({
        name: 'settings.instrument.list'
      })
    } catch (e) {
      commit('SET_INSTRUMENT', null)
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
      return false
    }
  },

  async editInstrument ({ commit }, form) {
    try {
      const instru = await Instrument.update(form['id'], form)
      commit('SET_INSTRUMENT', instru.data)
      // Reload instruments list
      const c = await Instrument.getList()
      await commit('SET_INSTRUMENTS', c.data)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
    }
  },

  async getInstruments ({ commit }, form) {
    try {
      const c = await Instrument.getList(form)
      await commit('SET_INSTRUMENTS', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getInstrumentsDetails ({ commit }, form) {
    try {
      const c = await Instrument.getListDetail(form)
      await commit('SET_INSTRUMENTS', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getMyInstruments ({ commit }, page) {
    try {
      const c = await Instrument.getListFromUserConnected(page)
      await commit('SET_MY_INSTRUMENTS', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async exportInstruments (form) {
    try {
      Instrument.export(form).then((res) => {
        var FILE = window.URL.createObjectURL(new Blob([res.data]))
        var docUrl = document.createElement('x')
        docUrl.href = FILE
        docUrl.setAttribute('download', 'file.pdf')
        document.body.appendChild(docUrl)
        docUrl.click()
      })
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getInstrument ({ commit }, id) {
    try {
      if(id) {
        const c = await Instrument.get(id)
        await commit('SET_INSTRUMENT', c.data)
      } else {
        Toast.open({
          message: i18n.t('Instrument id is required'),
          type: 'is-danger'
        })
      }
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getInstrumentDetail ({ commit }, id) {
    try {
      if(id) {
        const c = await Instrument.getDetail(id)
        await commit('SET_INSTRUMENT_DETAIL', c.data)
      } else {
        Toast.open({
          message: i18n.t('Instrument id is required'),
          type: 'is-danger'
        })
      }
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async initInstrument ({ commit }) {
    try {
      await commit('INIT_INSTRUMENT')
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

}

export default {
  state,
  getters,
  mutations,
  actions
}
