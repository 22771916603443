<template>
  <div class="medium-center">
    <organization-form formType="edit" />
  </div>
</template>

<script>
import Vuex from 'vuex'
import { store } from '@/store'
import OrganizationForm from './Form.vue'

export default {
  name: 'settingsOrganizationCreate',
  components: {
    'organization-form': OrganizationForm,
  },
  methods: {
    ...Vuex.mapActions({
      editOrganization: 'editOrganization',
    })
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('getOrganization', to.params.id).then(next())
  }
}
</script>