<template>
  <div class="medium-center">
    <e-event-form formType="create"/>
  </div>
</template>

<script>
import Vuex from 'vuex'
import EventForm from '@/views/event/Form.vue'

export default {
  name: 'settingsEventCreate',
  components: {
    'e-event-form': EventForm,
  },
  methods: {
    ...Vuex.mapActions({
      initEvent: 'initEvent'
    })
  },
  mounted: function () {
    //this.initEvent()
  }
}
</script>