import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Settings from './views/Settings.vue'
import Dashboard from './views/Dashboard.vue'
import Export from './views/Export.vue'
import Profile from './views/Profile.vue'
import ObservingsystemCreate from './views/observingsystem/Create.vue'
import ObservingsystemEdit from './views/observingsystem/Edit.vue'
import ObservingsystemView from './views/observingsystem/View.vue'
import ObservingsystemList from './views/observingsystem/List.vue'
import EventCreate from './views/event/Create.vue'
import EventEdit from './views/event/Edit.vue'
import EventView from './views/event/View.vue'
import VocabularyTemplateCreate from './views/vocabulary/template/Create.vue'
import VocabularyTemplateEdit from './views/vocabulary/template/Edit.vue'
import VocabularyTemplateList from './views/vocabulary/template/List.vue'
import CreateOrganization from './views/organization/Create.vue'
import EditOrganization from './views/organization/Edit.vue'
import ListOrganization from './views/organization/List.vue'
import CreateObservatory from './views/observatory/Create.vue'
import EditObservatory from './views/observatory/Edit.vue'
import ListObservatory from './views/observatory/List.vue'
import VisuData from './views/observatory/VisuData.vue'
import CreateSite from './views/site/Create.vue'
import EditSite from './views/site/Edit.vue'
import ListSite from './views/site/List.vue'
import CreateInstrument from './views/instrument/Create.vue'
import EditInstrument from './views/instrument/Edit.vue'
import ViewInstrument from './views/instrument/View.vue'
import ListInstrument from './views/instrument/List.vue'
import CreateVocabulary from './views/vocabulary/Create.vue'
import ListVocabulary from './views/vocabulary/List.vue'
import EditVocabulary from './views/vocabulary/Edit.vue'
import DataprodMainSettings from './views/dataprod/MainSettings.vue'
import DataprodCollectionList from './views/dataprod/collection/List.vue'
import DataprodCollectionCreate from './views/dataprod/collection/Create.vue'
import DataprodCollectionEdit from './views/dataprod/collection/Edit.vue'
import DataprodCollectionView from './views/dataprod/collection/View.vue'
import DataprodProcessingLevelList from './views/dataprod/processingLevel/List.vue'
import DataprodProcessingLevelCreate from './views/dataprod/processingLevel/Create.vue'
import DataprodProcessingLevelEdit from './views/dataprod/processingLevel/Edit.vue'
import DataprodProductList from './views/dataprod/product/List.vue'
import DataprodProductCreate from './views/dataprod/product/Create.vue'
import DataprodProductEdit from './views/dataprod/product/Edit.vue'
import DataprodProductionVersionList from './views/dataprod/productionVersion/List.vue'
import DataprodProductionVersionCreate from './views/dataprod/productionVersion/Create.vue'
import DataprodProductionVersionEdit from './views/dataprod/productionVersion/Edit.vue'
import DataprodCreateOperationSheet from './views/dataprod/operationSheet/Create.vue'
import DataprodEditOperationSheet from './views/dataprod/operationSheet/Edit.vue'
import DataprodListOperationSheet from './views/dataprod/operationSheet/List.vue'
import DataprodQualityControlSheetCreate from './views/dataprod/qualityControlSheet/Create.vue'
import DataprodQualityControlSheetEdit from './views/dataprod/qualityControlSheet/Edit.vue'
import DataprodQualityControlSheetList from './views/dataprod/qualityControlSheet/List.vue'
import Scan from './views/settings/Scan.vue'
import SignIn from './views/SignIn.vue'
import { ability as appAbility } from '@/store/ability'

export const ability = appAbility

Vue.use(Router)

async function requireAuth (to, from, next) {
  const authenticated = localStorage.getItem('authenticated')
  if (authenticated === 'true') {
    next()
  } else {
    await next({ name: 'signIn' })
  }
}

async function adminAccess (to, from, next) {
  if (ability.can('access', 'adm')) {
    next()
  } else {
    next({ name: 'home' })
  }
}

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
			return { selector: to.hash }
		} else if (savedPosition) {
      return savedPosition
    } else {
			return { x: 0, y: 0 }
		}
  },
  routes: [
    {
      path: '*', // or '/index.html'
      beforeEnter: (to, from, next) => {
        next('/')
      }
    },
    { 
      path: '/api', // Ignore or pass on to server 
      name: 'api',
    },
    { 
      path: '/docs', // Ignore or pass on to server 
      name: 'docs',
    },
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/observatory/:obs',
      name: 'observatory.visuData',
      component: VisuData
    },
    {
      path: '/observingsystem/:observingsystemType/view/:id(\\d+)',
      name: 'observingsystem.view',
      meta: {
        title: 'View observing system'
      },
      component: ObservingsystemView
    },
    {
      path: '/instrument/:id(\\d+)',
      name: 'instrument.view',
      meta: {
        title: 'View instrument'
      },
      component: ViewInstrument
    },
    {
      path: '/event/view/:id(\\d+)',
      name: 'event.view',
      meta: {
        title: 'View event'
      },
      component: EventView
    },
    {
      path: '/dataprod/collection/view/:id(\\d+)',
      name: 'dataprod.collection.view',
      props: true,
      meta: {
        title: 'View collection'
      },
      component: DataprodCollectionView
    },
    {
      path: '/profile',
      name: 'profile',
      props: true,
      meta: {
        title: 'Profile'
      },
      component: Profile,
      beforeEnter: requireAuth,
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      props: true,
      meta: {
        title: 'Dashboard'
      },
      component: Dashboard,
      beforeEnter: requireAuth,
    },
    {
      path: '/export',
      name: 'export',
      props: true,
      meta: {
        title: 'Export'
      },
      component: Export,
      beforeEnter: requireAuth,
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      meta: {
        title: 'Tableau de bord'
      },
      beforeEnter: requireAuth,
      children: [{
        path: 'observingsystem/:observingsystemType/list',
        name: 'settings.observingsystem.list',
        meta: {
          title: 'List observing system'
        },
        component: ObservingsystemList,
        beforeEnter: adminAccess,
      },{
        path: 'observingsystem/:observingsystemType/create',
        name: 'settings.observingsystem.create',
        meta: {
          title: 'Create observing system'
        },
        component: ObservingsystemCreate,
        beforeEnter: adminAccess,
      },{
        path: 'observingsystem/:observingsystemType/edit/:id(\\d+)',
        name: 'settings.observingsystem.edit',
        meta: {
          title: 'Edit observing system'
        },
        component: ObservingsystemEdit,
        beforeEnter: adminAccess,
      },{
        path: 'event/create/:observingsystem(\\d+)?/:eventTemplate(\\d+)?/:trigger(\\d+)?',
        name: 'settings.event.create',
        meta: {
          title: 'Create event'
        },
        component: EventCreate,
      },{
        path: 'event/edit/:id(\\d+)',
        name: 'settings.event.edit',
        meta: {
          title: 'Edit event'
        },
        component: EventEdit
      },{
        path: 'vocabulary/template/create/:type/:subtype?',
        name: 'settings.vocabulary.template.create',
        meta: {
          title: 'Create template'
        },
        component: VocabularyTemplateCreate,
        props: true,
        beforeEnter: adminAccess,
      },{
        path: 'vocabulary/template/edit/:id(\\d+)',
        name: 'settings.vocabulary.template.edit',
        meta: {
          title: 'Edit template'
        },
        component: VocabularyTemplateEdit,
        beforeEnter: adminAccess,
      },{
        path: 'vocabulary/template/list/:type/:subtype?',
        name: 'settings.vocabulary.template.list',
        meta: {
          title: 'List template'
        },
        component: VocabularyTemplateList,
        props: true,
        beforeEnter: adminAccess,
      },{
        path: 'operationSheet/list',
        name: 'settings.operationSheet.list',
        meta: {
          title: 'List operation sheets'
        },
        component: DataprodListOperationSheet,
        beforeEnter: adminAccess,
      },{
        path: 'operationSheet/create',
        name: 'settings.operationSheet.create',
        meta: {
          title: 'Create operation sheet'
        },
        component: DataprodCreateOperationSheet,
      },{
        path: 'operationSheet/edit/:id(\\d+)',
        name: 'settings.operationSheet.edit',
        meta: {
          title: 'Edit operation sheet'
        },
        component: DataprodEditOperationSheet,
      },{
        path: 'qualityControlSheet/list',
        name: 'settings.qualityControlSheet.list',
        meta: {
          title: 'List quality control sheets'
        },
        component: DataprodQualityControlSheetList,
        beforeEnter: adminAccess,
      },{
        path: 'qualityControlSheet/create',
        name: 'settings.qualityControlSheet.create',
        meta: {
          title: 'Create quality control sheet'
        },
        component: DataprodQualityControlSheetCreate,
      },{
        path: 'qualityControlSheet/edit/:id(\\d+)',
        name: 'settings.qualityControlSheet.edit',
        meta: {
          title: 'Edit quality control sheet'
        },
        component: DataprodQualityControlSheetEdit,
      },{
        path: 'observatory/list',
        name: 'settings.observatory.list',
        meta: {
          title: 'List observatories'
        },
        component: ListObservatory,
        beforeEnter: adminAccess,
      },{
        path: 'observatory/create',
        name: 'settings.observatory.create',
        meta: {
          title: 'Create observatory'
        },
        component: CreateObservatory,
        beforeEnter: adminAccess,
      },{
        path: 'observatory/edit/:id(\\d+)',
        name: 'settings.observatory.edit',
        meta: {
          title: 'Edit observatory'
        },
        component: EditObservatory,
        beforeEnter: adminAccess,
      },{
        path: 'observatory/view/:id(\\d+)',
        name: 'observatory.view',
        meta: {
          title: 'Observatory detail'
        },
        component: EditObservatory,
        beforeEnter: adminAccess,
      },{
        path: 'organization/list',
        name: 'settings.organization.list',
        meta: {
          title: 'List organizations'
        },
        component: ListOrganization,
        beforeEnter: adminAccess,
      },{
        path: 'organization/create',
        name: 'settings.organization.create',
        meta: {
          title: 'Create organization'
        },
        component: CreateOrganization,
        beforeEnter: adminAccess,
      },{
        path: 'organization/edit/:id(\\d+)',
        name: 'settings.organization.edit',
        meta: {
          title: 'Edit organization'
        },
        component: EditOrganization,
        beforeEnter: adminAccess,
      },{
        path: 'organization/view/:id(\\d+)',
        name: 'organization.view',
        meta: {
          title: 'Organization detail'
        },
        component: EditOrganization,
        beforeEnter: adminAccess,
      },{
        path: 'site/list',
        name: 'settings.site.list',
        meta: {
          title: 'List sites'
        },
        component: ListSite,
        beforeEnter: adminAccess,
      },{
        path: 'site/create',
        name: 'settings.site.create',
        meta: {
          title: 'Create site'
        },
        component: CreateSite,
        beforeEnter: adminAccess,
      },{
        path: 'site/edit/:id(\\d+)',
        name: 'settings.site.edit',
        meta: {
          title: 'Edit site'
        },
        component: EditSite,
        beforeEnter: adminAccess,
      },{
        path: 'instrument/list',
        name: 'settings.instrument.list',
        meta: {
          title: 'List instruments'
        },
        component: ListInstrument,
        beforeEnter: adminAccess,
      },{
        path: 'instrument/create',
        name: 'settings.instrument.create',
        meta: {
          title: 'Create instrument'
        },
        component: CreateInstrument,
        beforeEnter: adminAccess,
      },{
        path: 'instrument/edit/:id(\\d+)',
        name: 'settings.instrument.edit',
        meta: {
          title: 'Edit instrument'
        },
        component: EditInstrument,
        beforeEnter: adminAccess,
      },{
        path: 'scan',
        name: 'scan',
        component: Scan,
        beforeEnter: adminAccess,
      },{
        path: 'vocabulary/list',
        name: 'settings.vocabulary.list',
        meta: {
          title: 'List vocabulary'
        },
        component: ListVocabulary,
        beforeEnter: adminAccess,
      },{
        path: 'vocabulary/create',
        name: 'settings.vocabulary.create',
        component: CreateVocabulary,
        beforeEnter: adminAccess,
      },{
        path: 'vocabulary/edit/:id(\\d+)',
        name: 'settings.vocabulary.edit',
        meta: {
          title: 'Edit vocabulary'
        },
        component: EditVocabulary,
        beforeEnter: adminAccess,
      },{
        path: 'dataprod',
        name: 'settings.dataprod.main',
        meta: {
          title: 'Manage dataprod'
        },
        component: DataprodMainSettings,
        beforeEnter: adminAccess,
      },{
        path: 'dataprod/collection/list',
        name: 'settings.dataprod.collection.list',
        meta: {
          title: 'List collection'
        },
        component: DataprodCollectionList,
        beforeEnter: adminAccess,
      },{
        path: 'dataprod/collection/create',
        name: 'settings.dataprod.collection.create',
        meta: {
          title: 'Create collection'
        },
        component: DataprodCollectionCreate,
        beforeEnter: adminAccess,
      },{
        path: 'dataprod/collection/edit/:id(\\d+)',
        name: 'settings.dataprod.collection.edit',
        meta: {
          title: 'Edit collection'
        },
        component: DataprodCollectionEdit,
        beforeEnter: adminAccess,
      },{
        path: 'dataprod/processingLevel/list',
        name: 'settings.dataprod.processingLevel.list',
        meta: {
          title: 'List Processing Levels'
        },
        component: DataprodProcessingLevelList,
        beforeEnter: adminAccess,
      },{
        path: 'dataprod/processingLevel/create',
        name: 'settings.dataprod.processingLevel.create',
        meta: {
          title: 'Create Processing Level'
        },
        component: DataprodProcessingLevelCreate,
        beforeEnter: adminAccess,
      },{
        path: 'dataprod/processingLevel/edit/:id(\\d+)',
        name: 'settings.dataprod.processingLevel.edit',
        meta: {
          title: 'Edit Processing Level'
        },
        component: DataprodProcessingLevelEdit,
        beforeEnter: adminAccess,
      },{
        path: 'dataprod/product/list',
        name: 'settings.dataprod.product.list',
        meta: {
          title: 'List products'
        },
        component: DataprodProductList,
        beforeEnter: adminAccess,
      },{
        path: 'dataprod/product/create',
        name: 'settings.dataprod.product.create',
        meta: {
          title: 'Create product'
        },
        component: DataprodProductCreate,
        beforeEnter: adminAccess,
      },{
        path: 'dataprod/product/edit/:id(\\d+)',
        name: 'settings.dataprod.product.edit',
        meta: {
          title: 'Edit product'
        },
        component: DataprodProductEdit,
        beforeEnter: adminAccess,
      },{
        path: 'dataprod/productionVersion/list',
        name: 'settings.dataprod.productionVersion.list',
        meta: {
          title: 'List production versions'
        },
        component: DataprodProductionVersionList,
        beforeEnter: adminAccess,
      },{
        path: 'dataprod/productionVersion/create',
        name: 'settings.dataprod.productionVersion.create',
        meta: {
          title: 'Create production version'
        },
        component: DataprodProductionVersionCreate,
        beforeEnter: adminAccess,
      },{
        path: 'dataprod/productionVersion/edit/:id(\\d+)',
        name: 'settings.dataprod.productionVersion.edit',
        meta: {
          title: 'Edit production version'
        },
        component: DataprodProductionVersionEdit,
        beforeEnter: adminAccess,
      }]
    },
    {
      path: '/signin',
      name: 'signIn',
      component: SignIn
    },
    /**
    {
      path: '/register',
      name: 'register',
      component: SignIn
    },
    **/
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    }
  ]
})
