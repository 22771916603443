/* eslint-disable */
import { Toast } from 'buefy'
import Countries from '@/store/api/countries'
import i18n from '@/i18n'

// state
const state = {
  countries: null,
}

// getters
const getters = {
  countries: state => state.countries
}

// mutations
const mutations = {
  SET_COUNTRIES: function (state, data) {
    state.countries = data
  },
  INIT_ELEMENTS: function (state) {
    state.countries = []
  }
}

// actions
const actions = {
  async getCountries ({ commit }) {
    try {
      const c = await Countries.get()
      await commit('SET_COUNTRIES', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_loading_countries'),
        type: 'is-danger'
      })
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
