<template>
  <div class="sites full-width">
    <h1 class="title settings-title">
      {{ $t('Export') }} 
    </h1>
    <div class="columns">
      <div class="column is-4">
        <div class="tile is-parent">
          <div class="tile is-child box">
            <p class="title">
              {{ $t('Instruments') }} 
            </p>
            <el-select name="sltObs" v-model="sltObservatory" :placeholder="$t('Observatory')" value-key="id" ref="sltObs">
              <el-option
                v-for="o in observatories"
                :key="o.id"
                :label="o.label"
                :value="o.id">
              </el-option>
            </el-select>
            <el-button @click="exportInstrument()" class="mg-l">{{ $t('Export') }}</el-button>
          </div>
        </div>
      </div>
      <div class="column is-4">
        <div class="tile is-parent">
          <div class="tile is-child box">
            <p class="title">
              {{ $t('Geoflow') }} 
            </p>
            <el-button @click="exportGeoflow()" class="mg-l">{{ $t('Export geoflow') }}</el-button>
            <br/>
            <br/>
            <el-button @click="exportContactsGeoflow()" class="mg-l">{{ $t('Export contacts geoflow') }}</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'
import conf from '@/conf'

export default {
  name: 'Exports',
  data () {
    return {
      id: '',
      name: '',
      title: '',
      sltObservatory: '',
      headers: '',
      token: ''
    }
  },
  computed: {
    ...Vuex.mapGetters(['user', 'observatories']),
  },
  methods: {
    ...Vuex.mapActions({
      check: 'check',
      getObservatoriesAndAll: 'getObservatoriesAndAll',
      exportInstruments: 'exportInstruments',
    }),
    exportInstrument () {
      this.url = conf.API_URL + 'resources/instruments/export?observatory=' + this.sltObservatory
      this.token = localStorage.getItem('id_token')
      this.headers = {
        'Authorization': 'Bearer ' + this.token,
      }
      const requestOptions = {
        method: 'GET',
        headers: this.headers,
        responseType: 'blob',
      }
      return fetch(this.url, requestOptions).then(response => response.blob())
        .then(blob => {
          window.URL.createObjectURL(blob)
          var a = document.createElement('a')
          a.href = this.url
          document.body.appendChild(a)
          a.click()   
          a.remove()
        })
    },
    exportGeoflow () {
      this.url = conf.API_URL + 'observingsystem/discoveries/export_geoflow_csv'
      this.token = localStorage.getItem('id_token')
      this.headers = {
        'Authorization': 'Bearer ' + this.token,
      }
      const requestOptions = {
        method: 'GET',
        headers: this.headers,
        responseType: 'blob',
      }
      return fetch(this.url, requestOptions).then(response => response.blob())
        .then(blob => {
          window.URL.createObjectURL(blob)
          var a = document.createElement('a')
          a.href = this.url
          document.body.appendChild(a)
          a.click()   
          a.remove()
        })
    },
    exportContactsGeoflow () {
      this.url = conf.API_URL + 'observingsystem/discoveries/export_contacts_geoflow_csv'
      this.token = localStorage.getItem('id_token')
      this.headers = {
        'Authorization': 'Bearer ' + this.token,
      }
      const requestOptions = {
        method: 'GET',
        headers: this.headers,
        responseType: 'blob',
      }
      return fetch(this.url, requestOptions).then(response => response.blob())
        .then(blob => {
          window.URL.createObjectURL(blob)
          var a = document.createElement('a')
          a.href = this.url
          document.body.appendChild(a)
          a.click()   
          a.remove()
        })
    }
  },
  mounted: function () {
    this.getObservatoriesAndAll()
    this.$nextTick(function () {
      this.check(this)
    })
  }
}
</script>