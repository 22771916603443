import { ToastProgrammatic as Toast } from 'buefy'
import Software from '@/store/api/software'
import router from '@/router'
import i18n from '@/i18n'

// state
const state = {
  mySoftwares: {},
  softwares: {},
  software: {}
}

// getters
const getters = {
  mySoftwares: state => state.mySoftwares,
  softwares: state => state.softwares,
  software: state => state.software,
}

// mutations
const mutations = {
  SET_MY_SOFTWARES: function (state, data) {
    state.mySoftwares = data
  },
  SET_SOFTWARES: function (state, data) {
    state.softwares = data
  },
  SET_SOFTWARE: function (state, data) {
    state.software = data
  },
  INIT_SOFTWARE: function (state) {
    state.software = {
      'name' : null,
      'label' : null,
      'current_status' : null,
      'pi' : null,
      'comment' : null
    }
  }
}

// actions: method for CRUD actions
const actions = {

  async createSoftware ({ commit }, form) {
    try {
      const instru = await Software.create(form)
      commit('SET_SOFTWARE', instru)
      // Reload softwares list
      const c = await Software.getList()
      await commit('SET_SOFTWARES', c.data)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
      router.push({
        name: 'settings.software.list'
      })
    } catch (e) {
      commit('SET_SOFTWARE', null)
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
      return false
    }
  },

  async editSoftware ({ commit }, form) {
    try {
      const instru = await Software.update(form['id'], form)
      commit('SET_SOFTWARE', instru.data)
      // Reload softwares list
      const c = await Software.getList()
      await commit('SET_SOFTWARES', c.data)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
    }
  },

  async getSoftware ({ commit }, id) {
    try {
      const c = await Software.get(id)
      await commit('SET_SOFTWARE', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getSoftwares ({ commit }, form) {
    try {
      const c = await Software.getList(form)
      await commit('SET_SOFTWARES', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getMySoftwares ({ commit }, form) {
    try {
      const c = await Software.getListFromUserConnected(form)
      await commit('SET_MY_SOFTWARES', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async initSoftware ({ commit }) {
    try {
      await commit('INIT_SOFTWARE')
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

}

export default {
  state,
  getters,
  mutations,
  actions
}
