import { ToastProgrammatic as Toast } from 'buefy'
import Platform from '@/store/api/platform'
import router from '@/router'
import i18n from '@/i18n'

// state
const state = {
  myPlatforms: {},
  platforms: {},
  platform: {}
}

// getters
const getters = {
  myPlatforms: state => state.myPlatforms,
  platforms: state => state.platforms,
  platform: state => state.platform,
}

// mutations
const mutations = {
  SET_MY_COLLECTORS: function (state, data) {
    state.myPlatforms = data
  },
  SET_COLLECTORS: function (state, data) {
    state.platforms = data
  },
  SET_COLLECTOR: function (state, data) {
    state.platform = data
  },
  INIT_COLLECTOR: function (state) {
    state.platform = {
      'name' : null,
      'label' : null,
      'current_status' : null,
      'pi' : null,
      'comment' : null
    }
  }
}

// actions: method for CRUD actions
const actions = {

  async createPlatform ({ commit }, form) {
    try {
      const platform = await Platform.create(form)
      commit('SET_COLLECTOR', platform)
      // Reload platforms list
      const c = await Platform.getList()
      await commit('SET_COLLECTORS', c.data)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
      router.push({
        name: 'settings.observingsystem.list',
        params: { observingsystemType: 'platform' }
      })
    } catch (e) {
      commit('SET_COLLECTOR', null)
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
      return false
    }
  },

  async editPlatform ({ commit }, form) {
    try {
      const platform = await Platform.update(form['id'], form)
      commit('SET_COLLECTOR', platform.data)
      // Reload platforms list
      const c = await Platform.getList()
      await commit('SET_COLLECTORS', c.data)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
    }
  },

  async getPlatform ({ commit }, id) {
    try {
      const c = await Platform.get(id)
      await commit('SET_COLLECTOR', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getPlatforms ({ commit }, form) {
    try {
      const c = await Platform.getList(form)
      await commit('SET_COLLECTORS', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getMyPlatforms ({ commit }, form) {
    try {
      const c = await Platform.getListFromUserConnected(form)
      await commit('SET_MY_COLLECTORS', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async initPlatform ({ commit }) {
    try {
      await commit('INIT_COLLECTOR')
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

}

export default {
  state,
  getters,
  mutations,
  actions
}
