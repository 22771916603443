import { ToastProgrammatic as Toast } from 'buefy'
import Visudata from '@/store/api/visudata'
import Observatory from '@/store/api/observatory'
import i18n from '@/i18n'

// state
const state = {
  visudata: {},
  visudataYears: {},
  visudataTimeline: {},
  visudataQuicklooks: {}
}

// getters
const getters = {
  visudata: state => state.visudata,
  visudataYears: state => state.visudataYears,
  visudataTimeline: state => state.visudataTimeline,
  visudataQuicklooks: state => state.visudataQuicklooks,
}

// mutations
const mutations = {
  SET_VISUDATA: function (state, data) {
    state.visudata = data
  },
  SET_VISUDATA_YEARS: function (state, data) {
    state.visudataYears = data
  },
  SET_VISUDATA_TIMELINE: function (state, data) {
    state.visudataTimeline = data
  },
  SET_VISUDATA_QUICKLOOKS: function (state, data) {
    state.visudataQuicklooks = data
  },
  INIT_VISUDATA: function (state) {
    state.visudata = {
      'month' : null
    }
  },
  INIT_VISUDATA_TIMELINE: function () {
    state.visudataTimeline = {}
  },
  INIT_VISUDATA_QUICKLOOKS: function (state) {
    state.visudataQuicklooks = {}
  }
}

// actions: method for CRUD actions
const actions = {

  async getVisudata ({ commit }, visudata) {
    try {
      var obs = null
      if (visudata.obs) {
        const o = await Observatory.getByName(visudata.obs)
        obs = o.data.results[0].id
      }
      const c = await Visudata.get(visudata.year, obs, visudata.processing_level)
      await commit('SET_VISUDATA', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getVisudataYears ({ commit }, data) {
    try {
      var obs = null
      if (data.obs_n) {
        const o = await Observatory.getByName(data.obs_n)
        obs = o.data.results[0].id
      }
      const years = await Visudata.getListYears(obs)
      await commit('SET_VISUDATA_YEARS', years.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getVisudataTimeline ({ commit }, visudata) {
    try {
      const c = await Visudata.getDataCollectionTimeline(visudata.datacollection_n, visudata.year, visudata.month, visudata.processing_level)
      await commit('SET_VISUDATA_TIMELINE', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getVisudataQuicklooks ({ commit }, visudata) {
    try {
      const c = await Visudata.getQuicklooks(visudata.obs, visudata.calendar)
      await commit('SET_VISUDATA_QUICKLOOKS', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async initVisudata ({ commit }, data) {
    try {
      var obs = null
      if(data.obs_n) {
        const o = await Observatory.getByName(data.obs_n)
        obs = o.data.results[0].id
      }
      const years = await Visudata.getListYears(obs)
      await commit('SET_VISUDATA_YEARS', years.data)
      const c = await Visudata.get(years.data[0], obs)
      await commit('SET_VISUDATA', c.data)
      await commit('INIT_VISUDATA_TIMELINE')
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async initVisudataTimeline ({ commit }) {
    try {
      await commit('INIT_VISUDATA_TIMELINE')
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async initVisudataQuicklooks ({ commit }, data) {
    try {
      var obs = null
      if(data.obs_n) {
        const o = await Observatory.getByName(data.obs_n)
        obs = o.data.results[0].id
      }
      const c = await Visudata.getQuicklooks(obs)
      await commit('SET_VISUDATA_QUICKLOOKS', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

}

export default {
  state,
  getters,
  mutations,
  actions
}
