<template>
  <div>
    <el-form :model="productionVersion" label-position="top" ref="form" :rules="rules" :hide-required-asterisk="true">
      <el-form-item :label="$t('Product')" prop="product">
        <el-select name="sltProduct" v-model="productionVersion.product" placeholder="Select" value-key="id" ref="sltProduct">
          <el-option
            v-for="item in products"
            :key="item.id"
            :label="item.fullname"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="is_hidden">
        <el-checkbox v-model="productionVersion.is_hidden">{{ $t('Hide datas') }}</el-checkbox>
      </el-form-item>
      <el-form-item :label="$t('Version')" prop="version">
        <el-input-number v-model="productionVersion.version" :min="0" :max="10"></el-input-number>
      </el-form-item>
      <el-form-item :label="$t('Source path')" prop="label">
        <el-input v-model="productionVersion.data_path" auto-complete="off"></el-input>
      </el-form-item>
      <el-collapse v-model="productionVersionQC">
        <el-collapse-item :title="$t('Quality control Sheet')" name="1">
          <el-form-item prop="is_qualitycontrolsheet">
            <el-checkbox v-model="productionVersion.is_qualitycontrolsheet">{{ $t('Quality control activated') }}</el-checkbox>
          </el-form-item>
          <el-form-item prop="is_mandatory_qualitycontrolsheet">
            <el-checkbox v-model="productionVersion.is_mandatory_qualitycontrolsheet">{{ $t('Quality control mandatory') }}</el-checkbox>
          </el-form-item>
          <el-form-item :label="$t('Quality control sheet delay')" prop="quality_control_sheet_delay">
            <el-input-number v-model="productionVersion.qualitycontrolsheet_delay"></el-input-number>
          </el-form-item>
          <el-form-item :label="$t('Quality control sheet template')" prop="productionVersion_qualitycontrolsheet_template" >
            <el-select name="sltQCTemplate" v-model="productionVersion.qualitycontrolsheet_template" placeholder="Select" value-key="id" ref="sltQCTemplate">
              <el-option
                v-for="item in qualityControlSheetTemplates.results"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('Quality control sheet mode')" prop="productionVersion_qualitycontrolsheet_mode" >
            <el-select name="sltProductionVersionQCMode" v-model="productionVersion.qualitycontrolsheet_mode" placeholder="Select" value-key="id" ref="sltProductionVersionQCMode">
              <el-option
                v-for="value in listOfProductionVersionQCMode"
                :key="value[0]"
                :label="value[1]"
                :value="value[0]">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('Software')" prop="qualitycontrolsheet_software">
            <el-select name="sltQCSoftware" v-model="productionVersion.qualitycontrolsheet_software" placeholder="Select" value-key="id" ref="sltQCSoftware">
              <el-option
                v-for="item in softwares.results"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-collapse-item>
      </el-collapse>
      <br>
      <el-collapse v-model="productionVersionOF">
        <el-collapse-item :title="$t('Operation sheet')" name="2">
          <el-form-item prop="is_operationsheet">
            <el-checkbox v-model="productionVersion.is_operationsheet">{{ $t('Operation sheet activated') }}</el-checkbox>
          </el-form-item>
          <el-form-item prop="is_mandatory_operationsheet">
            <el-checkbox v-model="productionVersion.is_mandatory_operationsheet">{{ $t('Operation sheet mandatory') }}</el-checkbox>
          </el-form-item>
          <el-form-item :label="$t('Operation sheet template')" prop="productionVersion_operationsheet_template" >
            <el-select name="sltOperationSheetTemplate" v-model="productionVersion.operationsheet_template" placeholder="Select" value-key="id" ref="sltOperationSheetTemplate">
              <el-option
                v-for="item in operationSheetTemplates.results"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-collapse-item>
      </el-collapse>
      <br>
      <el-form-item :label="$t('Access level initial')" prop="productionVersion_access_level_initial" >
        <el-select name="sltProductionVersionPolicyInitial" v-model="productionVersion.access_level_initial" placeholder="Select" value-key="id" ref="sltProductionVersionPolicyInitial">
          <el-option
            v-for="value in listOfProductionVersionPolicy"
            :key="value[0]"
            :label="value[1]"
            :value="value[0]">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('Access level final')" prop="productionVersion_access_level_final" >
        <el-select name="sltProductionVersionPolicyFinal" v-model="productionVersion.access_level_final" placeholder="Select" value-key="id" ref="sltProductionVersionPolicyFinal">
          <el-option
            v-for="value in listOfProductionVersionPolicy"
            :key="value[0]"
            :label="value[1]"
            :value="value[0]">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('Software')" prop="software">
        <el-select name="sltSoftware" v-model="productionVersion.software" placeholder="Select" value-key="id" ref="sltSoftware">
          <el-option
            v-for="item in softwares.results"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('Access restriction days')" prop="version">
        <el-input-number v-model="productionVersion.access_restriction_days"></el-input-number>
      </el-form-item>
      <el-form-item :label="$t('Comment')">
        <el-input type="textarea" v-model="productionVersion.comment"></el-input>
      </el-form-item>
      <el-form-item :label="$t('History')">
        <el-input type="textarea" v-model="productionVersion.history"></el-input>
      </el-form-item>
      <el-form-item class="lst-btn-end-form">
        <el-button @click.stop="save('form')">{{ $t('Save') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Vuex from 'vuex'
import i18n from '@/i18n'

export default {
  name: 'productionVersionForm',
  data () {
    return {
      qualityControlSheetTemplates: '',
      operationSheetTemplates: '',
      rules: {
        product_version: [
          { required: true, message: i18n.t('msg_required_field'), trigger: 'change' },
        ]
      }
    }
  },
  computed: {
    ...Vuex.mapGetters(['user', 'productionVersion', 'products', 'softwares', 'templates', 'listOfProductionVersionPolicy', 'listOfProductionVersionQCMode', 'listOfProductionVersionPolicy'])
  },
  methods: {
    ...Vuex.mapActions({
      createProductionVersion: 'createProductionVersion',
      editProductionVersion: 'editProductionVersion',
      getProducts: 'getProducts',
      getSoftwares: 'getSoftwares',
      getTemplates: 'getTemplates',
      getListOfProductionVersionPolicy: 'getListOfProductionVersionPolicy',
      getListOfProductionVersionQCMode: 'getListOfProductionVersionQCMode',
    }),
    save (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$route.name == 'settings.dataprod.productionVersion.create' ? this.createProductionVersion(this.productionVersion) : this.editProductionVersion(this.productionVersion)
        } else {
          this.$buefy.toast.open({
            message: this.$root.$t('check_data_entered'),
            type: 'is-danger'
          })
          return false
        }
      })
    }
  },
  mounted: function () {
    this.getListOfProductionVersionPolicy(),
    this.getListOfProductionVersionQCMode(),
    this.getProducts([]),
    this.getSoftwares([]),
    this.getTemplates({'type' : 'operationsheet'}).then(
      () => {
        this.operationSheetTemplates = this.templates
      }
    ),
    this.getTemplates({'type' : 'qualitycontrolsheet'}).then(
      () => {
        this.qualityControlSheetTemplates = this.templates
      }
    )
  }
}
</script>