<template>
  <div class="operationSheet full-width">
    <h1 class="title settings-title">
      {{ $t('Operation sheets') }} 
    </h1>
    <div class="columns medium-center">
      <div class="column">
        <section>
          <b-table
              :data="operationSheets.results"
              :paginated="isPaginated"
              :per-page="perPage"
              :current-page.sync="currentPage"
              :pagination-simple="isPaginationSimple"
              :default-sort-direction="defaultSortDirection"
              default-sort="id"
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page">
              <b-table-column field="id" label="ID" width="40" sortable numeric v-slot="props">
                {{ props.row.id }}
              </b-table-column>

              <b-table-column field="name" label="File name" sortable v-slot="props">
                {{ props.row.name }}
              </b-table-column>

              <b-table-column field="date" label="Date modified" sortable centered v-slot="props">
                <span class="tag is-success">
                  {{ new Date(props.row.date_modified).toLocaleDateString() }}
                </span>
              </b-table-column>

              <b-table-column field="first_name" label="Edit" sortable v-slot="props">
                <router-link :to="{ name: 'settings.operationSheet.edit', params: { id: props.row.id } }" class="button rounded is-primary">
                  <i class="el-icon-edit"></i>
                </router-link>
              </b-table-column>
          </b-table>
          <router-link :to="{name: 'settings.operationSheet.create'}">
            <el-button icon="el-icon-plus" plain>{{ $t('Add') }}</el-button>
          </router-link>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'

export default {
  name: 'listOperationSheet',
  data () {
    return {
      isPaginated: true,
      isPaginationSimple: false,
      defaultSortDirection: 'asc',
      currentPage: 1,
      perPage: 10,
      token: null,
      name: '',
      value: '',
      formSchema: '',
      id: '',
    }
  },
  computed: {
    ...Vuex.mapGetters(['user', 'operationSheets'])
  },
  methods: {
    ...Vuex.mapActions({
      getOperationSheets: 'getOperationSheets',
      editOperationSheet: 'editOperationSheet'
    }),
    createTemplate () {
      const data = {
        'name': this.name,
        'template': this.formSchema
      }
      this.createOperationSheetTemplate(data)
    }
  },

  mounted: function () {
    this.token = localStorage.getItem('id_token')
    this.headers = {
      'Authorization': 'Bearer ' + this.token,
    }
  }
}
</script>
