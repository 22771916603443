<template>
  <div class="block">
    <el-timeline id="timelineEvents" :reverse="reverse" v-if="observingsystemEvents">
      <el-timeline-item
        v-for="(event, index) in observingsystemEvents"
        :key="index"
        :timestamp="event.date_start">
        <el-button @click="loadEventForm(event.id)" round> {{event.label}} </el-button>
        <span class="event-status__item" v-if="event.event_criticity">
          <el-tooltip class="item" effect="dark" :content="$t('Event criticity')" placement="top-start">
            <el-tag :style="{ 'color': event.event_criticity_color }"> <span class="mdi mdi-alert-decagram"></span> {{event.event_criticity_label}}</el-tag>
          </el-tooltip>
        </span>
        <span class="event-status__item">
          <b-tag type="is-light" v-if="event.is_open"> {{ $t('Open') }} </b-tag>
          <b-tag type="is-dark" v-else> {{ $t('Closed') }} </b-tag>
        </span>
      </el-timeline-item>
    </el-timeline>
    <div v-if="!observingsystemEvents.length || isNoMoreEvents">
      <e-no-more-data/>
    </div>
    <el-dialog
      :title="$t('Event')"
      :visible.sync="showEventForm"
      :before-close="actionDialogClose"
      width="80%"
      center>
      <e-event-form formType="view" @displayEventForm="showEventForm = $event"/>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import Vuex from 'vuex'
import { store } from '@/store'
import NoMoreData from '@/components/NoMoreData.vue'
import EventForm from '@/views/event/Form.vue'

export default {
  name: 'EventsList',
  components: {
    'e-no-more-data': NoMoreData,
    'e-event-form': EventForm,
  },
  data () {
    return {
      page: 1,
      reverse: false,
      showEventForm: false
    }
  },
  watch: {
    page: function () {
      this.loadEvents()
    }
  },
  computed: {
    ...Vuex.mapGetters(['observingsystemEvents', 'observingsystemEventsPage', 'isNoMoreEvents'])
  },
  methods: {
    ...Vuex.mapActions({
      getEvent: 'getEvent',
      getObservingSystemEvents: 'getObservingSystemEvents',
      getUsers: 'getUsers',
    }),
    loadEventForm: function (id) {
      this.getEvent(id)
      this.showEventForm = true
    },
    loadEvents: function () {
      const form = {
        'id': this.$route.params.id,
        'page': this.page
      }
      if(!this.isNoMoreEvents) {
        store.dispatch('getObservingSystemEvents', form)
      }
    },
    actionDialogClose(done) {
      done()
      /**this.getUsers(this.$route.params.observingsystem).then(_ => {
        done()
      })**/
    },
    getNextEvents() {
      window.onscroll = () => {
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
        if (bottomOfWindow) {
          this.page++
        }
      }
    }
  },
  mounted() {
    this.getNextEvents();
  }
}
</script>