<template>
  <div class="medium-center">
    <el-form :model="collection" label-position="top" ref="form" :rules="rules" :hide-required-asterisk="true" :disabled="disabled">
      <el-form-item :label="$t('Datasource')" prop="data_source">
        <el-select name="sltDatasource" v-model="collection.data_source" @change="loadInstrument($event)" placeholder="Select" value-key="id" ref="sltDatasource">
          <el-option
            v-for="item in datasources.results"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('Observatory')" prop="observatory">
        <el-select name="sltObservatory" v-model="collection.observatory" placeholder="Select" value-key="id" ref="sltObservatory">
          <el-option
            v-for="item in observatories.results"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('Site')" prop="site">
        <el-select name="sltSite" v-model="collection.site" placeholder="Select" value-key="id" ref="sltSite">
          <el-option
            v-for="item in sites.results"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('Associated observatories')" prop="associated-observatoires">
        <el-select name="sltObservatories" v-model="collection.associated_observatories" multiple placeholder="Select" value-key="id" ref="sltObservatories">
          <el-option
            v-for="item in observatories.results"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('Instrument clone')" prop="instrument_clone">
        <el-select name="sltInstrumentClone" v-model="collection.instrument_clone" placeholder="Select" value-key="id" ref="sltInstrumentClone">
          <el-option
            v-for="item in instrument.clones"
            :key="item.id"
            :label="item.label"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('Name')" prop="name">
        <el-input v-model="collection.name" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item :label="$t('Label')" prop="label">
        <el-input v-model="collection.label" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item :label="$t('Data storage strategy')" prop="datastorage_strategy" >
        <el-select name="sltDatastorageStrategy" v-model="collection.data_storage_strategy" placeholder="Select" value-key="id" ref="sltDatastorageStrategy">
          <el-option
            v-for="value in listOfDataStorageStrategy"
            :key="value[0]"
            :label="value[1]"
            :value="value[0]">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('Primary data center')" prop="primary_data_center">
        <el-select name="sltNetworks" v-model="collection.primary_data_center" placeholder="Select" value-key="id" ref="sltNetworks">
          <el-option
            v-for="item in networks.results"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('Other data centers')" prop="other_data_centers">
        <el-select name="sltOtherDatacenters" v-model="collection.other_data_centers" placeholder="Select" value-key="id" ref="sltOtherDatacenters" multiple>
          <el-option
            v-for="item in networks.results"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="is_hidden" v-if="!disabled">
        <el-checkbox v-model="collection.is_hidden">{{ $t('Hide the collection') }}</el-checkbox>
      </el-form-item>
      <el-form-item class="lst-btn-end-form" v-if="!disabled">
        <el-button @click.stop="save('form')">{{ $t('Update') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Vuex from 'vuex'
import i18n from '@/i18n'

export default {
  name: 'settingsCollectionEdit',
  props: ['title'],
  data () {
    return {
      disabled: false,
      rules: {
        name: [
          { required: true, message: i18n.t('msg_required_field'), trigger: 'blur' },
        ],
        datasource_link: [
          { required: true, message: i18n.t('msg_required_field'), trigger: 'change' },
        ],
        site: [
          { required: true, message: i18n.t('msg_required_field'), trigger: 'change' },
        ],
        observatory: [
          { required: true, message: i18n.t('msg_required_field'), trigger: 'change' },
        ]
      }
    }
  },
  computed: {
    ...Vuex.mapGetters(['user', 'sites', 'observatories', 'collection', 'datasources', 'instrument', 'networks', 'listOfDataStorageStrategy'])
  },
  methods: {
    ...Vuex.mapActions({
      createCollection: 'createCollection',
      editCollection: 'editCollection',
      getCollection: 'getCollection',
      getDatasources: 'getDatasources',
      getInstrument: 'getInstrument',
      getSites: 'getSites',
      getObservatories: 'getObservatories',
      getListOfDataStorageStrategy: 'getListOfDataStorageStrategy',
      getNetworks: 'getNetworks',
    }),
    loadInstrument (i) {
      this.getInstrument(i)
    },
    save (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$route.name == 'settings.dataprod.collection.create' ? this.createCollection(this.collection) : this.editCollection(this.collection)
        } else {
          this.$buefy.toast.open({
            message: this.$root.$t('check_data_entered'),
            type: 'is-danger'
          })
          return false
        }
      })
    },
    isDisabled () {
      if (this.$route.name == 'dataprod.collection.view') {
        this.disabled = true
      } 
    }

  },
  mounted: function () {
    this.getDatasources()
    this.getListOfDataStorageStrategy()
    this.getNetworks()
    this.getSites()
    this.getObservatories()
    this.isDisabled()
  }
}
</script>