<template>
  <div class="menu" v-if="!isMobile">
    <div class="settings-menu" :class="isCollapse?'close':'open'">
      <div class="settings-menu-content">
        <el-menu default-active="2" class="el-menu-vertical" :collapse="isCollapse" :router="true">
          <el-menu-item :index="{ name: 'dashboard' }">
            <i class="el-icon-menu"></i>
            <span slot="title">{{ $t('My dashboard') }}</span>
          </el-menu-item>
          <el-menu-item :index="{ name: 'profile' }">
            <i class="el-icon-user"></i> 
            <span slot="title">{{ $t('Profile') }}</span>
          </el-menu-item>
          <el-menu-item :index="{ name: 'export' }">
            <i class="el-icon-upload"></i> 
            <span slot="title">{{ $t('Export') }}</span>
          </el-menu-item>
          <el-submenu index="settings" v-if="$can('access', 'adm')">
            <template slot="title">
              <i class="el-icon-setting"></i>
              <span slot="title">{{ $t('Admin area') }}</span>
            </template>
            <el-menu-item :index="{ name: 'settings.organization.list' }" v-if="$can('access', 'adm')" class="el-menu__admin">
              <i class="el-icon-office-building"></i>
              <span slot="title">{{ $t('Organization') }}</span>
            </el-menu-item>
            <el-menu-item :index="{ name: 'settings.observatory.list' }" v-if="$can('access', 'adm')">
              <i class="el-icon-aim"></i>
              <span slot="title">{{ $t('Observatory / Campaign') }}</span>
            </el-menu-item>
            <el-menu-item :index="{ name: 'settings.observingsystem.list', params: { observingsystemType: 'site' } }" v-if="$can('access', 'adm')">
              <i class="el-icon-place"></i>
              <span slot="title">{{ $t('Site') }}</span>
            </el-menu-item>
            <el-submenu index="resources" v-if="$can('manage', 'Resources')" class="submenu">
              <template slot="title">
                <i class="el-icon-share"></i>
                <span slot="title">{{ $t('Observing system') }}</span>
              </template>
              <el-tooltip class="item" effect="light" :content="$t('Instrument')" placement="top-end" open-delay=500>
                <el-menu-item :index="{ name: 'settings.observingsystem.list', params: { observingsystemType: 'instrument' } }" v-if="$can('manage', 'Instrument')">{{ $t('Instrument') }}</el-menu-item>
              </el-tooltip>
              <el-tooltip class="item" effect="light" :content="$t('Software')" placement="top-end" open-delay=500>
                <el-menu-item :index="{ name: 'settings.observingsystem.list', params: { observingsystemType: 'software' } }" v-if="$can('manage', 'Software')">{{ $t('Software') }}</el-menu-item>
              </el-tooltip>
              <el-tooltip class="item" effect="light" :content="$t('Platform')" placement="top-end" open-delay=500>
                <el-menu-item :index="{ name: 'settings.observingsystem.list', params: { observingsystemType: 'platform' } }" v-if="$can('manage', 'Platform')">{{ $t('Platform') }}</el-menu-item>
              </el-tooltip>
              <el-tooltip class="item" effect="light" :content="$t('Network')" placement="top-end" open-delay=500>
                <el-menu-item :index="{ name: 'settings.observingsystem.list', params: { observingsystemType: 'network' } }" v-if="$can('manage', 'Network')">{{ $t('Network') }}</el-menu-item>
              </el-tooltip>
              <el-tooltip class="item" effect="light" :content="$t('Project')" placement="top-end" open-delay=500>
                <el-menu-item :index="{ name: 'settings.observingsystem.list', params: { observingsystemType: 'project' } }" v-if="$can('manage', 'Project')">{{ $t('Project') }}</el-menu-item>
              </el-tooltip>
              <el-tooltip class="item" effect="light" :content="$t('Paper')" placement="top-end" open-delay=500>
                <el-menu-item :index="{ name: 'settings.observingsystem.list', params: { observingsystemType: 'paper' } }" v-if="$can('manage', 'Paper')">{{ $t('Paper') }}</el-menu-item>
              </el-tooltip>
              <el-tooltip class="item" effect="light" :content="$t('Study')" placement="top-end" open-delay=500>
                <el-menu-item :index="{ name: 'settings.observingsystem.list', params: { observingsystemType: 'study' } }" v-if="$can('manage', 'Study')">{{ $t('Study') }}</el-menu-item>
              </el-tooltip>
            </el-submenu>
            <el-submenu index="dataprod" v-if="$can('access', 'adm')" class="submenu">
              <template slot="title">
                <i class="el-icon-s-data"></i>
                <span slot="title">{{ $t('Dataprod') }}</span>
              </template>
              <el-tooltip class="item" effect="light" :content="$t('Collection')" placement="top-end" open-delay=500>
                <el-menu-item :index="{ name: 'settings.observingsystem.list', params: { observingsystemType: 'data_collection' } }">{{ $t('Collection') }}</el-menu-item>
              </el-tooltip>
              <el-tooltip class="item" effect="light" :content="$t('Processing Level')" placement="top-end" open-delay=500>
                <el-menu-item :index="{ name: 'settings.dataprod.processingLevel.list' }">{{ $t('Processing Level') }}</el-menu-item>
              </el-tooltip>
              <el-tooltip class="item" effect="light" :content="$t('Product')" placement="top-end" open-delay=500>
                <el-menu-item :index="{ name: 'settings.observingsystem.list', params: { observingsystemType: 'data_product' } }">{{ $t('Product') }}</el-menu-item>
              </el-tooltip>
              <el-tooltip class="item" effect="light" :content="$t('Production version')" placement="top-end" open-delay=500>
                <el-menu-item :index="{ name: 'settings.dataprod.productionVersion.list' }">{{ $t('Production version') }}</el-menu-item>
              </el-tooltip>
            </el-submenu>
            <!--<el-tooltip class="item" effect="light" :content="$t('Operation Sheet')" placement="top-end" open-delay=500 v-if="$can('access', 'set-metop')">
              <el-menu-item :index="{ name: 'settings.operationSheet.list' }">
                <i class="el-icon-s-order"></i>
                <span slot="title">{{ $t('Operation Sheet') }}</span>
              </el-menu-item>
            </el-tooltip>
            <el-tooltip class="item" effect="light" :content="$t('Quality Control Sheet')" placement="top-end" open-delay=500 v-if="$can('access', 'set-metqual')">
              <el-menu-item :index="{ name: 'settings.qualityControlSheet.list' }">
                <i class="el-icon-s-claim"></i>
                <span slot="title">{{ $t('Quality Control Sheet') }}</span>
              </el-menu-item>
            </el-tooltip>-->
            <el-submenu index="templates" v-if="$can('access', 'set-templates')" class="submenu">
              <template slot="title">
                <i class="el-icon-copy-document"></i>
                <span slot="title">{{ $t('Templates') }}</span>
              </template>
              <el-tooltip class="item" effect="light" :content="$t('Site')" placement="top-end" open-delay=500>
                <el-menu-item :index="{ name: 'settings.vocabulary.template.list', params: { title: 'Site', type: 'observingsystem', subtype: 'site' } }">{{ $t('Site') }}</el-menu-item>
              </el-tooltip>
              <el-tooltip class="item" effect="light" :content="$t('Instrument')" placement="top-end" open-delay=500>
                <el-menu-item :index="{ name: 'settings.vocabulary.template.list', params: { title: 'Instrument', type: 'observingsystem', subtype: 'instrument' } }">{{ $t('Instrument') }}</el-menu-item>
              </el-tooltip>
              <el-tooltip class="item" effect="light" :content="$t('Software')" placement="top-end" open-delay=500>
                <el-menu-item :index="{ name: 'settings.vocabulary.template.list', params: { title: 'Software', type: 'observingsystem', subtype: 'software' } }">{{ $t('Software') }}</el-menu-item>
              </el-tooltip>
              <el-tooltip class="item" effect="light" :content="$t('Platform')" placement="top-end" open-delay=500>
                <el-menu-item :index="{ name: 'settings.vocabulary.template.list', params: { title: 'Platform', type: 'observingsystem', subtype: 'platform' } }">{{ $t('Platform') }}</el-menu-item>
              </el-tooltip>
              <el-tooltip class="item" effect="light" :content="$t('Network')" placement="top-end" open-delay=500>
                <el-menu-item :index="{ name: 'settings.vocabulary.template.list', params: { title: 'Network', type: 'observingsystem', subtype: 'network' } }">{{ $t('Network') }}</el-menu-item>
              </el-tooltip>
              <el-tooltip class="item" effect="light" :content="$t('Campaign')" placement="top-end" open-delay=500>
                <el-menu-item :index="{ name: 'settings.vocabulary.template.list', params: { title: 'Campaign', type: 'observingsystem', subtype: 'campaign' } }">{{ $t('Campaign') }}</el-menu-item>
              </el-tooltip>
              <el-tooltip class="item" effect="light" :content="$t('Project')" placement="top-end" open-delay=500>
                <el-menu-item :index="{ name: 'settings.vocabulary.template.list', params: { title: 'Project', type: 'observingsystem', subtype: 'project' } }">{{ $t('Project') }}</el-menu-item>
              </el-tooltip>
              <el-tooltip class="item" effect="light" :content="$t('Paper')" placement="top-end" open-delay=500>
                <el-menu-item :index="{ name: 'settings.vocabulary.template.list', params: { title: 'Paper', type: 'observingsystem', subtype: 'paper' } }">{{ $t('Paper') }}</el-menu-item>
              </el-tooltip>
              <el-tooltip class="item" effect="light" :content="$t('Study')" placement="top-end" open-delay=500>
                <el-menu-item :index="{ name: 'settings.vocabulary.template.list', params: { title: 'Study', type: 'observingsystem', subtype: 'study' } }">{{ $t('Study') }}</el-menu-item>
              </el-tooltip>
              <el-tooltip class="item" effect="light" :content="$t('Collection')" placement="top-end" open-delay=500>
                <el-menu-item :index="{ name: 'settings.vocabulary.template.list', params: { title: 'Collection', type: 'observingsystem', subtype: 'data_collection' } }">{{ $t('Collection') }}</el-menu-item>
              </el-tooltip>
              <el-tooltip class="item" effect="light" :content="$t('Product')" placement="top-end" open-delay=500>
                <el-menu-item :index="{ name: 'settings.vocabulary.template.list', params: { title: 'Product', type: 'observingsystem', subtype: 'data_product' } }">{{ $t('Product') }}</el-menu-item>
              </el-tooltip>
              <el-tooltip class="item" effect="light" :content="$t('Event')" placement="top-end" open-delay=500>
                <el-menu-item :index="{ name: 'settings.vocabulary.template.list', params: { title: 'Event', type: 'event' } }">{{ $t('Event') }}</el-menu-item>
              </el-tooltip>
              <el-tooltip class="item" effect="light" :content="$t('Operation Sheet')" placement="top-end" open-delay=500>
                <el-menu-item :index="{ name: 'settings.vocabulary.template.list', params: { title: 'Operation sheet', type: 'operationsheet' } }"> {{ $t('Operation Sheet') }}</el-menu-item>
              </el-tooltip>
              <el-tooltip class="item" effect="light" :content="$t('Quality Control Sheet')" placement="top-end" open-delay=500>
                <el-menu-item :index="{ name: 'settings.vocabulary.template.list', params: { title: 'Quality control sheet', type: 'qualitycontrolsheet' } }">{{ $t('Quality Control Sheet') }}</el-menu-item>
              </el-tooltip>
            </el-submenu>
            <!--<el-menu-item :index="{ name: 'settings.vocabulary.list' }" v-if="$can('access', 'find-voc')">
              <i class="el-icon-collection"></i>
              <span slot="title">{{ $t('Vocabulary') }}</span>
            </el-menu-item>-->
            <el-menu-item :index="{ name: 'scan' }" v-if="$can('access', 'adm')">
              <i class="el-icon-c-scale-to-original"></i>
              <span slot="title">{{ $t('Scan') }}</span>
            </el-menu-item>
          </el-submenu>
        </el-menu>
        <button class="menu-footer" v-on:click="isCollapse = !isCollapse" :class="isCollapse?'close':'open'">
          <i class="el-icon-d-arrow-right" v-if="isCollapse"></i>
          <i class="el-icon-d-arrow-left" v-if="!isCollapse"></i>
          <span slot="title" v-if="!isCollapse">{{ $t('Collapse') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<style>
  
</style>

<script>
  import Vuex from 'vuex'
  var MobileDetect = require('mobile-detect')
  var md = new MobileDetect(navigator.userAgent)

  export default {
    data() {
      return {
        isMobile: false,
        isCollapse: true,
        menuState: 'open'
      };
    },
    mounted: function () {
      if (md.mobile()) {
        this.isMobile = true
        this.isCollapse = true
      } else {
        this.isMobile = false
        this.isCollapse = true
      }
    },
    computed: {
      ...Vuex.mapGetters(['user'])
    }
  }
</script>