<template>
  <div class="medium-center">
    <e-event-form formType="edit"/>
  </div>
</template>

<script>
import Vuex from 'vuex'
import { store } from '@/store'
import EventForm from '@/views/event/Form.vue'

export default {
  name: 'settingsEventCreate',
  components: {
    'e-event-form': EventForm,
  },
  methods: {
    ...Vuex.mapActions({
      getEvent: 'getEvent',
    })
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('getEvent', to.params.id).then(next())
  }
}
</script>