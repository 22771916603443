import api from '@/store/api'

export default {
  get (id) {
    return api.get('resources/observatories/' + id + '/')
  },
  getDetail (id) {
    return api.get('resources/observatories/' + id + '/get_detail')
  },
  getByName (obs) {
    return api.get('resources/observatories/?page=1&name=' + obs)
  },
  getList (form) {
    var req = 'resources/observatories/?'
    if(form['page'])
      req = req + '&page=' + form['page']
    if(form['name'])
      req = req + '&name=' + form['name']
    if(form['observingsystemType'])
      req = req + '&type_of_system=' + form['observingsystemType']
    return api.get(req)
  },
  create (data) {
    return api.post('resources/observatories/', data)
  },
  update (id, data) {
    return api.patch('resources/observatories/' + id + '/', data)
  },
}
