import { ToastProgrammatic as Toast } from 'buefy'
import Vocabulary from '@/store/api/vocabulary'
import router from '@/router'
import i18n from '@/i18n'

// state
const state = {
  templates: {},
  template: {},
  eventTemplates: {},
  words: {},
  word: {}
}

// getters
const getters = {
  templates: state => state.templates,
  template: state => state.template,
  eventTemplates: state => state.eventTemplates,
  words: state => state.words,
  word: state => state.word,
}

// mutations
const mutations = {
  SET_TEMPLATES: function (state, data) {
    state.templates = data
  },
  SET_TEMPLATE: function (state, data) {
    state.template = data
  },
  INIT_TEMPLATE: function (state) {
    state.template = {
      'name' : null,
      'type' : null,
      'subtype' : null,
      'template' : null
    }
  },
  SET_EVENT_TEMPLATES: function (state, data) {
    state.eventTemplates = data
  },
  SET_WORDS: function (state, data) {
    state.words = data
  },
  SET_WORD: function (state, data) {
    state.word = data
  },
  INIT_WORD: function (state) {
    state.word = {
      'vocabulary_version' : null,
      'category' : null,
      'name' : null,
      'label' : null,
      'data_type' : null,
      'data_widget' : null,
      'synonyms' : [],
      'limited_values' : false,
      'references' : [],
      'deprecated' : false,
      'use_instead' : null,
      'comment' : null
    }
  }
}

// actions: method for CRUD actions
const actions = {
  async createTemplate ({ commit }, form) {
    try {
      await Vocabulary.createTemplate(form)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
      router.push({
        name: 'settings.vocabulary.template.list',
        params: { type: form['type'], subtype: form['subtype'] }
      })
    } catch (e) {
      commit('SET_TEMPLATES', null)
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
    }
  },

  async editTemplate ({ commit }, form) {
    try {
      await Vocabulary.updateTemplate(form['id'], form)
      const c = await Vocabulary.getTemplate(form['id'])
      await commit('SET_TEMPLATE', c.data)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
    }
  },

  async getTemplates ({ commit }, form) {
    try {
      const c = await Vocabulary.getListTemplates(form)
      await commit('SET_TEMPLATES', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getTemplate ({ commit }, id) {
    try {
      const c = await Vocabulary.getTemplate(id)
      await commit('SET_TEMPLATE', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getEventTemplates ({ commit }) {
    try {
      const data =  {
        'type': 'event'
      }
      const c = await Vocabulary.getListTemplates(data)
      await commit('SET_EVENT_TEMPLATES', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('test'),
        type: 'is-danger'
      })
    }
  },

  async createWord ({ commit }, form) {
    try {
      const word = await Vocabulary.create(form)
      commit('SET_WORD', word)
      // Reload word list
      const c = await Vocabulary.getList()
      await commit('SET_WORDS', c.data)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
      router.push({
        name: 'settings.vocabulary.list'
      })
    } catch (e) {
      commit('SET_WORD', null)
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
      return false
    }
  },

  async editWord ({ commit }, form) {
    try {
      const word = await Vocabulary.update(form['id'], form)
      commit('SET_WORD', word.data)
      // Reload word list
      const c = await Vocabulary.getList()
      await commit('SET_WORDS', c.data)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
    }
  },

  async searchWords ({ commit }, txt) {
    try {
      const c = await Vocabulary.search(txt)
      await commit('SET_WORDS', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getWords ({ commit }) {
    try {
      const c = await Vocabulary.getList()
      await commit('SET_WORDS', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getWord ({ commit }, id) {
    try {
      const c = await Vocabulary.get(id)
      await commit('SET_WORD', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async initWord ({ commit }) {
    try {
      await commit('INIT_WORD')
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

}

export default {
  state,
  getters,
  mutations,
  actions
}
