<template>
  <div class="medium-center">
    <collection-form/>
  </div>
</template>

<script>
import Vuex from 'vuex'
import { store } from '@/store'
import CollectionForm from './Form.vue'

export default {
  name: 'settingsCollectionEdit',
  components: {
    'collection-form': CollectionForm,
  },
  methods: {
    ...Vuex.mapActions({
      getCollection: 'getCollection',
    })
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('getCollection', to.params.id).then(next())
  }
}
</script>