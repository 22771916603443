<template>
  <div class="full-width">
    <h1 class="title settings-title">
      {{ $t('Quality Control Sheet') }} 
    </h1>
    <div class="columns medium-center">
      <div class="column">
        <section>
          <b-table
            :data="qualityControlSheets.results"
            :paginated="isPaginated"
            :per-page="perPage"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :default-sort-direction="defaultSortDirection"
            default-sort="id"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page">
            <b-table-column field="id" label="ID" width="40" sortable numeric v-slot="props">
                {{ props.row.id }}
            </b-table-column>

            <b-table-column field="first_name" label="File name" sortable v-slot="props">
                {{ props.row.name }}
            </b-table-column>

            <b-table-column field="date" label="Date" sortable centered v-slot="props">
                <span class="tag is-success">
                    {{ new Date(props.row.date).toLocaleDateString() }}
                </span>
            </b-table-column>

            <b-table-column field="first_name" label="Edit" sortable v-slot="props">
                <router-link :to="{ name: 'settings.qualityControlSheet.edit', params: { id: props.row.id } }" class="button rounded is-primary">
                  <i class="el-icon-edit"></i>
                </router-link>
            </b-table-column>
          </b-table>
          <router-link :to="{name: 'settings.qualityControlSheet.create'}">
            <el-button icon="el-icon-plus" plain>{{ $t('Add') }}</el-button>
          </router-link>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'

export default {
  name: 'listQualityControlSheet',
  data () {
    return {
      isPaginated: true,
      isPaginationSimple: false,
      defaultSortDirection: 'asc',
      currentPage: 1,
      perPage: 10,
      token: null,
      name: '',
      value: '',
      formSchema: '',
      id: '',
    }
  },
  computed: {
    ...Vuex.mapGetters(['qualityControlSheets'])
  },
  methods: {
    ...Vuex.mapActions({
      getQualityControlSheets: 'getQualityControlSheets',
      editQualityControlSheet: 'editQualityControlSheet'
    }),
    onCmCodeChange(newCode) {
      let res = eval("(" + newCode + ")")
      this.formSchema = res
    },
    createTemplate () {
      const data = {
        'name': this.name,
        'template': this.formSchema
      }
      this.createQualityControlSheetTemplate(data)
    }
  },

  mounted: function () {
    this.$nextTick(function () {
      this.getQualityControlSheets()
      this.token = localStorage.getItem('id_token')
      this.headers = {
        'Authorization': 'Bearer ' + this.token,
      }
    })
  }

}
</script>
