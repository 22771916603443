<template>
  <div class="home">
    <div class="container">
      <section class="hero pt-middle">
        <div class="hero-body">
          <div class="container">
            <h1 class="title">
              {{ $t('Explore observatory data') }}
            </h1>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="container">
          <section class="list-element category">
            <router-link class="card home"
              v-model="observatories"
              v-for="o in observatories"
              v-bind:item="o"
              v-bind:key="o.name" :to="{ name: 'observatory.visuData', params: { obs: o.name } }">
              <el-card class="zoom-hover" v-bind:style="{ backgroundImage: 'url(' + o.thumbnail_m + ')' }">
                <div class="title">
                    {{ o.name.toUpperCase() }}
                </div>
                <div class="overlay"></div>
              </el-card>
            </router-link>
          </section>
        </div>
      </section>
      <section class="hero pt-middle" v-if="campaigns.length > 0">
        <div class="hero-body">
          <div class="container">
            <h1 class="title">
              {{ $t('The campaigns') }}
            </h1>
          </div>
        </div>
      </section>
      <section class="section" v-if="campaigns.length > 0">
        <div class="container">
          <section class="list-element category">
            <router-link class="card home"
              v-model="campaigns"
              v-for="o in campaigns"
              v-bind:item="o"
              v-bind:key="o.name" :to="{ name: 'observatory.visuData', params: { obs: o.name } }">
              <el-card class="zoom-hover" v-bind:style="{ backgroundImage: 'url(' + o.thumbnail_m + ')' }">
                <div class="title">
                    {{ o.name.toUpperCase() }}
                </div>
                <div class="overlay"></div>
              </el-card>
            </router-link>
          </section>
        </div>
      </section>
      <section class="hero pt-big">
        <div class="hero-body">
          <div class="container">
            <h1 class="title">
              {{ $t('home_p2') }}
            </h1>
          </div>
        </div>
      </section>
      <section>
        <br><br>
        <nav class="level">
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">{{ $t('Observatories') }}</p>
              <p class="title">{{ counters.observatory_count }}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">{{ $t('Sites') }}</p>
              <p class="title">{{ counters.site_count }}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">{{ $t('Instruments') }}</p>
              <p class="title">{{ counters.instrument_count }}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">{{ $t('Users') }}</p>
              <p class="title">{{ counters.user_count }}</p>
            </div>
          </div>
        </nav>
        <br><br>
      </section>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'

export default {
  name: 'home',
  data () {
    return {
      activeName: '1'
    }
  },
  computed: {
    ...Vuex.mapGetters(['user', 'observatories', 'campaigns', 'counters'])
  },
  methods: {
    ...Vuex.mapActions({
      getObservatories: 'getObservatories',
      getCampaigns: 'getCampaigns',
      getCounters: 'getCounters'
    }),
  },
  mounted: function () {
    this.$nextTick(function () {
      this.getObservatories(),
      this.getCampaigns(),
      this.getCounters()
    })
  }
}
</script>
