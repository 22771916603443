<template>
  <el-tiptap
    :extensions="extensions"
    :content="data"
    :placeholder="$t('Write something ...')"
    :readonly="isDisabled"
    @onUpdate="onUpdate"
  />
</template>

<script>
import Vuex from 'vuex'

import {
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Underline,
  Italic,
  Code,
  Blockquote,
  ListItem,
  BulletList,
  OrderedList,
  TodoItem,
  TodoList,
  TextAlign,
  Indent,
  HardBreak,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  HorizontalRule,
  Fullscreen,
  Print,
  History,
} from 'element-tiptap'

export default {
  props: {
    data: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    isDisabled: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      extensions: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new Heading({ level: 5 }),
        new Bold({ bubble: true }),
        new Underline({ bubble: true }),
        new Italic({ bubble: true }),
        new Code(),
        new Blockquote(),
        new TextAlign(),
        new ListItem(),
        new BulletList({ bubble: true }),
        new OrderedList({ bubble: true }),
        new TodoItem(),
        new TodoList({ bubble: true }),
        new Indent(),
        new HardBreak(),
        new Table({ resizable: true }),
        new TableHeader(),
        new TableCell(),
        new TableRow(),
        new HorizontalRule({ bubble: true }),
        new Fullscreen(),
        new Print(),
        new History(),
      ],
    }
  },
  computed: {
    ...Vuex.mapGetters(['observingsystem', 'event', 'operationSheet', 'qualityControlSheet'])
  },
  methods: {
    onUpdate (output, options) {
      const { getHTML } = options
      if(this.type=='observingsystem'){
        this.observingsystem.description = getHTML()
      }
      if(this.type=='event'){
        this.event.description = getHTML()
      }
      if(this.type=='operationSheet'){
        this.operationSheet.description = getHTML()
      }
      if(this.type=='qualityControlSheet'){
        this.qualityControlSheet.description = getHTML()
      }
    },
  },
}
</script>