<template>
  <e-loading v-if="isLoadingObservingsystem"></e-loading>
  <div class="instrument full-width" v-else>
    <e-observingsystem-form isVersion="false" formType="edit"/>
  </div>
</template>

<script>
import Vuex from 'vuex'
import { store } from '@/store'
import ObservingSystemForm from '@/views/observingsystem/Form.vue'
import Loading from '@/components/Loading'

export default {
  name: 'editObservingSystem',
  components: {
    'e-observingsystem-form': ObservingSystemForm,
    'e-loading': Loading
  },
  computed: {
    ...Vuex.mapGetters(['user', 'template', 'isLoadingObservingsystem'])
  },
  methods: {
    ...Vuex.mapActions({
      getObservingSystem: 'getObservingSystem',
    }),
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('initLoadingObservingSystem')
    store.dispatch('getObservingSystem', to.params.id).then(next())
  }
}
</script>