import api from '@/store/api'

export default {
  get (id) {
    return api.get('dataprod/qualityControlSheets/' + id + '/')
  },
  getList (productionVersion, dateRelease) {
    var req = 'dataprod/qualityControlSheets/?'
    if(productionVersion)
      req = req + '&production_version=' + productionVersion
    if(dateRelease)
      req = req + '&date_release=' + dateRelease
    return api.get(req)
  },
  create (data) {
    return api.post('dataprod/qualityControlSheets/', data)
  },
  update (id, data) {
    return api.patch('dataprod/qualityControlSheets/' + id + '/', data)
  },
  exportQualityControlSheets (form) {
    var req = 'dataprod/productionVersions/' + form['id'] + '/export_qualitycontrol_sheets/?'
    if(form['date_release_start_bs'])
      req = req + '&date_release_start_bs=' + form['date_release_start_bs']
    if(form['date_release_start_be'])
      req = req + '&date_release_start_be=' + form['date_release_start_be']
    if(form['date_release_end_bs'])
      req = req + '&date_release_end_bs=' + form['date_release_end_bs']
    if(form['date_release_end_be'])
      req = req + '&date_release_end_be=' + form['date_release_end_be']
    if(form['fields'])
      req = req + '&fields=' + form['fields']
    return api.get(req, { responseType: 'blob' })
  },
}
