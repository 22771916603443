import api from '@/store/api'

export default {
  get (year, obs, processing_level) {
    var req = 'visudata/?year=' + year
    if(obs)
      req = req + '&observatory=' + obs
    if(processing_level)
      req = req + '&processing_level=' + processing_level
    return api.get(req)
  },
  getListYears (obs) {
    var req = 'visudata/years/?'
    if(obs)
      req = req + 'observatory=' + obs
    return api.get(req)
  },
  getDataCollectionTimeline (data_collection, year, month, processing_level) {
    var req = 'visudata/timeline/?data_collection=' + data_collection + '&year=' + year + '&month=' + month
    if(processing_level)
      req = req + '&processing_level=' + processing_level
    return api.get(req)
  },
  getQuicklooks (obs, calendar) {
    var req = 'visudata/quicklooks/?'
    if(obs)
      req = req + 'observatory=' + obs
    if(calendar)
      req = req + '&day=' + calendar
    return api.get(req)
  },
}