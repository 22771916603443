import { ToastProgrammatic as Toast } from 'buefy'
import DataFile from '@/store/api/dataFile'
// import router from '@/router'
import i18n from '@/i18n'

// state
const state = {
  dataFiles: {},
  dataFile: {}
}

// getters
const getters = {
  dataFiles: state => state.dataFiles,
  dataFile: state => state.dataFile,
}

// mutations
const mutations = {
  SET_DATAFILES: function (state, data) {
    state.dataFiles = data
  },
  SET_DATAFILE: function (state, data) {
    state.dataFile = data
  },
  INIT_DATAFILE: function (state) {
    state.dataFile = {
      'filename' : null,
      'filelink' : null,
      'extension' : null,
      'is_quicklook' : null,
      'instrument_clone' : null,
      'nb_download' : null
    }
  }
}

// actions: method for CRUD actions
const actions = {

  async createDataFile ({ commit }, form) {
    try {
      const ins = await DataFile.create(form)
      commit('SET_DATAFILE', ins)
      // Reload dataFile list
      const c = await DataFile.getList()
      await commit('SET_DATAFILES', c.data)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
      /** 
      router.push({
        name: 'settings.dataprod.dataFile.list'
      })
      */
    } catch (e) {
      commit('SET_DATAFILE', null)
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
      return false
    }
  },

  async editDataFile ({ commit }, form) {
    try {
      const ins = await DataFile.update(form['id'], form)
      commit('SET_DATAFILE', ins.data)
      // Reload dataFile list
      const c = await DataFile.getList()
      await commit('SET_DATAFILES', c.data)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
    }
  },

  async updateCloneByDateRange ({ commit }, form) {
    try {
      const c = await DataFile.updateCloneByDateRange(form['data_collection'], form['clone_used'], form['date_range'][0].toISOString(), form['date_range'][1].toISOString() )
      commit('SET_DATAFILE', c.data)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },


  async getDataFiles ({ commit }, data) {
    try {
      const c = await DataFile.getList(data.production_version, data.date_release)
      await commit('SET_DATAFILES', c.data.results)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getDataFile ({ commit }, id) {
    try {
      const c = await DataFile.get(id)
      await commit('SET_DATAFILE', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async initDataFile ({ commit }) {
    try {
      await commit('INIT_DATAFILE')
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

}

export default {
  state,
  getters,
  mutations,
  actions
}
