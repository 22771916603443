<template>
  <div class="template full-width">
    <h1 class="title settings-title cap">
      {{ title }} {{ $t('templates') }} 
    </h1>
    <div class="columns medium-center">
      <div class="column">
        <section>
          <b-table
            :data="templates"
            :paginated="isPaginated"
            :per-page="perPage"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :default-sort-direction="defaultSortDirection"
            default-sort="id"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page">
            <b-table-column field="id" :label="$t('ID')" width="40" sortable numeric v-slot="props">
                {{ props.row.id }}
            </b-table-column>

            <b-table-column field="name" :label="$t('Name')" sortable v-slot="props">
                {{ props.row.name }}
            </b-table-column>

            <b-table-column field="label" :label="$t('Label')" v-slot="props">
                {{ props.row.label }}
            </b-table-column>

            <b-table-column field="actions" :label="$t('Actions')" v-slot="props">
                <router-link :to="{ name: 'settings.vocabulary.template.edit', params: { id: props.row.id } }" class="button rounded is-primary">
                  <i class="el-icon-edit"></i>
                </router-link>
            </b-table-column>
          </b-table>
          <router-link :to="{ name: 'settings.vocabulary.template.create', params: { type: this.type, subtype: this.subtype } }">
            <el-button icon="el-icon-plus" plain>{{ $t('Add') }}</el-button>
          </router-link>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'
import { store } from '@/store'

export default {
  name: 'listTemplate',
  data () {
    return {
      title: null,
      type: null,
      subtype: null,
      isPaginated: true,
      isPaginationSimple: false,
      defaultSortDirection: 'asc',
      currentPage: 1,
      perPage: 10,
      token: null,
      name: '',
      value: '',
      formSchema: '',
      id: '',
    }
  },
  computed: {
    ...Vuex.mapGetters(['user', 'templates'])
  },
  methods: {
    ...Vuex.mapActions({
      getTemplates: 'getTemplates',
      editTemplate: 'editTemplate'
    }),
  },

  beforeRouteEnter (to, from, next) {
    const data = {
      'type': to.params.type,
      'subtype': to.params.subtype,
    }
    store.dispatch('getTemplates', data).then(next())
  },

  beforeRouteUpdate(to, from, next) {
    this.title = to.params.title
    this.type = to.params.type
    this.subtype = to.params.subtype
    const data = {
      'type': this.type,
      'subtype': this.subtype,
    }
    this.token = localStorage.getItem('id_token')
    this.headers = {
      'Authorization': 'Bearer ' + this.token,
    }
    store.dispatch('getTemplates', data).then(next())
  },

  mounted: function () {
    this.title = this.$route.params.title
    this.type = this.$route.params.type
    this.subtype = this.$route.params.subtype
    this.token = localStorage.getItem('id_token')
    this.headers = {
      'Authorization': 'Bearer ' + this.token,
    }
    const data = {
      'type': this.type,
      'subtype': this.subtype,
    }
    this.$nextTick(function () {
      this.getTemplates(data)
    })
  }
}
</script>
