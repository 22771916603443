/* eslint-disable */
import { Toast } from 'buefy'
import Utility from '@/store/api/utility'
import i18n from '@/i18n'

// state
const state = {
  counters: null,
  months: null,
  listOfGroups: null,
  listOfAccessRights: null,
  listOfObservingSystemType: null,
  listOfObservatoryType: null,
  listOfWordCategory: null,
  listOfWordDataType: null,
  listOfWordDataWidget: null,
  listOfDataStorageStrategy: null,
  listOfProductCategory: null,
  listOfProductVersionRecordMode: null,
  listOfProductionVersionPolicy: null,
  listOfProductionVersionQCMode: null,
  listOfQualityControlStatus: null,
  listOfEventCategory: null,
  listOfEventCriticity: null,
  listOfLanguages: null,
  listOfLanguagesIso: null,
  listOfIsoTopicCategory: null,
  listOfGeonetworkRights: null,
  listOfGeonetworkTypeOfSystem: null
}

// getters
const getters = {
  counters: state => state.counters,
  months: state => state.months,
  listOfGroups: state => state.listOfGroups,
  listOfAccessRights: state => state.listOfAccessRights,
  listOfObservingSystemType: state => state.listOfObservingSystemType,
  listOfObservatoryType: state => state.listOfObservatoryType,
  listOfWordCategory: state => state.listOfWordCategory,
  listOfWordDataType: state => state.listOfWordDataType,
  listOfWordDataWidget: state => state.listOfWordDataWidget,
  listOfDataStorageStrategy: state => state.listOfDataStorageStrategy,
  listOfProductCategory: state => state.listOfProductCategory,
  listOfProductVersionRecordMode: state => state.listOfProductVersionRecordMode,
  listOfProductionVersionPolicy: state => state.listOfProductionVersionPolicy,
  listOfProductionVersionQCMode: state => state.listOfProductionVersionQCMode,
  listOfQualityControlStatus: state => state.listOfQualityControlStatus,
  listOfEventCategory: state => state.listOfEventCategory,
  listOfEventCriticity: state => state.listOfEventCriticity,
  listOfLanguages: state => state.listOfLanguages,
  listOfLanguagesIso: state => state.listOfLanguagesIso,
  listOfIsoTopicCategory: state => state.listOfIsoTopicCategory,
  listOfGeonetworkRights: state => state.listOfGeonetworkRights,
  listOfGeonetworkTypeOfSystem: state => state.listOfGeonetworkTypeOfSystem,
}

// mutations
const mutations = {
  SET_COUNTERS: function (state, data) {
    state.counters = data
  },
  SET_MONTHS: function (state, data) {
    state.months = data
  },
  SET_LISTOF_GROUPS: function (state, data) {
    state.listOfGroups = data
  },
  SET_LISTOF_ACCESS_RIGHTS: function (state, data) {
    state.listOfAccessRights = data
  },
  SET_LISTOF_OBSERVINGSYSTEM_TYPE: function (state, data) {
    state.listOfObservingSystemType = data
  },
  SET_LISTOF_OBSERVATORY_TYPE: function (state, data) {
    state.listOfObservatoryType = data
  },
  SET_LISTOF_WORD_CATEGORY: function (state, data) {
    state.listOfWordCategory = data
  },
  SET_LISTOF_WORD_DATA_TYPE: function (state, data) {
    state.listOfWordDataType = data
  },
  SET_LISTOF_WORD_DATA_WIDGET: function (state, data) {
    state.listOfWordDataWidget = data
  },
  SET_LISTOF_DATASTORAGE_STRATEGY: function (state, data) {
    state.listOfDataStorageStrategy = data
  },
  SET_LISTOF_PRODUCT_CATEGORY: function (state, data) {
    state.listOfProductCategory = data
  },
  SET_LISTOF_PRODUCTVERSION_RECORD_MODE: function (state, data) {
    state.listOfProductVersionRecordMode = data
  },
  SET_LISTOF_PRODUCTIONVERSION_POLICY: function (state, data) {
    state.listOfProductionVersionPolicy = data
  },
  SET_LISTOF_PRODUCTIONVERSION_QCMODE: function (state, data) {
    state.listOfProductionVersionQCMode = data
  },
  SET_LISTOF_QUALITYCONTROL_STATUS: function (state, data) {
    state.listOfQualityControlStatus = data
  },
  SET_LISTOF_EVENT_CATEGORY: function (state, data) {
    state.listOfEventCategory = data
  },
  SET_LISTOF_EVENT_CRITICITY: function (state, data) {
    state.listOfEventCriticity = data
  },
  SET_LISTOF_LANGUAGES: function (state, data) {
    state.listOfLanguages = data
  },
  SET_LISTOF_LANGUAGES_ISO: function (state, data) {
    state.listOfLanguagesIso = data
  },
  SET_LISTOF_ISO_TOPIC_CATEGORY: function (state, data) {
    state.listOfIsoTopicCategory = data
  },
  SET_LISTOF_GEONETWORK_RIGHTS: function (state, data) {
    state.listOfGeonetworkRights = data
  },
  SET_LISTOF_GEONETWORK_TYPE_SYSTEM: function (state, data) {
    state.listOfGeonetworkTypeOfSystem = data
  },
  INIT_ELEMENTS: function (state) {
    state.listOfAccessRights = []
  },
}

// actions
const actions = {
  async getCounters ({ commit }) {
    try {
      const c = await Utility.getCounters()
      await commit('SET_COUNTERS', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },
  async getMonths ({ commit }) {
    try {
      const c = { 
        "data": [
          {
            "id": 1,
            "name": i18n.t('January')
          },
          {
            "id": 2,
            "name": i18n.t('February')
          },
          {
            "id": 3,
            "name": i18n.t('March')
          },
          {
            "id": 4,
            "name": i18n.t('April')
          },
          {
            "id": 5,
            "name": i18n.t('May')
          },
          {
            "id": 6,
            "name": i18n.t('June')
          },
          {
            "id": 7,
            "name": i18n.t('July')
          },
          {
            "id": 8,
            "name": i18n.t('August')
          },
          {
            "id": 9,
            "name": i18n.t('September')
          },
          {
            "id": 10,
            "name": i18n.t('October')
          },
          {
            "id": 11,
            "name": i18n.t('November')
          },
          {
            "id": 12,
            "name": i18n.t('December')
          }
        ] 
      }
      await commit('SET_MONTHS', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },
  async getListOfGroups ({ commit }) {
    try {
      const c = await Utility.getListOfGroups()
      await commit('SET_LISTOF_GROUPS', c.data.results)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },
  async getListOfObservingSystemType ({ commit }) {
    try {
      const c = await Utility.getListOfObservingSystemType()
      await commit('SET_LISTOF_OBSERVINGSYSTEM_TYPE', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },
  async getListOfWordCategory ({ commit }) {
    try {
      const c = await Utility.getListOfWordCategory()
      await commit('SET_LISTOF_WORD_CATEGORY', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },
  async getListOfWordDataType ({ commit }) {
    try {
      const c = await Utility.getListOfWordDataType()
      await commit('SET_LISTOF_WORD_DATA_TYPE', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },
  async getListOfWordDataWidget ({ commit }) {
    try {
      const c = await Utility.getListOfWordDataWidget()
      await commit('SET_LISTOF_WORD_DATA_WIDGET', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },
  async getListOfDataStorageStrategy ({ commit }) {
    try {
      const c = await Utility.getListOfDataStorageStrategy()
      await commit('SET_LISTOF_DATASTORAGE_STRATEGY', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },
  async getListOfProductCategory ({ commit }) {
    try {
      const c = await Utility.getListOfProductCategory()
      await commit('SET_LISTOF_PRODUCT_CATEGORY', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },
  async getListOfProductVersionRecordMode ({ commit }) {
    try {
      const c = await Utility.getListOfProductVersionRecordMode()
      await commit('SET_LISTOF_PRODUCTVERSION_RECORD_MODE', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },
  async getListOfProductionVersionPolicy ({ commit }) {
    try {
      const c = await Utility.getListOfProductionVersionPolicy()
      await commit('SET_LISTOF_PRODUCTIONVERSION_POLICY', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },
  async getListOfProductionVersionQCMode ({ commit }) {
    try {
      const c = await Utility.getListOfProductionVersionQCMode()
      await commit('SET_LISTOF_PRODUCTIONVERSION_QCMODE', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },
  async getListOfQualityControlStatus ({ commit }) {
    try {
      const c = await Utility.getListOfQualityControlStatus()
      await commit('SET_LISTOF_QUALITYCONTROL_STATUS', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },
  async getListOfEventCategory ({ commit }) {
    try {
      const c = await Utility.getListOfEventCategory()
      await commit('SET_LISTOF_EVENT_CATEGORY', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },
  async getListOfEventCriticity ({ commit }) {
    try {
      const c = await Utility.getListOfEventCriticity()
      await commit('SET_LISTOF_EVENT_CRITICITY', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },
  async getListOfAccessRights ({ commit }) {
    try {
      const c = await Utility.getListOfAccessRights()
      await commit('SET_LISTOF_EVENT_CRITICITY', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },
  async getListOfLanguages ({ commit }) {
    try {
      const c = await Utility.getListOfLanguages()
      await commit('SET_LISTOF_LANGUAGES', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },
  async getListOfLanguagesIso ({ commit }) {
    try {
      const c = await Utility.getListOfLanguagesIso()
      await commit('SET_LISTOF_LANGUAGES_ISO', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },
  async getListOfIsoTopicCategory ({ commit }) {
    try {
      const c = await Utility.getListOfIsoTopicCategory()
      await commit('SET_LISTOF_ISO_TOPIC_CATEGORY', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },
  async getListOfGeonetworkRights ({ commit }) {
    try {
      const c = await Utility.getListOfGeonetworkRights()
      await commit('SET_LISTOF_GEONETWORK_RIGHTS', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },
  async getListOfGeonetworkTypeOfSystem ({ commit }) {
    try {
      const c = await Utility.getListOfGeonetworkTypeOfSystem()
      await commit('SET_LISTOF_GEONETWORK_TYPE_SYSTEM', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
