<template>
  <div>
    <el-popover
      placement="left"
      width="400"
      trigger="click"
      v-if="element.events_templates.length || element.clones !== undefined && element.clones.length">
      <div class="observingsystem__choose_event">
        <div class="observingsystem__event_select_clone_used" v-if="element.clones.length">
          <h3>{{ $t('Choose the clone used') }}</h3>
          <div class="observingsystem__choose_event__list">
            <el-select v-model="sltClone" placeholder="Select">
              <el-option
                v-for="item in element.clones"
                :key="item.id"
                :label="item.label"
                :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="observingsystem__choose_event_template" v-show="showTemplates">
          <h3>{{ $t('Choose the model of event') }}</h3>
          <div class="observingsystem__choose_event__list">
            <span v-for="event_template in element.events_templates" :key="event_template.id">
              <router-link :to="{ name: 'settings.event.create', params: { observingsystem: sltClone, eventTemplate: event_template.id, trigger: isSubEvent?event.id:'' } }" class="button rounded" v-if="element.clones.length">
                {{ event_template.label }}
              </router-link>
              <router-link :to="{ name: 'settings.event.create', params: { observingsystem: element.id, eventTemplate: event_template.id, trigger: isSubEvent?event.id:'' } }" class="button rounded" v-else>
                {{ event_template.label }}
              </router-link>
            </span>
            <router-link :to="{ name: 'settings.event.create', params: { observingsystem: sltClone, trigger: isSubEvent?event.id:'' } }" class="button rounded" v-if="element.clones.length">
              {{ $t('Basic model') }}
            </router-link>
            <router-link :to="{ name: 'settings.event.create', params: { observingsystem: element.id, trigger: isSubEvent?event.id:'' } }" class="button rounded" v-else>
              {{ $t('Basic model') }}
            </router-link>
          </div>
        </div>
      </div>
      <b-button slot="reference" class="button--add-event">{{ label }}</b-button>
    </el-popover>
    <router-link :to="{ name: 'settings.event.create', params: { observingsystem: element.id, trigger: isSubEvent?event.id:'' } }" class="button rounded button--add-event" :class="size" v-else>
      {{ label }}
    </router-link>
  </div>
</template>

<script>
import Vuex from 'vuex'

export default {
  name: 'ButtonEvent',
  props: {
    element: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    size: {
      type: String,
      required: false
    },
    isSubEvent: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      sltClone: '',
      showTemplates: false
    }
  },
  computed: {
    ...Vuex.mapGetters(['event'])
  },
  methods: {
    init: function () {
      if(!this.element.clones.length)
        this.showTemplates = true
    },
  },
  watch: {
    sltClone: function () {
      this.showTemplates = true
    },
  },
  mounted: function () {
    this.init()
  },
}
</script>