import { ToastProgrammatic as Toast } from 'buefy'
import Organization from '@/store/api/organization'
import i18n from '@/i18n'

// state
const state = {
  organizations: {},
  organization: {},
  organizationDetail: {}
}

// getters
const getters = {
  organizations: state => state.organizations,
  org: state => state.organization,
  organizationDetail: state => state.organizationDetail,
}

// mutations
const mutations = {
  SET_ORGANIZATIONS: function (state, data) {
    state.organizations = data
  },
  SET_ORGANIZATION: function (state, data) {
    state.organization = data
  },
  SET_ORGANIZATION_DETAIL: function (state, data) {
    state.organizationDetail = data
  },
  INIT_ORGANIZATION: function (state) {
    state.organization = {
      'name' : null,
      'label' : null,
      'image' : null,
      'website' : null
    }
  }
}

// actions: method for CRUD actions
const actions = {

  async createOrganization ({ commit }, form) {
    try {
      const ins = await Organization.create(form)
      commit('SET_ORGANIZATION', ins.data)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
      return ins.data
    } catch (e) {
      commit('SET_ORGANIZATION', null)
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
      return false
    }
  },

  async editOrganization ({ commit }, form) {
    try {
      const ins = await Organization.update(form['id'], form)
      commit('SET_ORGANIZATION', ins.data)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
    }
  },

  async getOrganizations ({ commit }, data) {
    try {
      const c = await Organization.getList(data)
      await commit('SET_ORGANIZATIONS', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getOrganization ({ commit }, id) {
    try {
      const c = await Organization.get(id)
      await commit('SET_ORGANIZATION', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getOrganizationDetail ({ commit }, id) {
    try {
      const c = await Organization.getDetail(id)
      await commit('SET_ORGANIZATION_DETAIL', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async initOrganization ({ commit }) {
    try {
      await commit('INIT_ORGANIZATION')
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

}

export default {
  state,
  getters,
  mutations,
  actions
}
