<template>
  <div class="quicklook">
    <section class="section-quicklook">
      <div class="tile is-ancestor">
        <div class="tile is-parent is-vertical">
          <div class="tile is-vertical box">
            <p class="title">{{ $t('Quicklooks') }}</p>
            <section class="quicklook-datepicker">
              <el-date-picker
                v-model="calendar"
                type="date"
                format="dd/MM/yyyy"
                value-format="yyyy-MM-dd"
                :placeholder="$t('Select a date')">
              </el-date-picker>
            </section>
            <section class="quicklook-title">
              <h3>{{ title }}</h3>
            </section>
            <section class="list-element quicklooks columns">
              <div v-for="quicklook in visudataQuicklooks" :key="quicklook.id" class="column is-one-third">
                <div class="card-image">
                  <div class="datafile_img">
                    <el-image :src="file_url + quicklook.id">
                      <div slot="placeholder" class="image-slot">
                        {{ $t('Loading') }}<span class="dot">...</span>
                      </div>
                    </el-image>
                  </div>
                </div>
                <div class="card-content">
                  <div class="media">
                    <div class="media-left">
                    </div>
                    <div class="media-content">
                      <p class="title is-4">{{ quicklook.calendar__production_version__product__data_collection__name }}</p>
                      <p class="subtitle is-6">{{ quicklook.calendar__date_release }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!visudataQuicklooks.length">
                <e-no-data/>
              </div>
            </section> 
          </div>
        </div>
      </div>       
    </section>
  </div>
</template>

<script>
import Vuex from 'vuex'
import conf from '@/conf'
import { store } from '@/store'
import i18n from '@/i18n'
import NoData from '@/components/NoData.vue'

export default {
  name: 'Dataprod',
  components: {
    'e-no-data': NoData
  },
  data () {
    return {
      title: i18n.t('Here are the latest quicklooks by data collections'),
      obs: this.$route.params.obs,
      calendar: '',
      file_url: conf.API_URL + conf.FILE_URL,
    }
  },
  computed: {
    ...Vuex.mapGetters(['user', 'visudataQuicklooks', 'observatory'])
  },
  methods: {
    ...Vuex.mapActions({
      initObservatory: 'initObservatory',
      initVisudataQuicklooks: 'initVisudataQuicklooks',
      getObservatoryByName: 'getObservatoryByName',
      getVisudataQuicklooks: 'getVisudataQuicklooks'
    }),
    init: function () {
      var data = {}
      if (this.$route.name == 'observatory.visuData') {
        data = {
          obs_n : this.obs
        }
      }
      this.initVisudataQuicklooks(data)
    },
    loadQuicklooks: function () {
      if(this.calendar) {
        this.title = i18n.t('Here are the quicklooks of ') + this.calendar
      } else {
        this.title = i18n.t('Here are the latest quicklooks by data collections')
      }
      
      var visudata = {}
      if (this.obs) {
        visudata = {
          obs: this.observatory.id,
          calendar: this.calendar
        }
      } else {
        visudata = {
          obs: null,
          calendar: this.calendar
        }
      }
      this.getVisudataQuicklooks(visudata)
    }
  },

  watch: {
    calendar: function () {
      this.loadQuicklooks()
    }
  },

  beforeRouteEnter (to, from, next) {
    if (this.$route.name == 'observatory.visuData') {
      store.dispatch('getObservatoryByName', to.params.obs).then(next())
    } else {
      store.dispatch('initObservatory').then(next())
    }
  },

  beforeRouteUpdate (to, from, next) {
    if (this.$route.name == 'observatory.visuData') {
      store.dispatch('getObservatoryByName', to.params.obs).then(next())
    } else {
      store.dispatch('initObservatory').then(next())
    }
  },

  mounted: function () {
    this.init()
  },
}
</script>
