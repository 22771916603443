<template>
  <e-loading v-if="isLoadingObservingsystem"></e-loading>
  <div class="page-view medium-center" v-else>
    <e-observingsystem-form isVersion="false" formType="create"/>
  </div>
</template>

<script>
import Vuex from 'vuex'
import { store } from '@/store'
import ObservingSystemForm from '@/views/observingsystem/Form.vue'
import Loading from '@/components/Loading'

export default {
  name: 'createObservingSystem',
  data () {
    return {
      observingsystemType: '',
    }
  },
  components: {
    'e-observingsystem-form': ObservingSystemForm,
    'e-loading': Loading
  },
  computed: {
    ...Vuex.mapGetters(['observingsystem', 'observatories', 'isLoadingObservingsystem'])
  },
  methods: {
    ...Vuex.mapActions({
      initObservingSystem: 'initObservingSystem',
      initObservingSystems: 'initObservingSystems',
      getObservatoriesAndCampaigns: 'getObservatoriesAndCampaigns',
      initStatus: 'initStatus'
    })
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('initLoadingObservingSystem', to.params.id).then(next())
  },
  mounted: function () {
    this.getObservatoriesAndCampaigns([])
    this.initObservingSystem(this.$route.params.observingsystemType).then(
      () => {}
    )
    this.initObservingSystems()
    this.initStatus()
  },
}
</script>