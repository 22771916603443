<template>
  <div class="account-register">
    <section class="hero is-fullheight">
      <div class="hero-body">
        <div class="hero-body">
          <div class="container">
            <div class="columns">
              <div class="column is-6 is-offset-3">
                <el-tabs v-model="activeName">
                  <el-tab-pane :label="$t('Sign in')" name="signIn">
                    <el-form :model="ruleForm" label-position="top" status-icon :rules="rules" ref="ruleForm">
                      <el-form-item prop="loginUsername">
                        <el-input type="text" v-model="ruleForm.loginUsername" auto-complete="off" :placeholder="$t('Username')" prefix-icon="el-icon-user"></el-input>
                      </el-form-item>
                      <el-form-item prop="loginPassword">
                        <el-input type="password" v-model="ruleForm.loginPassword" auto-complete="off" :placeholder="$t('Password')" prefix-icon="el-icon-lock" @keyup.enter.native="signin('ruleForm')"></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-button type="primary" @click="signin('ruleForm')" class="el-large-button right">{{ $t('Sign in') }}</el-button>
                      </el-form-item>
                    </el-form>
                  </el-tab-pane>
                  <!--
                  <el-tab-pane :label="$t('Register')" name="register" class="is-hidden">
                    <el-form :model="ruleForm" label-position="top" status-icon :rules="rules" ref="registerForm">
                      <el-form-item label="Prénom" prop="firstName">
                        <el-input type="text" v-model="ruleForm.firstName" auto-complete="off"></el-input>
                      </el-form-item>
                      <el-form-item label="Nom" prop="lastName">
                        <el-input type="text" v-model="ruleForm.lastName" auto-complete="off"></el-input>
                      </el-form-item>
                      <el-form-item label="Username" prop="username">
                        <el-input type="text" v-model="ruleForm.username" auto-complete="off"></el-input>
                      </el-form-item>
                      <el-form-item label="Email" prop="email">
                        <el-input type="email" v-model="ruleForm.email" auto-complete="off"></el-input>
                      </el-form-item>
                      <el-form-item label="Mot de passe" prop="password">
                        <el-input type="password" v-model="ruleForm.password" auto-complete="off"></el-input>
                      </el-form-item>
                      <el-form-item label="Confirmation du mot de passe" prop="confirmPassword">
                        <el-input type="password" v-model="ruleForm.confirmPassword" auto-complete="off" @keyup.enter.native="createAccount('registerForm')"></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-button type="primary" @click="createAccount('registerForm')" class="el-large-button right">{{ $t('Register') }}</el-button>
                      </el-form-item>
                    </el-form>
                  </el-tab-pane>
                  -->
                </el-tabs>
              </div>
            </div>
          </div>
        </div> 
      </div>
    </section>
  </div>
</template>
<script>
import Vuex from 'vuex'

export default {
  name: 'signIn',
  data () {
    var validatePassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Merci d\'indiquer votre mot de passe'))
      } else {
        if (value.match((/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z].{6,}$/))) {
          if (this.ruleForm.confirmPassword !== '') {
            this.$refs.ruleForm.validateField('confirmPassword')
          }
          callback()
        } else {
          callback(new Error('Votre mot de passe doit contenir au moins une majuscule, une minuscule, un nombre et avoir au moins 6 caratères'))
        }
      }
    }

    var validateConfirmPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Merci d\'indiquer à nouveau votre mot de passe'))
      } else if (value !== this.ruleForm.password) {
        callback(new Error('La confirmation n\'est pas identique au mot de passe'))
      } else {
        callback()
      }
    }

    return {
      activeName: 'signIn',
      ruleForm: {
        loginUsername: null,
        loginPassword: null,
        username: null,
        password: null,
        firstName: null,
        lastName: null,
        email: null,
      },
      rules: {
        loginUsername: [
          { required: true, message: 'Vous devez renseigner votre identifiant pour vous authentifier', trigger: 'blur' }
        ],
        loginPassword: [
          { required: true, message: 'Veuillez indiquer votre mot de passe', trigger: 'blur' }
        ],
        username: [
          { required: true, message: 'Vous devez renseigner votre username', trigger: 'blur' }
        ],
        name: [
          { type: 'string', required: true, message: 'Le nom de votre vitrine est requis', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'Vous devez renseigner votre email pour vous authentifier', trigger: 'blur' },
          { type: 'email', message: 'Votre email n’est pas valide', trigger: 'blur' }
        ],
        password: [
          { validator: validatePassword, trigger: 'blur' }
        ],
        confirmPassword: [
          { validator: validateConfirmPassword, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    ...Vuex.mapActions({
      login: 'login',
      register: 'register',
    }),
    signin (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = {
            'username': this.ruleForm.loginUsername,
            'password': this.ruleForm.loginPassword
          }
          this.login(data)
        } else {
          this.$buefy.toast.open({
            message: 'L\'identifiant ou le mot de passe n\'est pas valide',
            type: 'is-danger'
          })
          return false
        }
      })
    },
    createAccount (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = {
            'firstName': this.ruleForm.firstName,
            'lastName': this.ruleForm.lastName,
            'username': this.ruleForm.username,
            'email': this.ruleForm.email,
            'password': this.ruleForm.password,
          }
          this.register(data)
        } else {
          this.$buefy.toast.open({
            message: 'Impossible de créer votre compte. Merci de vérifier les données saisies.',
            type: 'is-danger'
          })
          return false
        }
      })
    },
  },

  mounted: function () {
    this.activeName = this.$route.name
  }
}
</script>
