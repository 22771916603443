import { ToastProgrammatic as Toast } from 'buefy'
import User from '@/store/api/user'
import i18n from '@/i18n'

// state
const state = {
  users: {},
  user: {
    authenticated: false,
    info: {
      id: null,
      username: null,
      first_name: null,
      last_name: null,
      email: null,
      avatar: null,
      is_superuser: false,
    }
  },
  avatar_changed: false,
  file: null
}

// getters
const getters = {
  users: state => state.users,
  user: state => state.user,
  authenticated: state => state.user.authenticated,
  info: state => state.user.info,
  id: state => state.user.info.id,
  username: state => state.user.info.username,
  firstName: state => state.user.info.first_name,
  lastName: state => state.user.info.last_name,
  email: state => state.user.info.email,
  avatar: state => state.user.info.avatar,
  function: state => state.user.info.function,
  organization: state => state.user.info.organization,
  phone: state => state.user.info.phone,
  address: state => state.user.info.adresse,
  city: state => state.user.info.city,
  state: state => state.user.info.state,
  country: state => state.user.info.country,
  file: state => state.file
}

// mutations
const mutations = {
  SET_USERS: function (state, data) {
    state.users = data
  },
  SET_USER_INFO: function (state, data) {
    state.user.info = data
  },
  SET_USER_AUTHENTICATED: function (state, data) {
    localStorage.setItem('authenticated', data)
    state.user.authenticated = data
  },
  SET_USERNAME: function (state, data) {
    state.user.info.username = data
  },
  SET_AVATAR: function (state, data) {
    state.file = data
    state.avatar_changed = true
  }
}

// actions to update user profile
const actions = {
  async getUsers ({ commit }, observingsystem) {
    try {
      const c = await User.getList(observingsystem)
      await commit('SET_USERS', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },
  async getUsersByObservingSystems ({ commit }, observingsystems) {
    try {
      const c = await User.getListByObservingSystems(observingsystems)
      await commit('SET_USERS', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },
  async updateProfile ({ commit }, form) {
    try {
      const userProfile = await User.updateUserInfo(form)
      commit('SET_USER_INFO', userProfile.data)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success',
      })
    } catch (e) {
      commit('SET_USER_INFO', [])
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
