<template>
  <e-loading v-if="isLoadingEvent"></e-loading>
  <div class="medium-center" v-else>
    <e-event-form formType="view"/>
  </div>
</template>

<script>
import Vuex from 'vuex'
import { store } from '@/store'
import EventForm from '@/views/event/Form.vue'
import Loading from '@/components/Loading'

export default {
  name: 'settingsEventCreate',
  components: {
    'e-event-form': EventForm,
    'e-loading': Loading
  },
  computed: {
    ...Vuex.mapGetters(['event', 'isLoadingEvent'])
  },
  methods: {
    ...Vuex.mapActions({
      getEvent: 'getEvent'
    })
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('initLoadingEvent')
    store.dispatch('getEvent', to.params.id).then(next())
  },
  beforeRouteUpdate (to, from, next) {
    store.dispatch('initLoadingEvent')
    store.dispatch('getEvent', to.params.id).then(next())
  }
}
</script>