<template>
  <div class="full-width">
    <div class="columns">
      <div class="column">
        <h1 class="title cap">
          <span v-if="title">{{ title }} - </span>
          <span v-if="formType=='create'">{{ $t('Create your template') }}</span>
          <span v-if="formType=='edit'">{{ $t('Edit your template') }}</span>
        </h1>
        <el-input
          :placeholder="$t('Template name')"
          v-model="label">
          <i slot="prefix" class="el-input__icon el-icon-edit"></i>
        </el-input>
        <br>
        <br>
        <codemirror v-model="code" :options="cmOptions" @input="onCmCodeChange"></codemirror>
        <br>
        <el-button @click="saveTemplate()">
          <span v-if="formType=='create'">{{ $t('Create template') }}</span>
          <span v-if="formType=='edit'">{{ $t('Update template') }}</span>
        </el-button>
      </div>
      <div class="column">
        <h1 class="title">
          {{ $t('Result') }}
        </h1>
        <FormulateForm
          :schema="formSchema"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'

const codemirrorExample = `
[
  {
    type: 'text',
    name: 'input_example',
    label: 'This is an input example',
    placeholder: 'Enter something...',
    validation: 'required'
  },
  {
    type: 'text',
    name: 'serial_number_example',
    label: 'What is the serial number for example ?',
    placeholder: 'Enter serial number...',
    help: 'A serial number is also known as SNID',
    validation: 'required'
  }
]`

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    elementType: {
      type: String,
      required: true
    },
    elementSubType: {
      type: String,
      required: false
    },
    formType: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    data: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      id: null,
      type: null,
      subtype: null,
      code: "",
      cmOptions: {
        // codemirror options
        tabSize: 2,
        mode: 'text/javascript',
        theme: 'paraiso-dark',
        lineNumbers: true,
        line: true,
      },
      formSchema: []
    }
  },
  computed: {
    ...Vuex.mapGetters(['template'])
  },
  methods: {
    ...Vuex.mapActions({
      createTemplate: 'createTemplate',
      editTemplate: 'editTemplate'
    }),
    init: function () {
      this.code = (this.formType == 'create' ? codemirrorExample : this.data)
      this.type = this.$route.params.type
      this.subtype = this.$route.params.subtype
      if (this.formType == 'edit') {
        this.id = this.$route.params.id
      }
    },
    onCmCodeChange(newCode) {
      let res = eval("(" + newCode + ")")
      this.formSchema = res
    },
    saveTemplate () {
      let data = {
        'label': this.label,
        'template': this.formSchema
      }
      if (this.formType == 'create') {
        data["type"] = this.type
        data["subtype"] = this.subtype
      }
      if (this.formType == 'edit') {
        data["id"] = this.id
      }
      this.formType == 'create' ? this.createTemplate(data) : this.editTemplate(data)
    }
  },

  mounted: function () {
    this.init()
  }
}
</script>
