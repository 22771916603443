import api from '@/store/api'

export default {
  get () {
    return api.get('scan/')
  },

  run (production_version) {
    var req = 'scan/run/'
    if(production_version)
      req = req + '?production_version=' + production_version
    return api.get(req)
  },

  cancel () {
    return api.get('scan/cancel/')
  }
}
