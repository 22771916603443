<template>
  <div class="full-width">
    <e-operationsheet-form :title="$t('Create Operation Sheet')" :subtitle="this.productionVersion.source" formType="create"/>
  </div>
</template>

<script>
import Vuex from 'vuex'
import OperationSheetForm from '@/components/form/OperationSheet'

export default {
  name: 'settingsOperationSheetCreate',
  components: {
    'e-operationsheet-form': OperationSheetForm,
  },
  computed: {
    ...Vuex.mapGetters(['operationSheetTemplate', 'productionVersion', 'productionVersions'])
  },
  methods: {
    ...Vuex.mapActions({
      initOperationSheet: 'initOperationSheet',
      getProductionVersions: 'getProductionVersions',
      getProductionVersion: 'getProductionVersion',
      getOperationSheetTemplate: 'getOperationSheetTemplate',
      createOperationSheet: 'createOperationSheet'
    }),
    init() {
      this.initOperationSheet(this.$store.getters.user.info.id)
      this.getProductionVersion(this.$route.params.production_version).then(
        () => {
          this.getOperationSheetTemplate(this.productionVersion.operationsheet_template)
        }
      )
    },
  },
  mounted: function () {
    this.init()
  }
}
</script>
