<template>
  <div class="dashboard">
    <section class="section-tasks">
      <div class="columns">
        <div class="column">
          <div class="tile is-parent is-vertical">
            <e-list-event :isDashboard="true" @displaySitesList="showSitesList = $event"></e-list-event>
            <e-list-site :isDashboard="true" v-if="showSitesList"></e-list-site>
          </div>
        </div>
        <div class="column">
          <div class="tile is-parent is-vertical">
            <e-list-instrument :isDashboard="true"></e-list-instrument>
            <e-list-platform :isDashboard="true"></e-list-platform>
            <e-list-software :isDashboard="true"></e-list-software>
          </div>
        </div>
      </div>      
    </section>
  </div>
</template>

<script>
import InstrumentList from '@/components/instrument/List'
import PlatformList from '@/components/platform/List'
import SoftwareList from '@/components/software/List'
import EventList from '@/components/event/List'
import SiteList from '@/components/site/List'

export default {
  name: 'Dashboard',
  components: {
    'e-list-instrument': InstrumentList,
    'e-list-platform': PlatformList,
    'e-list-software': SoftwareList,
    'e-list-event': EventList,
    'e-list-site': SiteList
  },
  data () {
    return {
      showSitesList: true,
    }
  },
}
</script>