<template>
  <div class="medium-center">
    <site-form formType="create" />
  </div>
</template>

<script>
import Vuex from 'vuex'
import SiteForm from './Form.vue'

export default {
  name: 'settingsSiteCreate',
  components: {
    'site-form': SiteForm,
  },
  methods: {
    ...Vuex.mapActions({
      initSite: 'initSite'
    })
  },
  mounted: function () {
    this.initSite()
  }
}
</script>