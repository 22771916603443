<template>
  <div class="e-avatar">
    <figure class="image">
      <div v-if="this.user.info.avatar">
        <img :src="this.user.info.thumb_avatar" class="avatar-logo-sm">
      </div>
      <div v-else class="avatar-letter">
        <div>{{ this.user.info.first_name.charAt(0).toUpperCase() + this.user.info.last_name.charAt(0).toUpperCase() }}</div>
      </div>
    </figure>
  </div>
</template>

<script>
import Vuex from 'vuex'

export default {
  name: 'e-avatar',
  computed: {
    ...Vuex.mapGetters(['user'])
  },
}
</script>
