import api from '@/store/api'

export default {
  get (id) {
    return api.get('observingsystem/discoveries/' + id + '/')
  },
  getDetail (id) {
    return api.get('observingsystem/discoveries/' + id + '/get_detail')
  },
  getEvents (form) {
    var req = 'observingsystem/discoveries/' + form['id'] + '/events/?'
    if(form['page'])
      req = req + '&page=' + form['page']
    return api.get(req)
  },
  getHistory (id) {
    return api.get('observingsystem/discoveries/' + id + '/history')
  },
  getVersion (id) {
    return api.get('observingsystem/discoveryVersions/' + id + '/')
  },
  getList (form) {
    var req = 'observingsystem/discoveries/?'
    if(form['page'])
      req = req + '&page=' + form['page']
    if(form['obs'])
      req = req + '&site__data_collections__observatory__name=' + form['obs']
    if(form['observingsystemType'])
      req = req + '&type_of_system=' + form['observingsystemType']
    return api.get(req)
  },
  getListFromUserConnected (form) {
    var req = 'observingsystem/discoveries/my_observingsystems/?'
    if(form['page'])
      req = req + '&page=' + form['page']
    if(form['event_template'])
      req = req + '&event_template=' + form['event_template']
    return api.get(req)
  },
  getVersionList (discovery) {
    var req = 'observingsystem/discoveryVersions/'
    if(discovery)
      req = req + '?discovery=' + discovery
    return api.get(req)
  },
  create (data) {
    return api.post('observingsystem/discoveries/', data)
  },
  update (id, data) {
    return api.patch('observingsystem/discoveries/' + id + '/', data)
  },
  getListStatus (type_of_system) {
    var req = 'observingsystem/status/?'
    if(type_of_system)
      req = req + 'type_of_system=' + type_of_system
    return api.get(req)
  },
  getListIdsIn (ids) {
    var req = 'search/observingsystems/?ids='
    let id_list = ''
    for (let id of ids)
      id_list += id + '__'
    req = req + id_list
    return api.get(req)
  },
  search (value) {
    // let offset = 0
    // offset = (page - 1) * limit
    return api.get('search/observingsystems/?name__contains=' + value )
    //return api.get('search/observingsystems/?name__contains=' + value + '&offset=' + offset + '&limit=' + limit)
  },
  exportEvents (id, show_sites_events, date_start, date_end, fields) {
    var req = 'observingsystem/discoveries/' + id + '/export_events/?'
    if(show_sites_events)
      req = req + '&show_sites_events=' + show_sites_events
    if(date_start)
      req = req + '&date_start=' + date_start
    if(date_end)
      req = req + '&date_end=' + date_end
    if(fields)
      req = req + '&fields=' + fields
    return api.get(req, { responseType: 'blob' })
  },
}
