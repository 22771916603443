<template>
  <div class="instruments full-width" :key="observingsystemType">
    <h1 class="title settings-title cap">
      {{ observingsystemType }}
    </h1>
    <div class="columns medium-center">
      <div class="column">
        <section>
          <b-table
            :data="observingsystems.results"
            backend-pagination
            :loading="loading"
            :paginated="isPaginated"
            :per-page="perPage"
            @page-change="onPageChange"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :default-sort-direction="defaultSortDirection"
            :total="total"
            default-sort="name"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page">
              <b-table-column field="id" :label="$t('ID')" width="40" sortable numeric v-slot="props">
                {{ props.row.id }}
              </b-table-column>

              <b-table-column field="name" :label="$t('Name')" sortable v-slot="props">
                {{ observingsystemType=='data_product' ? props.row.fullname : props.row.name }}
              </b-table-column>

              <b-table-column field="label" :label="$t('Label')" v-slot="props">
                {{ props.row.label }}
              </b-table-column>

              <b-table-column field="actions" :label="$t('Actions')" v-slot="props">
                <router-link :to="{ name: 'settings.observingsystem.edit', params: { observingsystemType: observingsystemType, id: props.row.id } }" class="button rounded is-primary">
                  <i class="el-icon-edit"></i>
                </router-link>
              </b-table-column>
          </b-table>
          <router-link :to="{name: 'settings.observingsystem.create', params: { observingsystemType: observingsystemType } }">
            <el-button icon="el-icon-plus" plain>{{ $t('Add') }}</el-button>
          </router-link>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'

export default {
  name: 'observingsystemList',
  data () {
    return {
      loading: false,
      observingsystemType: '',
      isPaginated: true,
      isPaginationSimple: false,
      defaultSortDirection: 'asc',
      currentPage: 1,
      page: 1,
      perPage: 10,
      total: 0,
      token: null,
      data: null,
      name: '',
      value: '',
      formSchema: '',
      id: '',
    }
  },
  computed: {
    ...Vuex.mapGetters(['user', 'observingsystems'])
  },
  methods: {
    ...Vuex.mapActions({
      getObservingSystems: 'getObservingSystems'
    }),
    loadAsyncData() {
      this.loading = true
      this.observingsystemType = this.$route.params.observingsystemType
      const data =  {
        'page': this.page,
        'observingsystemType': this.observingsystemType
      }
      this.getObservingSystems(data).then(
        () => {
          this.loading = false
          this.total = this.observingsystems.count
          this.data = this.observingsystems.results
        }
      )
    },
    onPageChange(page) {
      this.page = page
      this.loadAsyncData()
    },
  },
  mounted: function () {
    this.loadAsyncData()
  }
}
</script>
