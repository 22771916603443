import { ToastProgrammatic as Toast } from 'buefy'
import ProductionVersion from '@/store/api/productionVersion'
import router from '@/router'
import i18n from '@/i18n'

// state
const state = {
  myProductionVersions: {},
  productionVersions: {},
  productionVersion: {}
}

// getters
const getters = {
  myProductionVersions: state => state.myProductionVersions,
  productionVersions: state => state.productionVersions,
  productionVersion: state => state.productionVersion,
}

// mutations
const mutations = {
  SET_MY_PRODUCTION_VERSIONS: function (state, data) {
    state.myProductionVersions = data
  },
  SET_PRODUCTION_VERSIONS: function (state, data) {
    state.productionVersions = data
  },
  SET_PRODUCTION_VERSION: function (state, data) {
    state.productionVersion = data
  },
  INIT_PRODUCTION_VERSION: function (state) {
    state.productionVersion = {
      'product' : null,
      'product_version' : null,
      'name' : null,
      'label' : null,
      'version' : 0,
      'data_path' : null,
      'is_hidden' : false,
      'qualitycontrolsheet_template' : null,
      'is_qualitycontrolsheet' : false,
      'is_public_qualitycontrolsheet' : false,
      'qualitycontrolsheet_mode' : null,
      'qualitycontrolsheet_software' : null,
      'qualitycontrolsheet_protocol_document' : null,
      'is_operationsheet' : false,
      'is_mandatory_operationsheet' : false,
      'operationfile_template' : null,
      'software' : null,
      'access_level_initial' : 'private',
      'access_level_final' : null,
      'access_restriction_days' : 0,
      'record_format' : null
    }
  }
}

// actions: method for CRUD actions
const actions = {

  async createProductionVersion ({ commit }, form) {
    try {
      const ins = await ProductionVersion.create(form)
      commit('SET_PRODUCTION_VERSION', ins)
      // Reload productionVersion list
      /**const c = await ProductionVersion.getList()
      await commit('SET_PRODUCTION_VERSIONS', c.data)**/
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
      router.push({
        name: 'settings.dataprod.productionVersion.list'
      })
    } catch (e) {
      commit('SET_PRODUCTION_VERSION', null)
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
      return false
    }
  },

  async editProductionVersion ({ commit }, form) {
    try {
      const ins = await ProductionVersion.update(form['id'], form)
      commit('SET_PRODUCTION_VERSION', ins.data)
      // Reload productionVersion list
      /**const c = await ProductionVersion.getList()
      await commit('SET_PRODUCTION_VERSIONS', c.data)**/
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
      router.push({
        name: 'settings.dataprod.productionVersion.list'
      })
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
    }
  },

  async getProductionVersions ({ commit }, form) {
    try {
      const c = await ProductionVersion.getList(form)
      await commit('SET_PRODUCTION_VERSIONS', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getMyProductionVersions ({ commit }) {
    try {
      const c = await ProductionVersion.getListFromUserConnected()
      await commit('SET_MY_PRODUCTION_VERSIONS', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getProductionVersion ({ commit }, id) {
    try {
      const c = await ProductionVersion.get(id)
      await commit('SET_PRODUCTION_VERSION', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getProductionVersionDetail ({ commit }, id) {
    try {
      const c = await ProductionVersion.getDetail(id)
      await commit('SET_PRODUCTION_VERSION', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async initProductionVersion ({ commit }) {
    try {
      await commit('INIT_PRODUCTION_VERSION')
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async exportOperationSheets (form) {
    try {
      await ProductionVersion.exportOperationsSheets(form)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

}

export default {
  state,
  getters,
  mutations,
  actions
}
