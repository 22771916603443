<template>
  <div class="medium-center">
    <observatory-form formType="create" />
  </div>
</template>

<script>
import Vuex from 'vuex'
import ObservatoryForm from './Form.vue'

export default {
  name: 'settingsObservatoryCreate',
  components: {
    'observatory-form': ObservatoryForm,
  },
  methods: {
    ...Vuex.mapActions({
      initObservatory: 'initObservatory'
    })
  },
  mounted: function () {
    this.initObservatory()
  }
}
</script>