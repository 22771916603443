<template>
  <div class="e-keywords">
    <el-tag
      :key="keyword"
      v-for="keyword in dynamicKeywords"
      :closable="formType!='view'"
      :disable-transitions="true"
      @close="handleClose(keyword)">
      {{keyword}}
    </el-tag>
    <div class="keyword-new" v-if="formType!='view'">
      <el-input
        class="input-new-keyword"
        v-if="inputVisible"
        v-model="inputValue"
        ref="saveKeywordInput"
        size="mini"
        @keyup.enter.native="handleInputConfirm"
        @blur="handleInputConfirm"
      >
      </el-input>
      <el-button v-else class="button-new-keyword" size="small" @click="showInput">+ {{ $t('Add keyword') }}</el-button>
    </div>
  </div>
</template>

<style>
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-keyword {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-keyword {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
  .keyword-new {
    display: inline-block;
  }
</style>

<script>
/* eslint-disable */
import Vuex from 'vuex'

export default {
  props: {
    formType: {
      type: String,
      required: true
    },
    isDisabled: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      keywords: [],
      inputVisible: false,
      inputValue: ''
    };
  },
  computed: {
    ...Vuex.mapGetters(['observingsystem']),
    dynamicKeywords: function () {
      // `this` points to the vm instance
      if(this.observingsystem.keywords !== null) {
        return this.observingsystem.keywords.split(',')
      } else {
        return this.keywords
      }
    }
  },
  methods: {
    handleClose(keyword) {
      this.dynamicKeywords.splice(this.dynamicKeywords.indexOf(keyword), 1)
      this.observingsystem.keywords = this.dynamicKeywords.join(", ")
    },

    showInput() {
      this.inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveKeywordInput.$refs.input.focus()
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue
      if (inputValue) {
        this.dynamicKeywords.push(inputValue)
        this.observingsystem.keywords = this.dynamicKeywords.join(", ")
      }
      this.inputVisible = false
      this.inputValue = ''
    }
  }
}
</script>