import { ToastProgrammatic as Toast } from 'buefy'
import Network from '@/store/api/network'
import router from '@/router'
import i18n from '@/i18n'

// state
const state = {
  networks: {},
  network: {}
}

// getters
const getters = {
  networks: state => state.networks,
  network: state => state.network,
}

// mutations
const mutations = {
  SET_NETWORKS: function (state, data) {
    state.networks = data
  },
  SET_NETWORK: function (state, data) {
    state.network = data
  },
  INIT_NETWORK: function (state) {
    state.network = {
      'name' : null,
      'label' : null,
      'current_status' : null,
      'pi' : null,
      'comment' : null
    }
  }
}

// actions: method for CRUD actions
const actions = {

  async createNetwork ({ commit }, form) {
    try {
      const instru = await Network.create(form)
      commit('SET_NETWORK', instru)
      // Reload networks list
      const c = await Network.getList()
      await commit('SET_NETWORKS', c.data)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
      router.push({
        name: 'settings.network.list'
      })
    } catch (e) {
      commit('SET_NETWORK', null)
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
      return false
    }
  },

  async editNetwork ({ commit }, form) {
    try {
      const instru = await Network.update(form['id'], form)
      commit('SET_NETWORK', instru.data)
      // Reload networks list
      const c = await Network.getList()
      await commit('SET_NETWORKS', c.data)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
    }
  },

  async getNetworks ({ commit }, form) {
    try {
      const c = await Network.getList(form)
      await commit('SET_NETWORKS', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getNetwork ({ commit }, id) {
    try {
      const c = await Network.get(id)
      await commit('SET_NETWORK', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async initNetwork ({ commit }) {
    try {
      await commit('INIT_NETWORK')
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

}

export default {
  state,
  getters,
  mutations,
  actions
}
