import { ToastProgrammatic as Toast } from 'buefy'
import OperationSheet from '@/store/api/operationSheet'
import i18n from '@/i18n'

// state
const state = {
  operationSheets: {},
  operationSheet: {}
}

// getters
const getters = {
  operationSheets: state => state.operationSheets,
  operationSheet: state => state.operationSheet,
}

// mutations
const mutations = {
  SET_OPERATION_SHEETS: function (state, data) {
    state.operationSheets = data
  },
  SET_OPERATION_SHEET: function (state, data) {
    state.operationSheet = data
  },
  INIT_OPERATION_SHEET: function (state, id) {
    let now = new Date
    state.operationSheet = {
      description: '',
      creator: id,
      images: [],
      files: [],
      images_d: [],
      files_d: [],
      date_created: now,
      template: {},
      data: {}
    }
  }
}

// actions to update user profile
const actions = {
  
  async createOperationSheet ({ commit }, form) {
    try {
      const ope = await OperationSheet.create(form)
      commit('SET_OPERATION_SHEET', ope.data)
      // Update list operator files
      const c = await OperationSheet.getList()
      await commit('SET_OPERATION_SHEETS', c.data)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
    } catch (e) {
      commit('SET_OPERATION_SHEET', null)
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
    }
  },

  async editOperationSheet ({ commit }, form) {
    try {
      const ope = await OperationSheet.update(form['id'], form)
      commit('SET_OPERATION_SHEET', ope.data)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
    }
  },

  async getOperationSheets ({ commit }, form) {
    try {
      const c = await OperationSheet.getList(form['production_version'], form['date_release'])
      await commit('SET_OPERATION_SHEETS', c.data.results)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getOperationSheet ({ commit }, id) {
    try {
      const c = await OperationSheet.get(id)
      await commit('SET_OPERATION_SHEET', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async initOperationSheet ({ commit }, creator) {
    try {
      await commit('INIT_OPERATION_SHEET', creator)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

}

export default {
  state,
  getters,
  mutations,
  actions
}
