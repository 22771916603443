import api from '@/store/api'

export default {
  get (id) {
    return api.get('dataprod/productionVersions/' + id + '/')
  },
  getDetail (id) {
    return api.get('dataprod/productionVersions/' + id + '/get_detail')
  },
  getList (form) {
    var req = 'dataprod/productionVersions/?'
    if(form['page'])
      req = req + '&page=' + form['page']
    if(form['obs'])
      req = req + '&site__data_collections__observatory__name=' + form['obs']
    if(form['observingsystemType'])
      req = req + '&type_of_system=' + form['observingsystemType']
    return api.get(req)
  },
  getListFromUserConnected () {
    var req = 'dataprod/productionVersions/my_productionversions/'
    return api.get(req)
  },
  create (data) {
    return api.post('dataprod/productionVersions/', data)
  },
  update (id, data) {
    return api.patch('dataprod/productionVersions/' + id + '/', data)
  },
  exportOperationSheets (form) {
    var req = 'dataprod/productionVersions/' + form['id'] + '/export_operation_sheets/?'
    if(form['date_release_start_bs'])
      req = req + '&date_release_start_bs=' + form['date_release_start_bs']
    if(form['date_release_start_be'])
      req = req + '&date_release_start_be=' + form['date_release_start_be']
    if(form['date_release_end_bs'])
      req = req + '&date_release_end_bs=' + form['date_release_end_bs']
    if(form['date_release_end_be'])
      req = req + '&date_release_end_be=' + form['date_release_end_be']
    if(form['fields'])
      req = req + '&fields=' + form['fields']
    return api.get(req, { responseType: 'blob' })
  },
}