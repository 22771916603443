import { ToastProgrammatic as Toast } from 'buefy'
import Product from '@/store/api/product'
import router from '@/router'
import i18n from '@/i18n'

// state
const state = {
  products: {},
  product: {}
}

// getters
const getters = {
  products: state => state.products,
  product: state => state.product,
}

// mutations
const mutations = {
  SET_PRODUCTS: function (state, data) {
    state.products = data
  },
  SET_PRODUCT: function (state, data) {
    state.product = data
  },
  INIT_PRODUCT: function (state) {
    state.product = {
      'name' : null,
      'label' : null
    }
  }
}

// actions: method for CRUD actions
const actions = {

  async createProduct ({ commit }, form) {
    try {
      const ins = await Product.create(form)
      commit('SET_PRODUCT', ins)
      // Reload product list
      const c = await Product.getList()
      await commit('SET_PRODUCTS', c.data)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
      router.push({
        name: 'settings.dataprod.product.list'
      })
    } catch (e) {
      commit('SET_PRODUCT', null)
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
      return false
    }
  },

  async editProduct ({ commit }, form) {
    try {
      const ins = await Product.update(form['id'], form)
      commit('SET_PRODUCT', ins.data)
      // Reload product list
      const c = await Product.getList()
      await commit('SET_PRODUCTS', c.data)
      Toast.open({
        message: i18n.t('msg_ok_saved'),
        type: 'is-success'
      })
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_backup'),
        type: 'is-danger'
      })
    }
  },

  async getProducts ({ commit }, form) {
    try {
      const c = await Product.getList(form)
      await commit('SET_PRODUCTS', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async getProduct ({ commit }, id) {
    try {
      const c = await Product.get(id)
      await commit('SET_PRODUCT', c.data)
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

  async initProduct ({ commit }) {
    try {
      await commit('INIT_PRODUCT')
    } catch (e) {
      Toast.open({
        message: i18n.t('msg_problem_data_recovery'),
        type: 'is-danger'
      })
    }
  },

}

export default {
  state,
  getters,
  mutations,
  actions
}
