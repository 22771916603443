<template>
  <e-template :elementType="type" :elementSubType="subtype" formType="create" label="" data=""/>
</template>

<script>
import Template from '@/components/form/Template'

export default {
  name: 'createTemplate',
  components: {
    'e-template': Template,
  },
  mounted: function () {
    this.elementType = this.$route.params.type
    this.elementSubType = this.$route.params.subtype
  }
}
</script>
