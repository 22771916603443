<template>
  <div class="medium-center">
    <div class="e-panel-actions" v-if="!editMode">
      <div v-if="observingsystem.type_of_system=='data_collection'">
        <div v-if="datasource.type_of_system=='instrument'">
          <el-tooltip class="item" effect="dark" :content="$t('Edit discovery')" placement="top-start" v-if="instrument.users_can_update.includes(user.info.id)">
            <el-button class="el-button--xsmall" type="primary" icon="el-icon-edit" @click="setEditMode(true)" plain> {{ $t('Edit') }}</el-button>
          </el-tooltip>
        </div>
        <div v-else>
          <el-tooltip class="item" effect="dark" :content="$t('Edit discovery')" placement="top-start" v-if="platform.users_can_update.includes(user.info.id)">
            <el-button class="el-button--xsmall" type="primary" icon="el-icon-edit" @click="setEditMode(true)" plain> {{ $t('Edit') }}</el-button>
          </el-tooltip>
        </div>
      </div>
      <div v-else>
        <el-tooltip class="item" effect="dark" :content="$t('Edit discovery')" placement="top-start" v-if="observingsystem.users_can_update.includes(user.info.id)">
          <el-button class="el-button--xsmall" type="primary" icon="el-icon-edit" @click="setEditMode(true)" plain> {{ $t('Edit') }}</el-button>
        </el-tooltip>
      </div>
      <el-button class="el-button--xsmall" @click="showHistory = true" icon-left="history"> 
        <i class="mdi mdi-history"></i>
        {{ $t('History') }}
      </el-button>
    </div>
    <div class="avatar-logo-center">
      <el-upload
        class="avatar-logo-uploader img-uploader-big"
        ref="upload"
        :action="url"
        :headers="headers"
        :data="data"
        :before-upload="getFile"
        :http-request="upload"
        :show-file-list="true"
        :auto-upload="false"
        :on-success="handleThumbnailSuccess"
        v-if="editMode">
        <img v-if="this.observingsystem.thumbnail" :src="this.observingsystem.thumbnail_m" class="img-background">
        <i v-else class="el-icon-picture avatar-uploader-icon img-uploader-big"></i>
      </el-upload>
      <el-image v-else-if="this.observingsystem.thumbnail" :src="this.observingsystem.thumbnail_m" :fit="cover"></el-image>
    </div>
    <el-form :model="observingsystem" label-position="top" label-width="40%" ref="form" :rules="rules" :hide-required-asterisk="false" :disabled="!editMode">
      <el-form-item :label="$t('Name')" prop="name" v-if="editMode">
        <el-input v-model="observingsystem.name" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item :label="$t('Label')" prop="label" v-if="editMode">
        <el-input v-model="observingsystem.label" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item :label="$t('DOI')" prop="doi" v-if="editMode || observingsystem.doi">
        <el-input v-model="observingsystem.doi" auto-complete="off" v-if="editMode"></el-input>
        <div v-else> {{ observingsystem.doi }}</div>
      </el-form-item>
      <el-form-item :label="$t('Language')" prop="language" v-if="editMode || observingsystem.language">
        <el-radio-group v-model="observingsystem.language" v-if="editMode">
          <el-radio-button 
            v-for="item in listOfLanguagesIso" 
            :key="item[0]" 
            :value="item[0]" 
            :label="item[0]">
            {{ item[1] }}
          </el-radio-button>
        </el-radio-group>
        <div v-else> {{ observingsystemDetail.language }}</div>
      </el-form-item>
      <el-form-item :label="$t('Address')" prop="address" v-if="observingsystem.type_of_system=='site' && editMode || observingsystem.address">
        <el-input type="textarea" v-model="observingsystem.address" auto-complete="off" v-if="editMode"></el-input>
        <div v-else> {{ observingsystem.address }}</div>
      </el-form-item>
      <el-form-item :label="$t('Postcode')" prop="postcode" v-if="observingsystem.type_of_system=='site' && editMode || observingsystem.postcode">
        <el-input v-model="observingsystem.postcode" auto-complete="off" v-if="editMode"></el-input>
        <div v-else> {{ observingsystem.postcode }}</div>
      </el-form-item>
      <el-form-item :label="$t('City')" prop="city" v-if="observingsystem.type_of_system=='site' && editMode || observingsystem.city">
        <el-input v-model="observingsystem.city" auto-complete="off" v-if="editMode"></el-input>
        <div v-else> {{ observingsystem.city }}</div>
      </el-form-item>
      <el-form-item :label="$t('Country')" prop="country" v-if="observingsystem.type_of_system=='site' && editMode || observingsystem.country">
        <el-select v-model="observingsystem.country" placeholder="Select" filterable v-if="editMode">
          <el-option
            v-for="(value, index) in countries"
            :key="index"
            :label="value"
            :value="index">
          </el-option>
        </el-select>
        <div v-else> {{ countries[observingsystem.country] }}</div>
      </el-form-item>
      <!--<el-form-item :label="$t('Geometry')" prop="geom" v-if="observingsystem.type_of_system=='site' && editMode || observingsystem.geometry">
        <codemirror v-model="this.code" :options="cmOptions" @input="onCmCodeChange" v-if="editMode"></codemirror>
        <div v-else> {{ observingsystem.geometry }}</div>
      </el-form-item>-->
      <el-form-item :label="$t('Access link')" prop="access_link" v-if="observingsystem.type_of_system=='network'">
        <el-input type="url" v-model="observingsystem.access_link" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item :label="$t('Serial number')" prop="serial_number" v-if="observingsystem.type_of_system=='instrument' && editMode || observingsystem.serial_number">
        <el-input v-model="observingsystem.serial_number" auto-complete="off" v-if="editMode"></el-input>
        <div v-else> {{ observingsystem.serial_number }}</div>
      </el-form-item>
      <el-form-item :label="$t('Status')" prop="status" v-if="status.length && editMode ||  observingsystem.current_status">
        <el-radio-group v-model="observingsystem.current_status" v-if="editMode">
          <el-radio-button 
            v-for="s in status" 
            :key="s.id"
            :label="s.id"
            :value="s.id">
            {{ $t(s.label) }}
          </el-radio-button>
        </el-radio-group>
        <span class="tag" :style="{ backgroundColor: observingsystemDetail.current_status.color, color: '#1C1C1C' }" v-else> {{ $t(observingsystemDetail.current_status.label) }}</span>
      </el-form-item>
      <el-form-item :label="$t('Description')" prop="description" v-if="editMode || observingsystem.description">
        <e-tiptap type="observingsystem" :data="observingsystem.description" :isDisabled="formType=='view'?true:false" v-if="editMode"/>
        <div class="justify" v-html="observingsystem.description" v-else></div>    
      </el-form-item>
      <el-form-item :label="$t('ISO topic category')" prop="iso_topic_category" v-if="editMode || observingsystem.iso_topic_category">
        <el-select v-model="observingsystem.iso_topic_category" v-if="editMode">
          <el-option 
            v-for="item in listOfIsoTopicCategory" 
            :key="item[0]" 
            :value="item[0]" 
            :label="$t(item[0])">
            {{ $t(item[1]) }}
          </el-option>
        </el-select>
        <div v-else> {{ $t(observingsystemDetail.iso_topic_category) }}</div>
      </el-form-item>
      <el-form-item :label="$t('Geonetwork publication')" prop="is_geonetwork_publication" v-if="exportGeonetworkTypeList.includes(observingsystem.type_of_system)">
        <el-switch
          v-model="observingsystem.is_geonetwork_publication"
          :label="$t('Geonetwork publication')"
          :active-text="$t('Yes')"
          :inactive-text="$t('No')"
          ref="is_geonetwork_publication">
        </el-switch>
      </el-form-item>
      <el-form-item :label="$t('Geonetwork type of system')" prop="geonetwork_type_of_system" v-if="exportGeonetworkTypeList.includes(observingsystem.type_of_system) && editMode || observingsystem.geonetwork_type_of_system">
        <el-select v-model="observingsystem.geonetwork_type_of_system" v-if="editMode">
          <el-option
            v-for="item in listOfGeonetworkTypeOfSystem" 
            :key="item[0]" 
            :value="item[0]" 
            :label="$t(item[0])">
            {{ $t(item[0]) }}
          </el-option>
        </el-select>
        <div v-else> {{ observingsystemDetail.geonetwork_type_of_system }}</div>
      </el-form-item>
      <el-form-item :label="$t('Right use limitation')" prop="rights_use_limitation" v-if="exportGeonetworkTypeList.includes(observingsystem.type_of_system) && editMode || observingsystem.rights_use_limitation">
        <el-input type="textarea" v-model="observingsystem.rights_use_limitation" auto-complete="off" v-if="editMode"></el-input>
        <div v-else> {{ observingsystem.rights_use_limitation }}</div>
      </el-form-item>
      <el-form-item :label="$t('Rights access constraint')" prop="rights_access_constraint" v-if="exportGeonetworkTypeList.includes(observingsystem.type_of_system) && editMode || observingsystem.rights_access_constraint">
        <el-select v-model="observingsystem.rights_access_constraint" v-if="editMode">
          <el-option
            v-for="item in listOfGeonetworkRights" 
            :key="item[0]" 
            :value="item[0]" 
            :label="$t(item[0])">
            {{ $t(item[0]) }}
          </el-option>
        </el-select>
        <div v-else> {{ observingsystemDetail.rights_access_constraint }}</div>
      </el-form-item>
      <el-form-item :label="$t('Rights use contraint')" prop="rights_use_constraint" v-if="exportGeonetworkTypeList.includes(observingsystem.type_of_system) && editMode || observingsystem.rights_use_constraint">
        <el-select v-model="observingsystem.rights_use_constraint" v-if="editMode">
          <el-option
            v-for="item in listOfGeonetworkRights" 
            :key="item[0]" 
            :value="item[0]" 
            :label="$t(item[0])">
            {{ $t(item[0]) }}
          </el-option>
        </el-select>
        <div v-else> {{ observingsystemDetail.rights_use_constraint }}</div>
      </el-form-item>
      <el-form-item :label="$t('Right other constraint')" prop="rights_other_constraint" v-if="exportGeonetworkTypeList.includes(observingsystem.type_of_system) && editMode || observingsystem.rights_other_constraint">
        <el-input type="textarea" v-model="observingsystem.rights_other_constraint" auto-complete="off" v-if="editMode"></el-input>
        <div v-else> {{ observingsystem.rights_other_constraint }}</div>
      </el-form-item>
      <el-form-item :label="$t('Dissemination principles')" prop="dissemination_principles" v-if="['data_collection','data_product'].includes(observingsystem.type_of_system) &&  editMode || observingsystem.dissemination_principles">
        <el-input type="textarea" v-model="observingsystem.dissemination_principles" auto-complete="off" v-if="editMode"></el-input>
        <div class="justify" v-html=" observingsystemDetail.dissemination_principles" v-else></div> 
      </el-form-item>
      <div v-if="collectionTypeList.includes(observingsystem.type_of_system)">
        <el-form-item :label="$t('Hidden')" prop="is_hidden" >
          <el-switch
            v-model="observingsystem.is_hidden"
            :label="$t('Hidden')"
            :active-text="$t('Yes')"
            :inactive-text="$t('No')"
            ref="is_hidden">
          </el-switch>
        </el-form-item>
        <el-form-item :label="$t('Data storage strategy')" prop="data_storage_strategy" v-if="editMode || observingsystem.data_storage_strategy">
          <el-radio-group v-model="observingsystem.data_storage_strategy" v-if="editMode">
            <el-radio-button 
              v-for="item in listOfDataStorageStrategy" 
              :key="item[0]" 
              :value="item[0]" 
              :label="item[0]">
              {{ item[1] }}
            </el-radio-button>
          </el-radio-group>
          <div v-else> {{ observingsystemDetail.data_storage_strategie }}</div>
        </el-form-item>
      </div>
      <div v-if="observingsystem.type_of_system=='data_collection'">
        <el-form-item :label="$t('Datasource')" prop="data_source" v-if="editMode || observingsystemDetail.data_source" :rules="[
          { required: true, message: $t('msg_required_field'), trigger: 'blur' }
        ]">
          <el-select name="sltDatasource" v-model="observingsystem.data_source" @change="loadDatasource($event)" placeholder="Select" value-key="id" ref="sltDatasource" v-if="editMode">
            <el-option
              v-for="item in datasources"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
          <div v-else> {{ observingsystemDetail.data_source.label }} </div> 
        </el-form-item>
        <el-form-item :label="$t('Clone in use')" prop="clone_used" v-if="editMode || observingsystemDetail.instrument_clone">
          <el-select name="sltClones" v-model="observingsystem.instrument_clone" placeholder="Select" value-key="id" ref="sltClones" v-if="editMode">
            <el-option
              v-for="item in instrument.clones"
              :key="item.id"
              :label="item.label"
              :value="item.id">
            </el-option>
          </el-select>
          <div v-else> {{ observingsystemDetail.instrument_clone.label }} </div>
        </el-form-item>
        <el-form-item :label="$t('Primary data center')" prop="primary_data_center" v-if="editMode || observingsystemDetail.primary_data_center">
          <el-select name="sltNetworks" v-model="observingsystem.primary_data_center" placeholder="Select" value-key="id" ref="sltNetworks" v-if="editMode">
            <el-option
              v-for="item in networks"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
          <div v-else> {{ observingsystemDetail.primary_data_center.name }}</div>
        </el-form-item>
        <el-form-item :label="$t('Other data centers')" prop="other_data_centers" v-if="editMode || observingsystemDetail.other_data_centers">
          <el-select name="sltOtherDatacenters" v-model="observingsystem.other_data_centers" placeholder="Select" value-key="id" ref="sltOtherDatacenters" multiple v-if="editMode || formType=='view' && observingsystemDetail.other_data_centers">
            <el-option
              v-for="item in networks"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
          <ul id="networks" v-else>
            <li v-for="item in observingsystemDetail.other_data_centers" :key="item.id">
              {{ item.name }}
            </li>
          </ul>
        </el-form-item>
      </div>
      <div v-if="observingsystem.type_of_system=='data_product'">
        <el-form-item :label="$t('Collection')" prop="data_collection" :rules="[
          { required: true, message: $t('msg_required_field'), trigger: 'blur' }
        ]">
          <el-select name="sltCollection" v-model="observingsystem.data_collection" placeholder="Select" value-key="id" ref="sltCollection">
            <el-option
              v-for="item in collections"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('Product category')" prop="product_category" >
          <el-radio-group v-model="observingsystem.category">
            <el-radio-button 
              v-for="item in listOfProductCategory" 
              :key="item[0]" 
              :value="item[0]" 
              :label="item[0]">
              {{ item[1] }}
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('Processing level')" prop="processing_level" :rules="[
          { required: true, message: $t('msg_required_field'), trigger: 'blur' }
        ]">
          <el-radio-group v-model="observingsystem.processing_level">
            <el-radio-button 
              v-for="item in processingLevels" 
              :key="item.id" 
              :value="item.id" 
              :label="item.id">
              {{ item.name }}
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('Primary data center')" prop="primary_data_center">
          <el-select name="sltNetworks" v-model="observingsystem.primary_data_center" placeholder="Select" value-key="id" ref="sltNetworks">
            <el-option
              v-for="item in networks"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('Other data centers')" prop="other_data_centers">
          <el-select name="sltOtherDatacenters" v-model="observingsystem.other_data_centers" placeholder="Select" value-key="id" ref="sltOtherDatacenters" multiple>
            <el-option
              v-for="item in networks"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div v-if="observingsystem.type_of_system!='data_collection'">
        <el-form-item :label="$t('Current observatory')" prop="current_observatory" v-if="editMode || observingsystem.current_observatory">
          <el-select name="sltCurrentObservatory" v-model="observingsystem.current_observatory" placeholder="Select" value-key="id" ref="sltCurrentObservatory" filterable v-if="editMode">
            <el-option
              v-for="item in observatoriesAndCampaigns"
              :key="item.id"
              :label="item.label"
              :value="item.id">
            </el-option>
          </el-select>
          <div v-else> 
            <router-link :to="{ name: 'observatory.visuData', params: { obs: observingsystemDetail.current_observatory.name } }">
              {{ observingsystemDetail.current_observatory.label }}
            </router-link>
          </div>
        </el-form-item>
        <div v-if="observingsystem.type_of_system!='site'">
          <el-form-item :label="$t('Current site')" prop="current_site" v-if="editMode || observingsystem.current_site">
            <el-select name="sltCurrentSite" v-model="observingsystem.current_site" placeholder="Select" value-key="id" ref="sltCurrentSite" filterable v-if="editMode">
              <el-option
                v-for="item in sites"
                :key="item.id"
                :label="item.label"
                :value="item.id">
              </el-option>
            </el-select>
            <div v-else>
              <router-link :to="{ name: 'observingsystem.view', params: { observingsystemType: 'site', id: observingsystemDetail.current_site.id } }">
                {{ observingsystemDetail.current_site.label }}
              </router-link>
            </div>
          </el-form-item>
        </div>
        <el-form-item :label="$t('Parent site')" prop="parent_site" v-if="observingsystem.type_of_system=='site' && editMode || observingsystem.parent_site">
          <el-select name="sltSites" v-model="observingsystem.parent_site" placeholder="Select" value-key="id" ref="sltSites" v-if="editMode">
            <el-option
              v-for="item in sites"
              :key="item.id"
              :label="item.label"
              :value="item.id">
            </el-option>
          </el-select>
          <div v-else> 
            <router-link :to="{ name: 'observingsystem.view', params: { observingsystemType: 'site', id: observingsystemDetail.parent_site.id } }">
              {{ observingsystemDetail.parent_site.label }}
            </router-link>
          </div>
        </el-form-item>
      </div>
      <div v-else>
        <el-form-item :label="$t('Observatory')" prop="observatory" v-if="editMode || observingsystem.observatory">
          <el-select name="sltObservatory" v-model="observingsystem.observatory" placeholder="Select" value-key="id" ref="sltObservatory" filterable v-if="editMode">
            <el-option
              v-for="item in observatoriesAndCampaigns"
              :key="item.id"
              :label="item.label"
              :value="item.id">
            </el-option>
          </el-select>
          <div v-else> 
            <router-link :to="{ name: 'observingsystem.view', params: { observingsystemType: 'observatory', id: observingsystemDetail.observatory.id } }">
              {{ observingsystemDetail.observatory.label }}
            </router-link>
          </div>
        </el-form-item>
        <el-form-item :label="$t('Associated observatories')" prop="associated-observatoires">
          <el-select name="sltObservatories" v-model="observingsystem.associated_observatories" multiple placeholder="Select" value-key="id" ref="sltObservatories" v-if="editMode">
            <el-option
              v-for="item in observatoriesAndCampaigns"
              :key="item.id"
              :label="item.label"
              :value="item.id">
            </el-option>
          </el-select>
          <ul id="associated_observatories" v-else>
            <li v-for="item in observingsystemDetail.associated_observatories" :key="item.id">
              <router-link :to="{ name: 'observingsystem.view', params: { observingsystemType: 'observatory', id: item.id } }">
                {{ item.label }}
              </router-link>
            </li>
          </ul>
        </el-form-item>
        <el-form-item :label="$t('Site')" prop="site" v-if="editMode || observingsystem.site" :rules="[
          { required: true, message: $t('msg_required_field'), trigger: 'blur' }
        ]">
          <el-select name="sltSite" v-model="observingsystem.site" placeholder="Select" value-key="id" ref="sltSite" filterable v-if="editMode">
            <el-option
              v-for="item in sites"
              :key="item.id"
              :label="item.label"
              :value="item.id">
            </el-option>
          </el-select>
          <div v-else> 
            <router-link :to="{ name: 'observingsystem.view', params: { observingsystemType: 'site', id: observingsystemDetail.site.id } }">
              {{ observingsystemDetail.site.label }} 
            </router-link>
          </div>
        </el-form-item>
      </div>
      <div v-if="exportGeonetworkTypeList.includes(observingsystem.type_of_system)">
        <el-form-item :label="$t('Date start')" prop="date_start" v-if="editMode || observingsystem.date_start" :rules="[
          { required: true, message: $t('msg_required_field'), trigger: 'blur' }
        ]">
          <el-date-picker 
            v-model="observingsystem.date_start" 
            type="datetime"
            format="dd/MM/yyyy HH:mm"
            :placeholder="$t('Select a date')"
            default-time="08:00:00">
          </el-date-picker>
          {{ this.observingsystem.date_start ? ' (' + new Date(this.observingsystem.date_start).toUTCString() + ') ' : ''}}
        </el-form-item>
      </div>
      <div v-else>
        <el-form-item :label="$t('Date start')" prop="date_start" v-if="editMode || observingsystem.date_start">
          <el-date-picker 
            v-model="observingsystem.date_start" 
            type="datetime"
            format="dd/MM/yyyy HH:mm"
            :placeholder="$t('Select a date')"
            default-time="08:00:00">
          </el-date-picker>
          {{ this.observingsystem.date_start ? ' (' + new Date(this.observingsystem.date_start).toUTCString() + ') ' : ''}}
        </el-form-item>
      </div>
      <el-form-item :label="$t('Date end')" prop="date_end" v-if="editMode || observingsystem.date_end">
        <el-date-picker 
          v-model="observingsystem.date_end" 
          type="datetime"
          format="dd/MM/yyyy HH:mm"
          :placeholder="$t('Select a date')"
          default-time="08:00:00">
        </el-date-picker>
        {{ this.observingsystem.date_end ? ' (' + new Date(this.observingsystem.date_end).toUTCString() + ') ' : ''}}
      </el-form-item>
      <el-form-item :label="$t('Modification date')" prop="date_modified" v-if="editMode || observingsystem.date_modified">
        <el-date-picker 
          v-model="observingsystem.date_modified" 
          type="datetime"
          format="dd/MM/yyyy HH:mm"
          :placeholder="$t('Select a date')"
          default-time="08:00:00">
        </el-date-picker>
        {{ this.observingsystem.date_modified ? ' (' + new Date(this.observingsystem.date_modified).toUTCString() + ') ' : ''}}
      </el-form-item>
      <el-form-item :label="$t('PI')" prop="pi" :rules="[
          { required: true, message: $t('msg_required_field'), trigger: 'blur' }
        ]" v-if="editMode || observingsystem.pi" >
        <el-select name="sltPI" v-model="observingsystem.pi" placeholder="Select" value-key="id" ref="sltPI" filterable v-if="editMode">
          <el-option
            v-for="item in users"
            :key="item.id"
            :label="item.full_name"
            :value="item.id">
          </el-option>
        </el-select>
        <a :href="'mailto:' + observingsystemDetail.pi.email" v-else> {{ observingsystemDetail.pi.first_name + " " + observingsystemDetail.pi.last_name }} {{ observingsystemDetail.pi.organization ? "(" + observingsystemDetail.pi.organization.label + ")" : "" }}</a>
      </el-form-item>
      <el-form-item :label="$t('Co-Investigators')" prop="co_investigators" v-if="editMode || observingsystem.co_investigators.length != 0">
        <el-select name="sltCI" v-model="observingsystem.co_investigators" placeholder="Select" value-key="id" ref="sltCI" multiple filterable v-if="editMode">
          <el-option
            v-for="item in users"
            :key="item.id"
            :label="item.full_name"
            :value="item.id">
          </el-option>
        </el-select>
        <ul id="co_investigators" v-else>
          <li v-for="item in observingsystemDetail.co_investigators" :key="item.id">
            <a :href="'mailto:' + item.email"> {{ item.first_name + " " + item.last_name }} {{ item.organization ? "(" + item.organization.label + ")" : "" }} </a>
          </li>
        </ul>
      </el-form-item>
      <el-form-item :label="$t('Collaborators')" prop="collaborators" v-if="editMode || observingsystem.collaborators.length != 0">
        <el-select name="sltCollaborators" v-model="observingsystem.collaborators" placeholder="Select" value-key="id" ref="sltCollaborators" multiple filterable v-if="editMode">
          <el-option
            v-for="item in users"
            :key="item.id"
            :label="item.full_name"
            :value="item.id">
          </el-option>
        </el-select>
        <ul id="collaborators" v-else>
          <li v-for="item in observingsystemDetail.collaborators" :key="item.id">
            <a :href="'mailto:' + item.email"> {{ item.first_name + " " + item.last_name  }} {{ item.organization ? "(" + item.organization.label + ")" : "" }} </a>
          </li>
        </ul>
      </el-form-item>
      <el-form-item :label="$t('Experts')" prop="experts" v-if="editMode || observingsystem.experts.length != 0">
        <el-select name="sltExperts" v-model="observingsystem.experts" placeholder="Select" value-key="id" ref="sltExperts" multiple filterable v-if="editMode">
          <el-option
            v-for="item in users"
            :key="item.id"
            :label="item.full_name"
            :value="item.id">
          </el-option>
        </el-select>
        <ul id="experts" v-else>
          <li v-for="item in observingsystemDetail.experts" :key="item.id">
            <a :href="'mailto:' + item.email"> {{ item.first_name + " " + item.last_name }} {{ item.organization ? "(" + item.organization.label + ")" : "" }} </a>
          </li>
        </ul>
      </el-form-item>
      <el-form-item :label="$t('Operators')" prop="operators" v-if="editMode || observingsystem.operators.length != 0">
        <el-select name="sltOperators" v-model="observingsystem.operators" placeholder="Select" value-key="id" ref="sltOperators" multiple filterable v-if="editMode">
          <el-option
            v-for="item in users"
            :key="item.id"
            :label="item.full_name"
            :value="item.id">
          </el-option>
        </el-select>
        <ul id="operators" v-else>
          <li v-for="item in observingsystemDetail.operators" :key="item.id">
            <a :href="'mailto:' + item.email"> {{ item.first_name + " " + item.last_name }} {{ item.organization ? "(" + item.organization.label + ")" : "" }} </a>
          </li>
        </ul>
      </el-form-item>
      <el-form-item :label="$t('Instrument manager')" prop="instrument_manager" v-if="editMode || observingsystem.instrument_manager">
        <el-select name="sltInstrumentManager" v-model="observingsystem.instrument_manager" placeholder="Select" value-key="id" ref="sltInstrumentManager" filterable v-if="editMode">
          <el-option
            v-for="item in users"
            :key="item.id"
            :label="item.full_name"
            :value="item.id">
          </el-option>
        </el-select>
        <a :href="'mailto:' + observingsystemDetail.instrument_manager.email" v-else> {{ observingsystemDetail.instrument_manager.first_name + " " + observingsystemDetail.instrument_manager.last_name }} {{ observingsystemDetail.instrument_manager.organization ? "(" + observingsystemDetail.instrument_manager.organization.label + ")" : "" }}</a>
      </el-form-item>
      <el-form-item :label="$t('Local instrument manager')" prop="local_instrument_manager" v-if="editMode || observingsystem.local_instrument_manager">
        <el-select name="sltLocalInstrumentManager" v-model="observingsystem.local_instrument_manager" placeholder="Select" value-key="id" ref="sltLocalInstrumentManager" filterable v-if="editMode">
          <el-option
            v-for="item in users"
            :key="item.id"
            :label="item.full_name"
            :value="item.id">
          </el-option>
        </el-select>
        <a :href="'mailto:' + observingsystemDetail.local_instrument_manager.email" v-else> {{ observingsystemDetail.local_instrument_manager.first_name + " " + observingsystemDetail.local_instrument_manager.last_name }} {{ observingsystemDetail.local_instrument_manager.organization ? "(" + observingsystemDetail.local_instrument_manager.organization.label + ")" : "" }} </a>
      </el-form-item>
      <el-form-item :label="$t('Data manager')" prop="data_manager" v-if="editMode || observingsystem.data_manager">
        <el-select name="sltDataManager" v-model="observingsystem.data_manager" placeholder="Select" value-key="id" ref="sltDataManager" filterable v-if="editMode">
          <el-option
            v-for="item in users"
            :key="item.id"
            :label="item.full_name"
            :value="item.id">
          </el-option>
        </el-select>
        <a :href="'mailto:' + observingsystemDetail.data_manager.email" v-else> {{ observingsystemDetail.data_manager.first_name + " " + observingsystemDetail.data_manager.last_name }} {{ observingsystemDetail.data_manager.organization ? "(" + observingsystemDetail.data_manager.organization.label + ")" : "" }}</a>
      </el-form-item>
      <el-form-item :label="$t('Local data manager')" prop="local_data_manager" v-if="editMode || observingsystem.local_data_manager">
        <el-select name="sltLocalDataManager" v-model="observingsystem.local_data_manager" placeholder="Select" value-key="id" ref="sltLocalDataManager" filterable v-if="editMode">
          <el-option
            v-for="item in users"
            :key="item.id"
            :label="item.full_name"
            :value="item.id">
          </el-option>
        </el-select>
        <a :href="'mailto:' + observingsystemDetail.local_data_manager.email" v-else> {{ observingsystemDetail.local_data_manager.first_name + " " + observingsystemDetail.local_data_manager.last_name }} {{ observingsystemDetail.local_data_manager.organization ? "(" + observingsystemDetail.local_data_manager.organization.label + ")" : "" }}</a>
      </el-form-item>
      <el-form-item :label="$t('Owners')" prop="owners" v-if="editMode || observingsystem.owners.length != 0">
        <el-select name="sltOwners" v-model="observingsystem.owners" placeholder="Select" value-key="id" ref="sltOwners" multiple filterable v-if="editMode">
          <el-option
            v-for="item in organizations"
            :key="item.id"
            :label="item.label"
            :value="item.id">
          </el-option>
        </el-select>
        <ul id="owners" v-else>
          <li v-for="item in observingsystemDetail.owners" :key="item.id">
            {{ item.label }}
          </li>
        </ul>
      </el-form-item>
      <el-form-item :label="$t('Publishers')" prop="publishers" v-if="editMode || observingsystem.publishers.length != 0">
        <el-select name="sltPublishers" v-model="observingsystem.publishers" placeholder="Select" value-key="id" ref="sltPublishers" multiple filterable v-if="editMode">
          <el-option
            v-for="item in organizations"
            :key="item.id"
            :label="item.label"
            :value="item.id">
          </el-option>
        </el-select>
        <ul id="publishers" v-else>
          <li v-for="item in observingsystemDetail.publishers" :key="item.id">
            {{ item.label }}
          </li>
        </ul>
      </el-form-item>
      <el-form-item :label="$t('Parent observing systems')" prop="parents_observingsystems" v-if="editMode || observingsystem.parents_discoveries.length != 0">
        <el-select
          v-model="observingsystem.parents_discoveries"
          multiple
          filterable
          remote
          reserve-keyword
          size="large"
          :placeholder="$t('Enter a keyword')"
          :remote-method="loadParentsDiscoveries"
          :loading="loading"
          v-if="editMode">
          <el-option
            v-for="item in parentsObservingsystems"
            :key="item.id"
            :label="item.label"
            :value="item.id">
          </el-option>
        </el-select>
        <div v-else>
          <ul>
            <li v-for="item in parentsObservingsystems" :key="item.id">
              <router-link  :key="item.id" :to="{ name: 'observingsystem.view', params: { observingsystemType: item.type_of_system, id: item.id } }">
                {{ item.label }}
              </router-link>
            </li>
          </ul>
        </div>
      </el-form-item>
      <el-form-item :label="$t('Associated observing systems')" prop="associated_observingsystems" v-if="editMode || observingsystem.associated_discoveries.length != 0">
        <el-select
          v-model="observingsystem.associated_discoveries"
          multiple
          filterable
          remote
          reserve-keyword
          size="large"
          :placeholder="$t('Enter a keyword')"
          :remote-method="loadAssociatedDiscoveries"
          :loading="loading"
          v-if="editMode">
          <el-option
            v-for="item in associatedObservingsystems"
            :key="item.id"
            :label="item.label"
            :value="item.id">
          </el-option>
        </el-select>
        <div v-else>
          <ul>
            <li v-for="item in associatedObservingsystems" :key="item.id">
              <router-link  :key="item.id" :to="{ name: 'observingsystem.view', params: { observingsystemType: item.type_of_system, id: item.id } }">
                {{ item.label }}
              </router-link>
            </li>
          </ul>
        </div>
      </el-form-item>
      <el-form-item :label="$t('Clones')" prop="clones" v-if="typeof observingsystem.clones != 'undefined' && observingsystem.clones.length">
        <ul class="clone-list">
          <li 
            v-for="clone in observingsystem.clones"
            :key="clone.id"
          >
           <router-link 
              :to="{ name: 'observingsystem.view', params: { observingsystemType: 'instrument', id: clone.id } }" 
              class="button rounded"
            >
              {{ clone.label }}
            </router-link>
          </li>
        </ul>
      </el-form-item>
      <el-button @click="loadInstrumentCloneForm(observingsystem.id)" round v-if="formType=='edit' && observingsystem.type_of_system=='instrument' && observingsystem.primary_instrument==null"> {{ $t('Create clone') }} </el-button>
      <el-form-item :label="$t('Keywords')" prop="keywords" v-if="editMode || this.observingsystem.keywords">
        <e-keywords :formType="formType"></e-keywords>
      </el-form-item>
      <el-form-item :label="$t('Events templates')" prop="events_templates" v-if="editMode">
        <el-select name="sltEventsTemplates" v-model="observingsystem.events_templates" placeholder="Select" value-key="id" ref="sltEventsTemplates" multiple filterable>
          <el-option
            v-for="item in eventTemplates"
            :key="item.id"
            :label="item.label"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('Latitude')" prop="latitude" v-if="editMode || observingsystem.latitude">
        <el-input-number v-model="observingsystem.latitude" auto-complete="off" controls-position="right" v-if="editMode"></el-input-number>
        <div v-else> {{ observingsystem.latitude }}</div>
      </el-form-item>
      <el-form-item :label="$t('Longitude')" prop="longitude" v-if="editMode || observingsystem.longitude">
        <el-input-number v-model="observingsystem.longitude" auto-complete="off" controls-position="right" v-if="editMode"></el-input-number>
        <div v-else> {{ observingsystem.longitude }}</div>
      </el-form-item>
      <el-form-item :label="$t('Altitude')" prop="altitude" v-if="editMode || observingsystem.altitude">
        <el-input-number v-model="observingsystem.altitude" auto-complete="off" controls-position="right" v-if="editMode"></el-input-number>
        <div v-else> {{ observingsystem.altitude }}</div>
      </el-form-item>
      <el-form-item :label="$t('Height')" prop="height" v-if="editMode || observingsystem.height">
        <el-input-number v-model="observingsystem.height" auto-complete="off" controls-position="right" v-if="editMode"></el-input-number>
        <div v-else> {{ observingsystem.height }}</div>
      </el-form-item>
      <el-form-item :label="$t('Geospatial vertical min')" prop="geospatial_vertical_min" v-if="editMode || observingsystem.geospatial_vertical_min">
        <el-input-number v-model="observingsystem.geospatial_vertical_min" auto-complete="off" controls-position="right" v-if="editMode"></el-input-number>
        <div v-else> {{ observingsystem.geospatial_vertical_min }}</div>
      </el-form-item>
      <el-form-item :label="$t('Geospatial vertical max')" prop="geospatial_vertical_max" v-if="editMode || observingsystem.geospatial_vertical_max">
        <el-input-number v-model="observingsystem.geospatial_vertical_max" auto-complete="off" controls-position="right" v-if="editMode"></el-input-number>
        <div v-else> {{ observingsystem.geospatial_vertical_max }}</div>
      </el-form-item>
      <el-form-item :label="$t('Geospatial latitude min')" prop="geospatial_latitude_min" v-if="editMode || observingsystem.geospatial_latitude_min">
        <el-input-number v-model="observingsystem.geospatial_latitude_min" auto-complete="off" controls-position="right" v-if="editMode"></el-input-number>
        <div v-else> {{ observingsystem.geospatial_latitude_min }}</div>
      </el-form-item>
      <el-form-item :label="$t('Geospatial latitude max')" prop="geospatial_latitude_max" v-if="editMode || observingsystem.geospatial_latitude_max">
        <el-input-number v-model="observingsystem.geospatial_latitude_max" auto-complete="off" controls-position="right" v-if="editMode"></el-input-number>
        <div v-else> {{ observingsystem.geospatial_latitude_max }}</div>
      </el-form-item>
      <el-form-item :label="$t('Geospatial longitude min')" prop="geospatial_longitude_min" v-if="editMode || observingsystem.geospatial_longitude_min">
        <el-input-number v-model="observingsystem.geospatial_longitude_min" auto-complete="off" controls-position="right" v-if="editMode"></el-input-number>
        <div v-else> {{ observingsystem.geospatial_longitude_min }}</div>
      </el-form-item>
      <el-form-item :label="$t('Geospatial longitude max')" prop="geospatial_longitude_max" v-if="editMode || observingsystem.geospatial_longitude_max">
        <el-input-number v-model="observingsystem.geospatial_longitude_max" auto-complete="off" controls-position="right" v-if="editMode"></el-input-number>
        <div v-else> {{ observingsystem.geospatial_longitude_max }}</div>
      </el-form-item>
      <el-form-item :label="$t('Geometry SRID')" prop="geometry_srid" v-if="editMode || observingsystem.geometry_srid">
        <el-input-number v-model="observingsystem.geometry_srid" :step="1" step-strictly auto-complete="off" controls-position="right" v-if="editMode"></el-input-number>
        <div v-else> {{ observingsystem.geometry_srid }}</div>
      </el-form-item>
      <el-form-item :label="$t('Geometry WKT')" prop="geom" v-if="editMode || observingsystem.geometry_wkt">
        <el-input type="textarea" v-model="geometry" auto-complete="off" @input="onCmGeometryChange" v-if="editMode"></el-input>
        <div v-else> {{ geometry }}</div>
      </el-form-item>
      <div class="e-map">
        <e-map :geojson="observingsystem.geometry_feature_collection" v-if="observingsystem.geometry_wkt && !editMode"/>
      </div>
      <FormulateForm
        v-model="observingsystem.data_format"
        name="formulate_data_format"
        :class="editMode?'':'disabled'"
        v-if="exportGeonetworkTypeList.includes(observingsystem.type_of_system)"
      >
        <FormulateInput
          name="inputs"
          type="group"
          :label="$t('Format')" 
          :repeatable="true"
          :add-label="$t('+ Add a format')"
        >
          <div class="align-inputs">
            <FormulateInput
              name="resource"
              validation="required"
              :label="$t('Resource')" 
            />
            <FormulateInput
              name="distribution"
              :label="$t('Distribution')" 
            />
          </div>
        </FormulateInput>
      </FormulateForm>
      <el-form-item :label="$t('Provenance statement')" prop="provenance_statement" v-if="editMode || observingsystem.provenance_statement">
        <el-input type="textarea" v-model="observingsystem.provenance_statement" auto-complete="off" v-if="editMode"></el-input>
        <div v-else> {{ observingsystem.provenance_statement }}</div>
      </el-form-item>
      <FormulateForm
        v-model="observingsystem.provenance_processes"
        name="formulate_provenance_processes"
        :class="editMode?'':'disabled'"
        v-if="exportGeonetworkTypeList.includes(observingsystem.type_of_system)"
      >
        <FormulateInput
          name="inputs"
          type="group"
          :label="$t('Processes')" 
          :repeatable="true"
          :add-label="$t('+ Add a process')"
        >
          <div class="align-inputs">
            <FormulateInput
              name="process_name"
              validation="required"
              :label="$t('Process name')" 
            />
            <FormulateInput
              name="process_description"
              validation="required"
              :label="$t('Process description')" 
            />
            <FormulateInput
              name="processor_email"
              :label="$t('Processor email')" 
            />
          </div>
        </FormulateInput>
      </FormulateForm>

      <FormulateForm
        v-model="observingsystem.references"
        name="formulate_references"
        :class="editMode?'':'disabled'"
        v-if="exportGeonetworkTypeList.includes(observingsystem.type_of_system)"
      >
        <FormulateInput
          name="inputs"
          type="group"
          :label="$t('References')" 
          :repeatable="true"
          :add-label="$t('+ Add a reference')"
        >
          <div class="align-inputs">
            <FormulateInput
              type="select"
              name="type"
              :label="$t('Type')" 
              :options="{http: 'http', wms: 'wms', wfs: 'wfs', other: $t('Other')}"
              :placeholder="$t('Type')"
            />
            <FormulateInput
              name="name"
              validation="required"
              :label="$t('Name')" 
            />
            <FormulateInput
              name="description"
              :label="$t('Description')" 
            />
            <FormulateInput
              name="linkage"
              :label="$t('Linkage')" 
              class="formulate-input-full"
            />
          </div>
        </FormulateInput>
      </FormulateForm>

      <FormulateForm
        v-model="observingsystem.data_storage"
        name="formulate_data_storage"
        :class="editMode?'':'disabled'"
        v-if="exportGeonetworkTypeList.includes(observingsystem.type_of_system)"
      >
        <FormulateInput
          name="inputs"
          type="group"
          :label="$t('Data storage')" 
          :repeatable="true"
          :add-label="$t('+ Add a data storage')"
          :minimum="0"
        >
          <FormulateInput
            type="select"
            name="access"
            :label="$t('Access')" 
            :placeholder="$t('Access')"
            :options="{'': '', default: $t('Default'), googledrive: 'Google Drive', zenodo: 'Zenodo', d4storagehub: 'D4Science.org', gbif: 'GBIF | Global Biodiversity Information Facility', thredds: 'Thredds Data Server'}"
          />
          <div class="align-inputs">
            <FormulateInput
              name="source"
              :label="$t('Source')"
              :help="$t('Name with extension of files to source')"
            />
            <FormulateInput
              type="select"
              name="sourceType"
              :label="$t('Source type')" 
              :placeholder="$t('Source type')"
              :options="{'': '', dbtable: $t('Database object'), dbquery: $t('Database query'), shp: $t('ESRI Shapefile (zipped)'), csv: $t('CSV table'), gpkg: $t('GeoPackage'), nc: $t('NetCDF4'), other: $t('Other')}"
            />
            <FormulateInput
              name="sourceSql"
              :label="$t('Source SQL')" 
              :help="$t('A plain SQL query to use as source')"
            />
            <FormulateInput
              name="sourceZip"
              type="checkbox"
              :label="$t('Source ZIP')" 
              :help="$t('A zipped version of data file(s) should be created from source files ?')"
            />
            <FormulateInput
              name="sourceZipOnly"
              type="checkbox"
              :label="$t('Source ZIP Only')" 
              :help="$t('A zipped version of data file(s) only should be created from source files ?')"
            />
          </div>
        
          <div class="align-inputs">
            <FormulateInput
              name="upload"
              type="checkbox"
              :label="$t('Upload')" 
              :help="$t('A zipped version of data file(s) only should be created from source files ?')"
            />
            <FormulateInput
              name="uploadSource"
              :label="$t('Upload Source')" 
              :help="$t('Name given to uploaded file. Caution, name must be without extension for a DB object, but must be a .zip file for a (zipped) ESRI shapefile. The file extension is mandatory for file upload (zip, shp, gpkg).')"
            />
            <FormulateInput
              type="select"
              name="uploadType"
              :label="$t('Upload type')" 
              :placeholder="$t('Upload type')"
              :options="{'': '', dbtable: $t('Database object'), dbquery: $t('Database query'), shp: $t('ESRI Shapefile (zipped)'), csv: $t('CSV table'), gpkg: $t('GeoPackage'), nc: $t('NetCDF4'), other: $t('Other')}"
            />
          </div>
          <div class="align-inputs">
            <FormulateInput
              name="workspaceName"
              :label="$t('Workspace Name')" 
              :help="$t('Name of target workspace to connect and upload to remote storage.')"
            />
            <FormulateInput
              type="select"
              name="workspaceType"
              :label="$t('Workspace Type')" 
              :placeholder="$t('Workspace Type')"
              :options="{'': '', geoserver: $t('Geoserver'), d4storagehub: $t('D4Science.org')}"
            />
          </div>
          <h4 class="form-title">Geoserver parameters</h4>
          <FormulateInput
            name="geoserverStyleUpload"
            type="checkbox"
            :label="$t('Style Upload')" 
            :help="$t('a specific boolean argument whether SLD style files (if listed/available in source) should be uploaded (specific to GeoServer publishing action)')"
          />
          <FormulateInput
            name="geoserverStyle"
            :label="$t('Style')" 
            :help="$t('Used as layer style name(s) for GeoServer action. When using multiple styles, the first one will be used as layer default style.')"
          />
          <FormulateInput
            name="geoserverStore"
            :label="$t('Store')" 
            :help="$t('Name of target store in the target GeoServer (can also be specified in json configuration)')"
          />
          <FormulateInput
            name="geoserverLayername"
            :label="$t('Layername')" 
            :help="$t('Name given to layer. If omitted, the entity id identifier will be used.')"
          />
        </FormulateInput>
      </FormulateForm>

      <el-form-item :label="$t('Comment')" prop="comment" v-if="editMode || observingsystem.comment">
        <el-input type="textarea" v-model="observingsystem.comment" auto-complete="off" v-if="editMode"></el-input>
        <div class="justify" v-html=" observingsystemDetail.comment" v-else></div> 
      </el-form-item>
      <el-form-item :label="$t('History')" prop="history" v-if="editMode || observingsystem.history">
        <el-input type="textarea" v-model="observingsystem.history" auto-complete="off" v-if="editMode"></el-input>
        <div class="justify" v-html=" observingsystemDetail.history" v-else></div> 
      </el-form-item>
      <el-form-item :label="$t('Template')" prop="template" v-if="(formType==='create' || formType==='edit') && !observingsystem.template">
        <el-select name="sltTemplate" v-model="observingsystem.template" placeholder="Select" value-key="id" ref="sltTemplate">
          <el-option
            v-for="item in templates"
            :key="item.id"
            :label="item.name"
            :value="item.template">
          </el-option>
        </el-select>
      </el-form-item> 
      <FormulateForm 
        name="formulate"
        :class="editMode?'':'disabled'"
        v-model="observingsystem.data"
        :schema="observingsystem.template"
        @submit="verify('form')"
      />
      <el-form-item class="lst-btn-end-form" v-if="editMode">
        <el-button @click.stop="setEditMode(false)" plain>
          <span>{{ $t('Cancel') }}</span>
        </el-button>
        <el-button @click.stop="save('form')" v-if="formType=='create'" type="primary">
          <span>{{ $t('Create') }}</span>
        </el-button>
        <el-popover
          placement="top"
          width="160"
          v-else>
          <p>{{ $t('Is this a major update?') }}</p>
          <div style="text-align: right; margin: 0">
            <el-button type="text" size="mini" @click="observingsystem.is_main_version = false; save('form')">{{ $t('No') }}</el-button>
            <el-button type="primary" size="mini" @click="observingsystem.is_main_version = true; save('form')">{{ $t('Yes') }}</el-button>
          </div>
          <el-button class="button-update" slot="reference" type="primary">{{ $t('Update') }}</el-button>
        </el-popover>
      </el-form-item>
    </el-form>
    <el-dialog
      :title="$t('Clone')"
      :visible.sync="showInstrumentCloneForm"
      width="80%"
      center>
      <e-instrument-clone-form formType="create"/>
    </el-dialog>
    <div class="history">
      <el-drawer
        :title="$t('History')"
        :visible.sync="showHistory"
        direction="rtl"
        >
        <div class="panel-history">
          <div class="block">
            <el-timeline :reverse="reverse" v-if="observingsystemHistory.length">
              <el-timeline-item
                v-for="(version, index) in observingsystemHistory"
                :key="index"
                :timestamp="version.date_created"
                :class="version.is_main_version?'timeline-main-version':'timeline-minor-version'">
                <el-button @click="loadObservingSystemForm(version.id)" round v-if="version.is_main_version"> {{version.commit_message}} </el-button>
                <el-tooltip class="item" effect="dark" :content="$t('Main version')" placement="top-start" v-if="version.is_main_version">
                  <b-icon class="timeline-bookmark" type="is-success" icon="mdi mdi-bookmark"></b-icon>
                </el-tooltip>
                <el-link @click="loadObservingSystemForm(version.id)" target="_blank" v-else> {{version.commit_message}}</el-link>
              </el-timeline-item>
            </el-timeline>
            <div v-if="!observingsystemHistory.length">
              <e-no-data/>
            </div>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'
import wellknown from 'wellknown'
import conf from '@/conf'
import i18n from '@/i18n'
import Keywords from '@/components/Keywords'
import Tiptap from '@/components/Tiptap'
import InstrumentCloneForm from '@/components/form/InstrumentClone'
import NoData from '@/components/NoData.vue'
import Map from '@/components/Map.vue'
import router from '@/router'

export default {
  name: 'formObservingSystem',
  components: {
    'e-keywords': Keywords,
    'e-tiptap': Tiptap,
    'e-instrument-clone-form': InstrumentCloneForm,
    'e-no-data': NoData,
    'e-map': Map,
  },
  props: {
    formType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      token: null,
      url: conf.API_URL + 'observingsystem/discoveries/',
      headers: {},
      code: "",
      geometry: "",
      reverse: false,
      editMode: false,
      data: {},
      showHistory: false,
      direction: 'rtl',
      showInstrumentCloneForm: false,
      observingsystemType: '',
      exportGeonetworkTypeList: ['campaign', 'data_collection', 'data_product', 'project'],
      collectionTypeList: ['data_collection', 'data_product', 'data_product_version'],
      collectionType: '',
      loading: false,
      file: null,
      image: '',
      imageUrl: '',
      rules: {
        name: [
          { required: true, message: i18n.t('msg_required_field'), trigger: 'blur' },
        ],
        label: [
          { required: true, message: i18n.t('msg_required_field'), trigger: 'blur' },
        ],
        access_link: [
          { type: 'url', required: false, message: i18n.t('msg_invalid_url'), trigger: 'change' }
        ]
      },
      cmOptions: {
        // codemirror options
        tabSize: 2,
        mode: 'text/javascript',
        theme: 'paraiso-dark',
        lineNumbers: true,
        line: true,
      },
    }
  },
  computed: {
    ...Vuex.mapGetters(['user', 'countries', 'organizations', 'observingsystem', 'observingsystemDetail', 'observingsystems', 'associatedObservingsystems', 'parentsObservingsystems', 'observingsystemHistory', 'datasources', 'datasource', 'instrument', 'platform', 'collection', 'observatories', 'observatoriesAndCampaigns', 'sites', 'users', 'templates', 'eventTemplates', 'status', 'collections', 'networks', 'listOfProductCategory', 'listOfDataStorageStrategy', 'processingLevels', 'listOfLanguagesIso', 'listOfIsoTopicCategory', 'listOfGeonetworkRights', 'listOfGeonetworkTypeOfSystem'])
  },
  methods: {
    ...Vuex.mapActions({
      searchObservingSystems: 'searchObservingSystems',
      searchParentsObservingSystems: 'searchParentsObservingSystems',
      searchAssociatedObservingSystems: 'searchAssociatedObservingSystems',
      initObservingSystem: 'initObservingSystem',
      initInstrument: 'initInstrument',
      initClone: 'initClone',
      setClone: 'setClone',
      getOrganizations: 'getOrganizations',
      getObservatoriesAndCampaigns: 'getObservatoriesAndCampaigns',
      getObservingSystemVersion: 'getObservingSystemVersion',
      createObservingSystem: 'createObservingSystem',
      editObservingSystem: 'editObservingSystem',
      createInstrument: 'createInstrument',
      editInstrument: 'editInstrument',
      createSoftware: 'createSoftware',
      editSoftware: 'editSoftware',
      createPlatform: 'createPlatform',
      editPlatform: 'editPlatform',
      createCollection: 'createCollection',
      editCollection: 'editCollection',
      createProduct: 'createProduct',
      editProduct: 'editProduct',
      createNetwork: 'createNetwork',
      editNetwork: 'editNetwork',
      getUsers: 'getUsers',
      getTemplates: 'getTemplates',
      getEventTemplates: 'getEventTemplates',
      getObservingSystem: 'getObservingSystem',
      getStatus: 'getStatus',
      getSites: 'getSites',
      getDatasource: 'getDatasource',
      getInstrument: 'getInstrument',
      getInstrumentDetail: 'getInstrumentDetail',
      getPlatform: 'getPlatform',
      getCollection: 'getCollection',
      getProcessingLevels: 'getProcessingLevels',
      getCollections: 'getCollections',
      getListOfProductCategory: 'getListOfProductCategory',
      getListOfDataStorageStrategy: 'getListOfDataStorageStrategy',
      getNetworks: 'getNetworks',
      getDatasources: 'getDatasources',
      getCountries: 'getCountries',
      getListOfLanguagesIso: 'getListOfLanguagesIso',
      getListOfIsoTopicCategory: 'getListOfIsoTopicCategory',
      getListOfGeonetworkRights: 'getListOfGeonetworkRights',
      getListOfGeonetworkTypeOfSystem: 'getListOfGeonetworkTypeOfSystem'
    }),
    handleThumbnailSuccess (res, file) {
      this.observingsystem.thumbnail = URL.createObjectURL(file.raw)
      this.observingsystem.thumbnail_m = URL.createObjectURL(file.raw)
      this.observingsystem.thumbnail_l = URL.createObjectURL(file.raw)
    },
    getFile (file) {
      const isJPGPNG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isJPGPNG) {
        this.$buefy.toast.open({
          message: this.$root.$t('image_must_be_jpg_png'),
          type: 'is-danger'
        })
      }
      if (!isLt5M) {
        this.$buefy.toast.open({
          message: this.$root.$t('image_too_heavy'),
          type: 'is-danger'
        })
      }
      return isJPGPNG && isLt5M
    },
    upload(req) {
      var formData = new FormData()
      this.url = this.url + this.observingsystem.id + '/'
      formData.append('thumbnail', req.file)
      const requestOptions = {
        method: 'PATCH',
        headers: this.headers,
        body: formData
      }
      return fetch(this.url, requestOptions)
    },
    setEditMode(edit) {
      this.editMode = edit
      this.formType = 'edit'
    },
    onCmCodeChange(newCode) {
      let res = eval("(" + newCode + ")")
      this.observingsystem.geometry_wkt = res
    },
    onCmGeometryChange(wktString) {
      if (!wktString.startsWith('GEOMETRYCOLLECTION')) {
        wktString = 'GEOMETRYCOLLECTION(' + wktString + ')'
      }
      let res = wellknown.parse(wktString)
      this.observingsystem.geometry_wkt = res
    },
    loadObservingSystemForm: function (id) {
      this.getObservingSystemVersion(id)
      this.showHistory = false
    },
    loadDatasource (id) {
      this.getDatasource(id)
    },
    loadInstrumentCloneForm: function (id) {
      this.setClone(id)
      this.showInstrumentCloneForm = true
    },
    loadAssociatedDiscoveries(query) {
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          /* eslint-disable */
          this.searchAssociatedObservingSystems(query).then(() => {})
        }, 200)
      } else {
        this.associatedObservingsystems = []
      }
    },
    loadParentsDiscoveries(query) {
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          /* eslint-disable */
          this.searchParentsObservingSystems(query).then(() => {})
        }, 200)
      } else {
        this.parentsObservingsystems = []
      }
    },
    verify (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.formType == 'create') {
            this.observingsystem.type_of_system = this.observingsystemType
            this.observingsystem.is_main_version = true
            this.observingsystem.commit_message = "Init"
            this.createObservingSystem(this.observingsystem).then(() => {
              this.$refs.upload.submit()
              router.push({
                name: 'settings.observingsystem.list',
                params: { observingsystemType: this.observingsystemType }
              })
            })
          } else {
            this.$prompt(i18n.t('Enter a message for this update'), i18n.t('Message'), {
              confirmButtonText: i18n.t('OK'),
              cancelButtonText: i18n.t('Cancel'),
              inputPattern: /.*\S.*/,
              inputErrorMessage: i18n.t('Message required')
            }).then(({ value }) => {
              this.observingsystem.commit_message = value
              // Delete thumbnail element to update observingsystem. Thumbnail is managed by upload function
              delete this.observingsystem['thumbnail']
              this.editObservingSystem(this.observingsystem).then(response => {
                this.$refs.upload.submit()
                this.editMode = false
                this.$refs.upload.submit()
              })
            }).catch(() => {
              this.$buefy.toast.open({
                message: this.$root.$t('Update canceled'),
                type: 'is-danger'
              })
              return false
            });
          }
        } else {
          this.$buefy.toast.open({
            message: this.$root.$t('check_data_entered'),
            type: 'is-danger'
          })
          return false
        }
      })
    },
    save (formName) {
      if(this.observingsystem.template) {
        this.$formulate.submit("formulate")
      } else {
        this.verify(formName)
      }
    },
    init () {
      this.editMode = this.$route.name == 'settings.observingsystem.create' || this.$route.name == 'settings.observingsystem.edit' ? true : false
      this.observingsystemType = this.$route.params.observingsystemType
      this.getObservatoriesAndCampaigns([])
      let data = {
        'type': 'observingsystem',
      }
      data["subtype"] = this.observingsystemType
      this.getTemplates(data)
      this.getEventTemplates()
      let type_of_system = {
        'type_of_system': this.observingsystemType,
      }
      this.getStatus(type_of_system)
      this.getDatasources([])
      this.getUsers()
      this.getSites([])
      this.getCountries()
      this.getOrganizations([])
      this.getListOfLanguagesIso()
      this.getListOfIsoTopicCategory()
      this.getListOfGeonetworkRights()
      this.getListOfGeonetworkTypeOfSystem()
      this.getNetworks([])
      if(this.observingsystemType == 'data_collection') {
        this.getListOfDataStorageStrategy()
        if(this.formType != 'create') {
          this.getCollection(this.$route.params.id).then(
            () => {
              this.getDatasource(this.collection.data_source).then(
                () => {
                  if(this.datasource.type_of_system == 'instrument')
                    this.getInstrument(this.datasource.id)
                    this.getInstrumentDetail(this.datasource.id)
                  if(this.datasource.type_of_system == 'platform')
                    this.getPlatform(this.datasource.id)
                }
              )
            }
          )
        }
      }
      
      if(this.observingsystemType == 'data_product') {
        this.getProcessingLevels(),
        this.getListOfProductCategory(),
        this.getCollections([]),
        this.getListOfProductCategory(),
        this.getListOfDataStorageStrategy()
      }
      //this.code = JSON.stringify(this.observingsystem.geometry_wkt, null, 2)
      this.geometry = wellknown.stringify(this.observingsystem.geometry_wkt)
    }
  },

  mounted: function () {
    this.token = localStorage.getItem('id_token')
    this.headers = {
      'Authorization': 'Bearer ' + this.token,
    }
    this.init()
  }
}
</script>