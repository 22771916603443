import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './en-US.json'
import fr from './fr-FR.json'
import locale from './defaultLocale.js'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: locale.language,
  fallbackLocale: locale.DEFAULT_LOCALE,
  messages: {
    'en-US': en,
    'fr-FR': fr
  }
})

export default i18n