<template>
  <div class="big-width">
    <div class="columns">
      <div class="column">
        <h1 class="title">
          {{ qualityControlSheet.name }} 
        </h1>
        <ncform :form-schema="qualityControlSheet.template" form-name="qualityControlSheetForm" v-model="qualityControlSheet.data" @submit="submit()"></ncform>
        <br>
        <el-button @click="submit()">Submit</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'
import { store } from '@/store'

export default {
  name: 'editQualityControlSheet',
  data () {
    return {
      token: null,
      data: {},
      name: '',
      value: '',
      formSchema: '',
      id: '',
    }
  },
  computed: {
    ...Vuex.mapGetters(['user', 'countries', 'qualityControlSheet'])
  },
  methods: {
    ...Vuex.mapActions({
      getCountries: 'getCountries',
      getQualityControlSheetSheets: 'getQualityControlSheetSheets',
      editQualityControlSheet: 'editQualityControlSheet'
    }),
    onCmCodeChange(newCode) {
      let res = eval("(" + newCode + ")")
      this.formSchema = res
    },
    createTemplate () {
      const data = {
        'name': this.name,
        'template': this.formSchema
      }
      this.createQualityControlTemplate(data)
    },
    submit () {
      this.$ncformValidate('qualityControlSheetForm').then(data => {
        if (data.result) {
          const data = {
            'id': this.qualityControlSheet.id,
            'name': this.qualityControlSheet.name,
            'template': this.qualityControlSheet.template,
            'data': this.qualityControlSheet.data
          }
          this.editQualityControlSheet(data)
        }
      })
    }
  },

  beforeRouteEnter (to, from, next) {
    store.dispatch('getQualityControlSheet', to.params.id).then(next())
  },

  mounted: function () {
    this.$nextTick(function () {
      this.getQualityControlSheet()
      this.token = localStorage.getItem('id_token')
      this.headers = {
        'Authorization': 'Bearer ' + this.token,
      }
    })
  }
}
</script>
