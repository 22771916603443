import api from '@/store/api'

export default {
  get (id) {
    return api.get('dataprod/operationSheets/' + id + '/')
  },
  getList (productionVersion, dateRelease) {
    var req = 'dataprod/operationSheets/?'
    if(productionVersion)
      req = req + '&production_version=' + productionVersion
    if(dateRelease)
      req = req + '&date_release=' + dateRelease
    return api.get(req)
  },
  create (data) {
    return api.post('dataprod/operationSheets/', data)
  },
  update (id, data) {
    return api.patch('dataprod/operationSheets/' + id + '/', data)
  },
}
