<template>
  <div class="medium-center">
    <el-form :model="site" label-position="top" ref="form" :rules="rules" :hide-required-asterisk="true">
      <el-form-item :label="$t('Parent site')" prop="parent_site">
        <el-select name="sltSites" v-model="site.parent_site" placeholder="Select" value-key="id" ref="sltSites">
          <el-option
            v-for="item in sites.results"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('Name')" prop="name">
        <el-input v-model="site.name" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item :label="$t('Label')" prop="label">
        <el-input v-model="site.label" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item :label="$t('Address')" prop="address">
        <el-input type="textarea" v-model="site.address" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item :label="$t('Postcode')" prop="postcode">
        <el-input v-model="site.postcode" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item :label="$t('City')" prop="city">
        <el-input v-model="site.city" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item :label="$t('Country')" prop="country">
        <el-select v-model="site.country" placeholder="Select" filterable>
          <el-option
            v-for="(value, index) in countries"
            :key="index"
            :label="value"
            :value="index">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('Geometry')" prop="site.geom">
        <el-input v-model="site.geom" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item class="lst-btn-end-form">
        <el-button @click.stop="save('form')">{{ formType=='create' ? $t('Create') : $t('Edit') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Vuex from 'vuex'
import i18n from '@/i18n'

export default {
  name: 'formSite',
  props: {
    formType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      rules: {
        name: [
          { required: true, message: i18n.t('msg_required_field'), trigger: 'blur' },
        ],
        observatory: [
          { required: true, message: i18n.t('msg_required_field'), trigger: 'change' },
        ],
        country: [
          { required: true, message: i18n.t('msg_required_field'), trigger: 'change' },
        ]
      }
    }
  },
  computed: {
    ...Vuex.mapGetters(['user', 'countries', 'site', 'observatories', 'sites'])
  },
  methods: {
    ...Vuex.mapActions({
      initSite: 'initSite',
      createSite: 'createSite',
      editSite: 'editSite',
      getCountries: 'getCountries'
    }),
    init () {
      this.editMode = this.$route.name == 'settings.observingsystem.create' || this.$route.name == 'settings.observingsystem.edit' ? true : false
    },
    save (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.$route.name == 'settings.site.create') {
            this.createSite(this.site)
          } else {
            this.editSite(this.site)
          }
        } else {
          this.$buefy.toast.open({
            message: this.$root.$t('check_data_entered'),
            type: 'is-danger'
          })
          return false
        }
      })
    }
  },
  mounted: function () {
    this.init()
  }
}
</script>