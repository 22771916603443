const DEFAULT_LOCALE = 'en-US'
import lang from 'element-ui/lib/locale/lang/fr'
import lang_en from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

let language = DEFAULT_LOCALE
const navigator = window.navigator
const languages = navigator.languages || navigator.language || navigator.browserLanguage || navigator.userLanguage
language = languages[0]

if(language == 'fr-FR') {
  locale.use(lang)
}else{
  locale.use(lang_en)
}

export default { language, DEFAULT_LOCALE }
