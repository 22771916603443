<template>
  <div class="history">
    <el-drawer
      :title="$t('History')"
      :visible.sync="showHistory"
      direction="rtl"
      >
      <div class="panel-history">
        <div class="block">
          <el-timeline :reverse="reverse" v-if="observingsystemHistory.length">
            <el-timeline-item
              v-for="(version, index) in observingsystemHistory"
              :key="index"
              :timestamp="version.date_created"
              :class="version.is_main_version?'timeline-main-version':'timeline-minor-version'">
              <el-button @click="loadObservingSystemForm(version.id)" round v-if="version.is_main_version"> {{version.commit_message}} </el-button>
              <el-tooltip class="item" effect="dark" :content="$t('Main version')" placement="top-start" v-if="version.is_main_version">
                <b-icon class="timeline-bookmark" type="is-success" icon="mdi mdi-bookmark"></b-icon>
              </el-tooltip>
              <el-link @click="loadObservingSystemForm(version.id)" target="_blank" v-else> {{version.commit_message}}</el-link>
              <!--<el-button @click="loadObservingSystemForm(version.id)" type="text" v-else> {{version.commit_message}} </el-button>-->
            </el-timeline-item>
          </el-timeline>
          <div v-if="!observingsystemHistory.length">
            <e-no-data/>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
/* eslint-disable */
import Vuex from 'vuex'
import NoData from '@/components/NoData.vue'
import ObservingSystemForm from '@/views/observingsystem/Form.vue'

export default {
  name: 'HistoryList',
  components: {
    'e-no-data': NoData,
    'e-observingsystem-form': ObservingSystemForm,
  },
  props: {
    observingSystemID: {
      type: Number,
      required: true
    },
    showHistory: {
      type: Boolean,
      required: true
    },
  },
  data () {
    return {
      reverse: false,
      showVersionForm: false
    }
  },
  computed: {
    ...Vuex.mapGetters(['observingsystem', 'observingsystemHistory'])
  },
  methods: {
    ...Vuex.mapActions({
      getObservingSystemVersion: 'getObservingSystemVersion',
      getObservingSystem: 'getObservingSystem'
    }),
    handleClose(done) {
      this.$confirm('Are you sure you want to close this?')
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    loadObservingSystemForm: function (id) {
      this.getObservingSystemVersion(id)
      this.showHistory = false
    },
    reloadObservingsystem(done) { 
      this.getObservingSystem(this.observingsystem.discovery).then(
        _ => {
          done()
        }
      ).catch(
        _ => {}
      )
    }
  }
}
</script>