<template>
  <e-list :title="$t('Last events')" :data="[]" type="event" route="event.view" :isDashboard="isDashboard"></e-list>
</template>

<script>
import List from '@/components/List'

export default {
  name: 'ListEvent',
  components: {
    'e-list': List
  },
  props: {
    isDashboard: {
      type: Boolean,
      required: false,
      default: false
    }
  },
}
</script>