<template>
  <div class="select-langage">
    <input type="radio" id="en" value="en-US" v-model="locale" style="display: none;">
    <label for="en" class="langage">en</label>
    |
    <input type="radio" id="fr" value="fr-FR" v-model="locale" style="display: none;">
    <label for="fr" class="langage">fr</label>
  </div>
</template>

<script>
import locale from '@/i18n/defaultLocale.js'

export default {
  name: 'select-langage',
  data () { return { locale: locale.language } },
  watch: {
    locale (val) {
      this.$i18n.locale = val
    }
  }
}
</script>
