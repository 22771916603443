<template>
  <div class="settings-content">
    <transition name="page-transition" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
// @ is an alias to /src
import Vuex from 'vuex'

export default {
  name: 'settings',
  data () {
    return {
      token: null
    }
  },
  methods: {
    ...Vuex.mapActions({
      check: 'check',
      getUsers: 'getUsers',
      getCountries: 'getCountries',
      getListOfGroups: 'getListOfGroups',
      createOperationFile: 'createOperationFile',
      editOperationFile: 'editOperationFile',
      getOperationFiles: 'getOperationFiles',
      getOperationFile: 'getOperationFile',
      createObservatory: 'createObservatory',
      getObservatoriesAndCampaigns: 'getObservatoriesAndCampaigns',
      getObservatory: 'getObservatory',
      createOrganization: 'createOrganization',
      getOrganizations: 'getOrganizations',
      getOrganization: 'getOrganization',
      createSite: 'createSite',
      getSites: 'getSites',
      getSite: 'getSite',
      createInstrument: 'createInstrument',
      createPlatform: 'createPlatform',
      getInstruments: 'getInstruments',
      getMyInstruments: 'getMyInstruments',
      getInstrument: 'getInstrument',
      getSoftwares: 'getSoftwares',
      getDatasources: 'getDatasources'
    })
  },
  computed: {
    ...Vuex.mapGetters(['user', 'countries', 'listOfAccessRights', 'listOfGroups', 'observatories', 'organizations', 'softwares', 'org', 'observatory', 'site', 'instrument'])
  },
  beforeMount: function() {
    this.check()
  },
  mounted: function () {
    this.$nextTick(function () {
      this.check(),
      this.getCountries()
    })
  }
}
</script>
