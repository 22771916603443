var conf = {
  API_URL: 'https://eobs.osureunion.fr/api/',
  GITLAB_URL: 'http://gitlab.osureunion.fr/eobs/',
  CLIENT_ID: '1KlcJcapto8VCyonAW6ccD1ewaEDH9obTok6fY3q',
  CLIENT_SECRET: 'aQjpUlMsQTLDZhSHJnkKvJTdrvXhnVUogJAwZJLU2yses2b6wwgik1XQpZxE23pH0iH39RHq4fDnoaU7ehv8bOHTG0PmctbGLfRzUNeDSnFTzhw6tuxgDLlvwB7j0Z1u',
  FILE_URL: 'visudata/dataFile/download/?datafile=',
  ZIP_URL: 'visudata/dataFiles/zip/?'
}

export default conf
