<template>
  <div class="product full-width">
    <h1 class="title settings-title">
      {{ $t('Products') }} 
    </h1>
    <div class="columns medium-center">
      <div class="column">
        <section>
          <b-table
            :data="products.results"
            :paginated="isPaginated"
            :per-page="perPage"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :default-sort-direction="defaultSortDirection"
            default-sort="id"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page">
            <b-table-column field="id" label="ID" width="40" sortable numeric v-slot="props">
                {{ props.row.id }}
            </b-table-column>

            <b-table-column field="name" :label="$t('Name')" sortable v-slot="props">
                {{ props.row.fullname }}
            </b-table-column>

            <b-table-column field="label" :label="$t('Label')" v-slot="props">
                {{ props.row.label }}
            </b-table-column>

            <b-table-column field="actions" :label="$t('Action')" v-slot="props">
                <router-link :to="{ name: 'settings.dataprod.product.edit', params: { id: props.row.id } }" class="button rounded is-primary">
                  <i class="el-icon-edit"></i>
                </router-link>
            </b-table-column>
              
          </b-table>
          <router-link :to="{name: 'settings.dataprod.product.create'}" v-if="$can('read', 'Info')">
            <el-button icon="el-icon-plus" plain>{{ $t('Add') }}</el-button>
          </router-link>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'


export default {
  name: 'listProducts',
  data () {
    return {
      isPaginated: true,
      isPaginationSimple: false,
      defaultSortDirection: 'asc',
      currentPage: 1,
      perPage: 10,
      token: null,
      name: '',
      value: '',
      formSchema: '',
      id: '',
    }
  },
  computed: {
    ...Vuex.mapGetters(['user', 'products'])
  },
  methods: {
    ...Vuex.mapActions({
      getProducts: 'getProducts'
    }),
  },

  mounted: function () {
    this.getProducts()
  }
}
</script>
