<template>
  <div class="full-width">
    <e-qualitycontrolsheet-form :title="$t('Create Quality Control Sheet')" formType="create"/>
  </div>
</template>

<script>
import Vuex from 'vuex'
import QualityControlSheetForm from '@/components/form/QualityControlSheet.vue'

export default {
  name: 'settingsQualityControlSheetCreate',
  components: {
    'e-qualitycontrolsheet-form': QualityControlSheetForm,
  },
  computed: {
    ...Vuex.mapGetters(['qualityControlSheetTemplate', 'productionVersion', 'productionVersions'])
  },
  methods: {
    ...Vuex.mapActions({
      initQualityControlSheet: 'initQualityControlSheet',
      getProductionVersions: 'getProductionVersions',
      getProductionVersion: 'getProductionVersion',
      getOperationSheetTemplate: 'getOperationSheetTemplate',
      createOperationSheet: 'createOperationSheet'
    }),
    init() {
      this.initQualityControlSheet(this.$store.getters.user.info.id)
      this.getProductionVersion(this.$route.params.production_version).then(
        () => {
          this.getOperationSheetTemplate(this.productionVersion.operationsheet_template)
        }
      )
    },
  },
  mounted: function () {
    this.init()
  }
}
</script>
